import { useState, useEffect, Fragment } from "react";
import { useNavigate, Link } from "react-router-dom";
import DataTableList from '../components/DataTableList';
import { Chip, Button, Drawer, Tooltip } from "@mui/material";
import InvoiceDialog from "../components/Modal/InvoiceDialog";
import { QuoteService } from "../components/Modal/QuoteForm";
import ServiceReportDialog from "../components/Modal/ServiceReport";
import CertificateDialog from "../components/Modal/CertificateDialog";
import { axiosFunction } from '../common';
import dayjs from 'dayjs';
import { Shield, RemoveRedEyeRounded } from '@mui/icons-material';

export const Quotation = ({ propertyId }) => {
    const navigate = useNavigate(null);
    const [DTColumns, setDTColumns] = useState([]);
    const [loader, setLoader] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const fetchData = async () => {
        try {
            const params = { 'property_id': propertyId }
            const response = await axiosFunction('quotes', 'get', params);

            if (response.status === 'success') {
                setDTColumns(response.data);
                setLoader(false);
            } else {
                /* if (response?.response?.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                } */
                setLoader(false);
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate("/login");
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId]);


    const handleView = () => {
    }


    const rows: GridRowsProp = DTColumns;
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'quote_number', headerName: 'Quote #', width: 100, filterable: true },
        { field: 'subject', headerName: 'Subject', width: 150, filterable: true },
        { field: 'description', headerName: 'Description', width: 150, filterable: true },
        { field: 'subtotal', headerName: 'Sub Total AUD', width: 100, filterable: true, renderCell: (params) => '$' + params.row.subtotal },
        { field: 'tax_rate', headerName: 'Tax Rate AUD', width: 150, filterable: true, renderCell: (params) => '$' + params.row.tax_rate },
        { field: 'total', headerName: 'Total AUD', width: 100, filterable: true, renderCell: (params) => '$' + params.row.total },
        { field: 'due_date', headerName: 'Due Date', width: 150, filterable: true },
        { field: 'actions', headerName: 'Actions', width: 100, sortable: false, renderCell: (params) => { return (<Tooltip title="View Quotation" placement="top" arrow ><Button type="button" onClick={(e) => handleView(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-eye"></i></Button></Tooltip>) } }
    ];


    return (
        <Fragment>
            <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={() => setDrawerOpen(true)}>Add New Quote</Button>
            </div>
            <DataTableList columns={columns} rows={rows} loading={loader} />
            {drawerOpen && <Drawer anchor="right" open={drawerOpen} sx={{ zIndex: 1400 }} PaperProps={{ className: 'container p-0' }}>
                <QuoteService propertyId={propertyId} setDrawerOpen={setDrawerOpen} />
            </Drawer>}
        </Fragment>
    );
}

export const Invoice = ({ propertyId, handlePay, updateTabList }) => {
    const navigate = useNavigate(null);
    const _role = localStorage.getItem('_role');
    const [DTColumns, setDTColumns] = useState([]);
    const [loader, setLoader] = useState(true);
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [invoiceData, setInvoiceData] = useState({});

    const fetchInvoiceData = async () => {
        try {
            const params = { 'property_id': propertyId }
            const response = await axiosFunction('invoices', 'get', params);
            if (response.status === 'success') {
                setDTColumns(response.data);
                setLoader(false);
            } else {
                setLoader(false);
                /* if (response?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate("/login");
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        fetchInvoiceData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId, updateTabList]);

    const handleView = (invoiceData) => {
        setInvoiceData(invoiceData);
        setInvoiceDialogOpen(true);
    }

    /* const handleEdit = (data) => {
        data.action = 'edit';
        setFormData(data);
        setInvoiceData({});
        setDrawerOpen(true);
    } */

    const handleInvoiceDialogClose = () => {
        setInvoiceData({});
        setInvoiceDialogOpen(false);
    }

    /* const handleQuoteAdd = () => {
        setFormData({ action: 'add' });
        setInvoiceData({});
        setDrawerOpen(true);
    } */

    const rows: GridRowsProp = DTColumns;
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'invoice_number', headerName: 'Invoice #', width: 100, filterable: true },
        { field: 'description', headerName: 'Description', width: 250, filterable: true },
        { field: 'amount', type: 'number', headerName: 'Amount', width: 100, filterable: true, renderCell: (params) => params.row.amount ? params.row.amount.toFixed(2) : '0.00', valueGetter: (params) => params.row.amount ? params.row.amount.toFixed(2) : '0.00' },
        { field: 'credit', type: 'number', headerName: 'Credit', width: 100, filterable: true, renderCell: (params) => params.row.credit ? params.row.credit.toFixed(2) : '0.00', valueGetter: (params) => params.row.credit ? params.row.credit.toFixed(2) : '0.00' },
        { field: 'subtotal', type: 'number', headerName: 'Subtotal', width: 100, filterable: true, renderCell: (params) => params.row.amount ? (params.row.amount - params.row.credit).toFixed(2) : '0.00', valueGetter: (params) => params.row.amount ? (params.row.amount - params.row.credit).toFixed(2) : '0.00' },
        { field: 'tax_rate', type: 'number', headerName: 'Tax Rate', width: 100, filterable: true, renderCell: (params) => params.row.tax_rate ? params.row.tax_rate.toFixed(2) : '0.00', valueGetter: (params) => params.row.tax_rate ? params.row.tax_rate.toFixed(2) : '0.00' },
        { field: 'total', type: 'number', headerName: 'Total', width: 120, filterable: true, renderCell: (params) => params.row.total ? params.row.total.toFixed(2) : '0.00', valueGetter: (params) => params.row.total ? params.row.total.toFixed(2) : '0.00' },
        { field: 'paid_status', headerName: 'Paid Status', width: 150, sortable: false, filterable: false, renderCell: (params) => (params.row.paid_status === 'Y') ? <Chip label="Paid" color="primary" size="small" /> : (['AGENT', 'LDLORD'].includes(_role) ? <Button className="themeholy-btn btn-primary w-100" onClick={(e) => handlePay(params.row, 'invoice')}>Pay</Button> : <Chip label="Pending" color="warning" size="small" />), valueGetter: (params) => (params.row.status === 'Y') ? 'Paid' : (['AGENT', 'LDLORD'].includes(_role) ? 'Pay' : 'Pending') },
        { field: 'actions', headerName: 'Actions', width: 100, sortable: false, renderCell: (params) => { return (<><Tooltip title="View Invoice" placement="top" arrow ><Button type="button" onClick={(e) => handleView(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-file-invoice"></i></Button></Tooltip></>) } }
    ];

    /* const handleAdd = () => {

    } */

    return (
        <Fragment>
            <div className='text-end mb-3'>
                {/*  <Button className="themeholy-btn btn-primary" onClick={() => handleQuoteAdd()}>Add New Quote</Button> */}

                {/* <Button className="themeholy-btn btn-primary" style={{ marginLeft: '15px' }} onClick={handleAdd}>Add New Invoice</Button> */}
            </div>
            <DataTableList columns={columns} rows={rows} loading={loader} />
            {/* {drawerOpen && <Drawer anchor="right" open={drawerOpen} sx={{ zIndex: 1400 }} >
                <QuoteService propertyId={propertyId} propertyData={propertyData} setDrawerOpen={setDrawerOpen} formData={formData} fetchQuoteInvoiceData={fetchQuoteInvoiceData} />
            </Drawer>} */}
            {invoiceDialogOpen && <InvoiceDialog invoiceDialogOpen={invoiceDialogOpen} handleInvoiceDialogClose={handleInvoiceDialogClose} invoiceData={invoiceData} />}
        </Fragment>
    );
}

export const PaymentHistory = ({ propertyId, updateTabList }) => {
    const navigate = useNavigate(null);
    const [loader, setLoader] = useState(true);
    const [DTColumns, setDTColumns] = useState([]);

    const fetchPaymentData = async () => {
        try {
            const params = { 'property_id': propertyId }
            const response = await axiosFunction('payments', 'get', params);

            if (response.status === 'success') {
                setDTColumns(response.data);
                setLoader(false);
            } else {
                setLoader(false);
                /*  if (response?.response?.status === 401) {
                     localStorage.clear();
                     navigate('/login');
                 } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate("/login");
            } else {
                console.log(e);
            }
        }

    }

    useEffect(() => {
        fetchPaymentData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId, updateTabList]);

    const rows: GridRowsProp = DTColumns;
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 100, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'paid_invoices', headerName: 'Paid Invoices', width: 200, filterable: true },
        { field: 'created_at', headerName: 'Payment Date', width: 200, filterable: true },
        { field: 'paid_amount', headerName: 'Paid Amount', width: 200, filterable: true, renderCell: (params) => '$' + params.row.paid_amount ? params.row.paid_amount.toFixed(2) : '0.00', valueGetter: (params) => params.row.paid_amount ? params.row.paid_amount.toFixed(2) : '0.00' },
        { field: 'transaction_id', headerName: 'Transaction ID', width: 250, filterable: true },
        { field: 'payment_mode', headerName: 'Payment Mode', width: 250, filterable: true },
        { field: 'paid_by', headerName: 'Paid By', width: 250, sortable: false, filterable: false },

    ];
    /* const handleAdd = () => {
    } */
    return (
        <Fragment>
            {/* <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Payment</Button>
            </div> */}
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}

export const Activities = () => {
    const [loader, setLoader] = useState(true);
    const rows: GridRowsProp = [];
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'activity_date', headerName: 'Activity Date', width: 100, filterable: true },
        { field: 'activity_type', headerName: 'Activity Type', width: 150, filterable: true },
        { field: 'check_in', headerName: 'Checkin', width: 100, filterable: true },
        { field: 'check_out', headerName: 'Checkout', width: 100, filterable: true },
        { field: 'duration', headerName: 'Duration', width: 100, sortable: false, filterable: false },
        { field: 'category', headerName: 'Category', width: 150, sortable: false, filterable: false },
        { field: 'service_type', headerName: 'Service Type', width: 250, sortable: false, filterable: false },
        { field: 'comments', headerName: 'Comments', width: 250, sortable: false, filterable: false },
    ];
    useEffect(() => {
        setLoader(false);
    }, []);

    const handleAdd = () => {
    }

    return (
        <Fragment>
            <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Activity</Button>
            </div>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}

export const Appointments = ({ propertyId }) => {
    const navigate = useNavigate(null);
    const [loader, setLoader] = useState(true);
    const [DTColumns, setDTColumns] = useState([]);
    const showText = (params) => {
        let stype = params.row.service_type;
        if (params.row.service.product.code === 'PROD3LMSM' || params.row.service.product.code === 'PROD4LMSM') {
            stype = stype === 'ES' ? 'Electrical Safety Check Only' : 'Smoke Alarm Safety Check Only';
        } else {
            stype = stype === 'ES' ? 'Electrical & Smoke Alarm Safety Check Only' : 'Gas Safety Check Only';;
        }
        return (<>S: {params.row.service.product.description} <br /> T: {stype} <br />Inv #: {params.row.service.invoice && params.row.service.invoice.invoice_number}</>);
    }

    const handleStatusColor = (status) => {
        var color = 'info';
        if (/Unassigned/.test(status)) {
            color = 'default';
        } else if (/Assigned/.test(status)) {
            color = 'primary';
        } else if (/Approved/.test(status)) {
            color = 'success';
        } else if (/Pending Approval/.test(status)) {
            color = 'secondary';
        } else if (/Cancelled/.test(status)) {
            color = 'error';
        } else if (/Reschedule/.test(status)) {
            color = 'warning';
        }
        return color;
    }

    const rows: GridRowsProp = DTColumns;
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'job_date', headerName: 'Appointment Date', width: 150, filterable: true },
        { field: 'job_time', headerName: 'Activity Time', width: 100, filterable: true },
        { field: 'product_code', headerName: 'Service Type', width: 200, filterable: true, renderCell: (params) => showText(params), valueGetter: (params) => showText(params) },
        { field: 'technician_id', headerName: 'Tech Person', width: 250, sortable: false, filterable: false, renderCell: (params) => (params.row.technician && ((params.row.technician.lastname ? params.row.technician.lastname : '') + ' ' + (params.row.technician.firstname ? params.row.technician.firstname : ''))), valueGetter: (params) => (params.row.technician && ((params.row.technician.lastname ? params.row.technician.lastname : '') + ' ' + (params.row.technician.firstname ? params.row.technician.firstname : ''))) },
        {
            field: 'job_status', headerName: 'Status', width: 200, renderCell: (params) => (params.row.job_status ? <Chip sx={{
                height: 'auto',
                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                },
            }} label={params.row.job_status} color={handleStatusColor(params.row.job_status)} size="small" /> : ''), valueGetter: (params) => params.row.job_status ? params.row.job_status : ''
        },
        // { field: 'rescheduled', headerName: 'Rescheduled', width: 150, sortable: false, filterable: false, renderCell: (params) => (params.row.reschedled === 'Y') ? <Chip label="Yes" color="primary" size="small" /> : <Chip label="No" color="warning" size="small" />, valueGetter: (params) => (params.row.reschedled === 'Y') ? 'Yes' : 'No' },
    ];

    const fetchApptData = async () => {
        try {
            const params = '?property_id=' + propertyId;
            const response = await axiosFunction('subservices' + params, 'get');

            if (response.status === 'success') {
                setDTColumns(response.data);
                setLoader(false);
            } else {
                setLoader(false);
                /* if (response?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate("/login");
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        fetchApptData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /*  const handleAdd = () => { 
     } */

    return (
        <Fragment>
            {/*  <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Appointment</Button>
            </div> */}
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}

export const ChangeLogs = () => {
    const [loader, setLoader] = useState(true);
    const rows: GridRowsProp = [];
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'updated_at', headerName: 'Changed Date & Time', width: 250, filterable: true },
        { field: 'changed_field', headerName: 'Changed Field', width: 200, filterable: true },
        { field: 'old', headerName: 'Old Value', width: 200, filterable: true },
        { field: 'new', headerName: 'New Value', width: 250, sortable: false, filterable: false },
        { field: 'updated_by', headerName: 'Updated By', width: 250, sortable: false, filterable: false },
    ];
    useEffect(() => {
        setLoader(false);
    }, []);

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}

export const ServiceReport = ({ propertyId }) => {
    const navigate = useNavigate(null);
    const [DTColumns, setDTColumns] = useState([]);
    const [loader, setLoader] = useState(true);
    const [jobData, setJobData] = useState({});
    const [serviceReportOpen, setServiceReportDialogOpen] = useState(false);
    const [certificateDialogOpen, setCertificateDialogOpen] = useState(false);

    const fetchData = async () => {
        try {
            const params = { 'property_id': propertyId }
            const response = await axiosFunction('subservices', 'get', params);

            if (response.status === 'success') {
                setDTColumns(response.data);
                setLoader(false);
            } else {
                setLoader(false);
                /*  if (response?.response?.status === 401) {
                     localStorage.clear();
                     navigate("/login");
                 } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate("/login");
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId]);

    const showText = (params) => {
        let stype = params.row.service_type;
        if (params.row.service.product.code === 'PROD3LMSM' || params.row.service.product.code === 'PROD4LMSM') {
            stype = stype === 'ES' ? 'Electrical Safety Check Only' : 'Smoke Alarm Safety Check Only';
        } else {
            stype = stype === 'ES' ? 'Electrical & Smoke Alarm Safety Check Only' : 'Gas Safety Check Only';;
        }
        return (<>S: {params.row.service.product.description} <br /> T: {stype} <br />Inv #: {params.row.service.invoice && params.row.service.invoice.invoice_number}</>);
    }


    const rows: GridRowsProp = DTColumns;
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 150, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'service_date', headerName: 'Service Date', width: 180, filterable: true, renderCell: (params) => dayjs(params.row.service.service_date).format('DD-MM-YYYY'), valueGetter: (params) => dayjs(params.row.service.service_date).format('DD-MM-YYYY') },
        { field: 'job_date', headerName: 'Job Date', width: 180, filterable: true },
        { field: 'service_type', headerName: 'Service', width: 250, renderCell: (params) => showText(params), valueGetter: (params) => showText(params) },
        { field: 'technician_id', headerName: 'Technician', width: 180, renderCell: (params) => (params.row.technician && ((params.row.technician.lastname ? params.row.technician.lastname : '') + ' ' + (params.row.technician.firstname ? params.row.technician.firstname : ''))), valueGetter: (params) => (params.row.technician && ((params.row.technician.lastname ? params.row.technician.lastname : '') + ' ' + (params.row.technician.firstname ? params.row.technician.firstname : ''))) },
        { field: 'action', headerName: 'Action', width: 200, sortable: false, renderCell: (params) => { return (<><Tooltip title="View Service Report" placement="top" arrow ><Button type="button" onClick={() => { openServiceReport(params.row) }} style={{ minWidth: '20px' }}><RemoveRedEyeRounded style={{ minWidth: '20px' }} /></Button></Tooltip> {(/Paid/.test(params.row.job_status)) && <Tooltip title="View Certificate" placement="top" arrow ><Button type="button" onClick={(e) => handleViewCertificate(params.row)} style={{ minWidth: '20px', color: 'green' }}><Shield /></Button></Tooltip >}</>) } }
    ];

    //<Link to="https://safetyninja.360membershipcrm.com.au/ElectricalSafetyChecklist.pdf" target="_blank"><Button type="button"><i className="fa fa-th-list"></i></Button></Link>

    const openServiceReport = (data) => {
        setJobData(data);
        setServiceReportDialogOpen(true);
    }
    const handleServiceReportDialogClose = () => {
        setServiceReportDialogOpen(false);
    }

    const handleViewCertificate = (data) => {
        setJobData(data);
        setCertificateDialogOpen(true);
    }

    const handleCertificateDialogClose = () => {
        setJobData({});
        setCertificateDialogOpen(false);
    }

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
            {serviceReportOpen && <ServiceReportDialog serviceReportOpen={true} handleServiceReportDialogClose={handleServiceReportDialogClose} jobData={jobData} />}
            {certificateDialogOpen && <CertificateDialog certificateDialogOpen={certificateDialogOpen} handleCertificateDialogClose={handleCertificateDialogClose} jobData={jobData} />}
        </Fragment>
    );
}

export const ECertificate = () => {
    const [loader, setLoader] = useState(true);
    const rows: GridRowsProp = [
        { id: 1, created_at: '20/03/2024 09:00 AM', certificate: 'complaince_services_10302023.pdf', action: '' },
        { id: 2, created_at: '05/04/2024 09:23 AM', certificate: 'complaince_services_10202023.pdf', action: '' },
    ];
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'created_at', headerName: 'Date & Time', width: 250, filterable: true },
        { field: 'certificate', headerName: 'Certificate', width: 300, filterable: true, renderCell: (params) => <Link to="#">{params.row.certificate}</Link> },
        { field: 'action', headerName: 'Action', width: 200, sortable: false, renderCell: (params) => { return (<Tooltip title="Download Certificate" placement="top" arrow ><Button type="button"><i className="fa fa-eye"></i></Button> <Button type="button"><i className="fa fa-download"></i></Button></Tooltip>) } }
    ];
    useEffect(() => {
        setLoader(false);
    }, []);

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}


export const CommunicationHistory = ({ propertyId, updateTabList }) => {
    const navigate = useNavigate(null);
    const [loader, setLoader] = useState(true);
    const [DTColumns, setDTColumns] = useState([]);

    const fetchCommunicationData = async () => {
        try {
            const params = { 'property_id': propertyId }
            const response = await axiosFunction('messages', 'get', params);

            if (response.status === 'success') {
                setDTColumns(response.data);
                setLoader(false);
            } else {
                setLoader(false);
                /* if (response?.response?.status === 401) {
                    localStorage.clear();
                    navigate("/login");
                } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate("/login");
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        fetchCommunicationData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId, updateTabList]);

    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'created_at', headerName: 'Date & Time', width: 180, filterable: true },
        { field: 'type', headerName: 'Via', width: 100, filterable: true },
        { field: 'from', headerName: 'From', width: 180, filterable: true },
        { field: 'to', headerName: 'To', width: 180, sortable: false, filterable: false },
        { field: 'message', headerName: 'Message', width: 250, sortable: false, filterable: false },
    ];

    return (
        <Fragment>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </Fragment>
    );
}