import React, { useState, createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from "./Login";
import Register from "./Register";
import Dashboard from "./Dashboard";
import { checkPermission } from '../common';
import MyAccount from "./MyAccount";
import Users from "./Users";
import Properties from "./Properties";
import Jobs from "./Jobs";
import Invoices from "./invoices";
import NoPage from './NoPage';
import Invoice from '../components/Jobs/Invoice';
import ServiceReport from '../components/Jobs/ServiceReport';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import ResetPassword from "./ResetPassword";
import ForgotPassward from "./ForgotPassword";

const THEME = createTheme({
    typography: {
        allVariants: {
            "fontFamily": `'Oswald', sans-serif`
        }
    }
});

export const AppContext = createContext(null);

const Main = () => {

    const _token = localStorage.getItem("_token");
    const _role = localStorage.getItem("_role");
    const _is_landlord = localStorage.getItem("_is_landlord");
    const [authenticated, setAuthenticated] = useState(_token ? true : false);

    /*  useEffect(() => {
         if (_token) setAuthenticated(true);
         //console.log(_role);
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []); */

    // eslint-disable-next-line
    const handleLogout = () => {
        localStorage.clear();
        setAuthenticated(false);
    };

    return (
        <ThemeProvider theme={THEME}>
            <AppContext.Provider value={{ authenticated, handleLogout }}>
                <Router>
                    <Routes>
                        <Route basename='/*'>
                            <Route index element={authenticated ? (checkPermission('DASHBOARD', 'view') ? < Dashboard /> : ((_role === 'AGENT') ? <Users /> : <Properties />)) : <Login />} />
                            <Route path="login" element={<Login />} />
                            <Route path="forgot-password" element={<ForgotPassward />} />
                            <Route path="reset-password/*" element={<ResetPassword />} />
                            <Route path="landlord-register" element={<Register type="LDLORD" />} />
                            <Route path="agent-register" element={<Register type="AGENT" />} />
                            {(!_role || checkPermission('DASHBOARD', 'view')) && <Route path="dashboard" element={<Dashboard />} />}
                            <Route path="my-account/*" element={<MyAccount />} />

                            {(!_role || checkPermission('USERS', 'view')) && <Route path="users/*" element={<Users />} />}

                            {(!_role || (_role && _role === 'AGENT')) && <Route path="land-lords/*" element={<Users />} />}

                            {(!_role || checkPermission('PROPERTY', 'add') || checkPermission('PROPERTY', 'view') || checkPermission('PROPERTY', 'send')) && <Route path="properties" element={<Properties />} />}

                            {(!_role || (_is_landlord === 'Y' && (checkPermission('PROPERTY', 'add') || checkPermission('PROPERTY', 'view') || checkPermission('PROPERTY', 'send')))) && <Route path="my-properties" element={<Properties />} />}

                            {(!_role || checkPermission('JOB', 'view')) && <Route path="jobs" element={<Jobs />} />}
                            {(!_role || checkPermission('JOB', 'viewinvoice')) && <Route path="invoices" element={<Invoices />} />}
                            <Route path="invoice/*" element={<Invoice upload='N' />} />
                            <Route path="generateinvoice/*" element={<Invoice upload='Y' />} />
                            <Route path="servicereport/*" element={<ServiceReport upload='N' />} />
                            <Route path="generateservicereport/*" element={<ServiceReport upload='Y' />} />
                            <Route path="*" element={<NoPage />} />
                        </Route>
                    </Routes>
                </Router>
            </AppContext.Provider>
        </ThemeProvider>
    );
}

/* const Logout = () => {
    useEffect(() => {
        window.location.href = webBaseUrl;
    }, []);

    return <h5>Redirecting...</h5>;
}; */

export default Main;