import { useState, useEffect, useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../assets/img/logo-main.png';
import Logo1 from '../assets/img/logo_new1.png';
import { webBaseUrl, checkPermission } from '../common';
import { AppContext } from '../pages/Main';

const SiteHeader = (props) => {
    const navigate = useNavigate(null);
    const _token = localStorage.getItem("_token");
    const _role = localStorage.getItem("_role");
    const _redirect = localStorage.getItem("_redirect");
    const _is_landlord = localStorage.getItem("_is_landlord");
    const _user_name = localStorage.getItem("_user_name");
    const [showHeaderMenu, setShowHeaderMenu] = useState('hide');
    const [showMenu, setShowMenu] = useState('');
    //const [activeSubMenu, setActiveSubMenu] = useState('');
    const [showUsersSubMenu, setShowUsersSubMenu] = useState('themeholy-submenu');
    const [showSettingsSubMenu, setshowSettingsSubMenu] = useState('themeholy-submenu');

    const { handleLogout } = useContext(AppContext);

    const uTypeArr = { 'SYS': 'Admin', 'MG': 'Manager', 'STAFF': 'Staff', 'AC': 'Accountant', 'REPNST': 'Receptionist', 'AGENT': 'Agent', 'LDLORD': 'Land Lord', 'TENANT': 'Tenant', 'E-TRADIE': 'Electrician Tradie', 'P-TRADIE': 'Plumber Tradie' }
    const userType = uTypeArr[_role];
    const comp = useLocation();
    let compseg = comp.pathname.split('/')[2];

    const [usersAttr, setUsersAttr] = useState({
        className: 'menu-item-has-children themeholy-item-has-children',
        onClick: () => handleSubMenuToggle(0)
    });

    const [settingsAttr, setSettingsAttr] = useState({
        className: 'menu-item-has-children themeholy-item-has-children',
        onClick: () => handleSubMenuToggle(1)
    });

    useEffect(() => {
        if (_redirect === 'reset' && props.menu !== 'reset') {
            navigate('/reset-password');
        }

        if (_token) {
            if (_redirect !== 'reset' && ['login', 'LDLORD', 'AGENT', 'reset', 'forgot'].includes(props.menu)) {
                if (_role === 'AGENT') navigate('/land-lords');

                else if (_role === 'LDLORD') navigate('/my-properties');

                else navigate('/dashboard');
            }
        } else if (!['login', 'LDLORD', 'AGENT', 'forgot', 'reset'].includes(props.menu)) {
            navigate('/login');
        }

        if (_token === null) {
            setShowHeaderMenu('');
        }
        setShowMenu('');
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [_token, compseg]);

    const handleMenuToggle = (cls) => {
        setShowMenu(cls);
        if (cls) {
            if (comp.pathname.split('/')[1] === 'users') {
                usersAttr.className = 'menu-item-has-children themeholy-item-has-children themeholy-active';
                setUsersAttr(usersAttr);
                setShowUsersSubMenu('');
            } else if (comp.pathname.split('/')[1] === 'my-account') {
                if (comp.pathname.split('/')[2] !== 'profile') {
                    settingsAttr.className = 'menu-item-has-children themeholy-item-has-children themeholy-active';
                    setSettingsAttr(settingsAttr);
                    setshowSettingsSubMenu('');
                } else {
                    setshowSettingsSubMenu('themeholy-submenu');
                    setSettingsAttr({
                        className: 'menu-item-has-children themeholy-item-has-children',
                        onClick: () => handleSubMenuToggle(1)
                    });
                }
            }
        }
    }
    const handleSubMenuToggle = (i) => {
        if (i === 0) {
            if (usersAttr.className === 'menu-item-has-children themeholy-item-has-children themeholy-active') {
                usersAttr.className = 'menu-item-has-children themeholy-item-has-children';
                setUsersAttr(usersAttr);
                setShowUsersSubMenu('themeholy-submenu');
            } else {
                usersAttr.className = 'menu-item-has-children themeholy-item-has-children themeholy-active'
                setUsersAttr(usersAttr);
                setShowUsersSubMenu('');
            }
        } else {
            if (settingsAttr.className === 'menu-item-has-children themeholy-item-has-children themeholy-active') {
                settingsAttr.className = 'menu-item-has-children themeholy-item-has-children';
                setSettingsAttr(settingsAttr);
                setshowSettingsSubMenu('themeholy-submenu');
            } else {
                settingsAttr.className = 'menu-item-has-children themeholy-item-has-children themeholy-active'
                setSettingsAttr(settingsAttr);
                setshowSettingsSubMenu('');
            }
        }
    }

    return (
        <>
            <div className={"themeholy-menu-wrapper " + showMenu}>
                <div className="themeholy-menu-area text-center">
                    <button className="themeholy-menu-toggle" onClick={() => handleMenuToggle('')}><i className="fal fa-times"></i></button>
                    <div className="mobile-logo"><Link to={webBaseUrl + "index.html"}><img src={Logo1} alt="Safety Ninja" /></Link></div>
                    <div className="themeholy-mobile-menu">
                        <ul>
                            {!showHeaderMenu ? (
                                <>
                                    <li>
                                        <Link to={webBaseUrl + "index.html"} >Home</Link>
                                    </li>
                                    <li><Link to={webBaseUrl + "our-services.html"}>Our Services</Link></li>
                                    <li><Link to={webBaseUrl + "why-choose-our-services.html"}>Why Choose Our Service?</Link></li>
                                    <li><Link to={webBaseUrl + "safety-resources.html"}>Safety Resources</Link></li>
                                    <li><Link to={webBaseUrl + "pricing.html"}>Pricing</Link></li>
                                    <li className="active"><Link to="contact.html">Contact</Link></li>
                                </>
                            ) : (
                                <>
                                    {checkPermission('DASHBOARD', 'view') && <li><Link to="/dashboard" className={(props.menu === 'dashboard') ? 'active' : ''}>Dashboard</Link></li>}
                                    {checkPermission('USERS', 'add') &&
                                        <li {...usersAttr}>
                                            <Link to='#' className={props.menu === 'users' ? 'active' : ''}>Users</Link>
                                            <ul className={showUsersSubMenu}>
                                                {checkPermission('ADMINSTAFF', 'add') &&
                                                    <li>
                                                        <Link to="/users/admin-staffs" className={compseg === 'admin-staffs' ? 'active' : ''}>Admin Staffs</Link>
                                                    </li>
                                                }
                                                {checkPermission('AGENT', 'add') &&
                                                    <li>
                                                        <Link to="/users/agents" className={compseg === 'agents' ? 'active' : ''}>Agents</Link>
                                                    </li>
                                                }
                                                {(_role === 'SYS' || checkPermission('LANDLORD', 'add')) &&
                                                    <li>
                                                        <Link to="/users/land-lords" className={compseg === 'land-lords' ? 'active' : ''}>Land Lords</Link>
                                                    </li>
                                                }
                                                {checkPermission('TRADIE', 'add') &&
                                                    <li>
                                                        <Link to="/users/e-tradie" className={compseg === 'Electrician-Tradie' ? 'active' : ''}>Electrician Tradie</Link>
                                                    </li>
                                                }
                                                {checkPermission('TRADIE', 'add') &&
                                                    <li>
                                                        <Link to="/users/p-tradie" className={compseg === 'Plumber Tradie' ? 'active' : ''}>Plumber Tradie</Link>
                                                    </li>
                                                }
                                            </ul>
                                        </li>
                                    }
                                    {checkPermission('LANDLORD', 'add') &&
                                        <li><Link to="/land-lords" className={(props.menu === 'land-lords') ? 'active' : ''}>Land Loards</Link></li>
                                    }
                                    {(checkPermission('PROPERTY', 'view') || checkPermission('PROPERTY', 'add') || checkPermission('PROPERTY', 'send')) &&
                                        <>
                                            {(_role !== 'LDLORD') &&
                                                <li><Link to="/properties" className={(props.menu === 'properties') ? 'active' : ''}>Properties</Link></li>
                                            }
                                            {_is_landlord === 'Y' &&
                                                <li><Link to="/my-properties" className={(props.menu === 'my-properties') ? 'active' : ''}>My Properties</Link></li>
                                            }
                                        </>
                                    }
                                    {checkPermission('JOB', 'view') &&
                                        <li><Link to="/jobs" className={(props.menu === 'jobs') ? 'active' : ''}>Jobs</Link></li>
                                    }
                                    {/* checkPermission('JOB', 'viewinvoice') &&
                                        <li><Link to="/invoices" className={(props.menu === 'invoices') ? 'active' : ''}>Invoices</Link></li>
                                     */}
                                    {checkPermission('SETTINGS', 'add') &&
                                        <li {...settingsAttr}><Link to='#' className={(props.menu === 'my-account' && compseg !== 'profile') ? 'active' : ''}>Settings</Link>
                                            <ul className={showSettingsSubMenu}>
                                                {/* <li>
                                                    <Link to="/my-account/user-types" className={compseg === 'user-types' ? 'active' : ''}>User Types</Link>
                                                </li> */}
                                                <li>
                                                    <Link to="/my-account/prefix" className={compseg === 'prefix' ? 'active' : ''}>Prefix</Link>
                                                </li>
                                                <li>
                                                    <Link to="/my-account/postcode" className={compseg === 'postcode' ? 'active' : ''}>Postcode</Link>
                                                </li>
                                                <li>
                                                    <Link to="/my-account/job-status" className={compseg === 'job-status' ? 'active' : ''}>Job Status</Link>
                                                </li>
                                                <li>
                                                    <Link to="/my-account/email-template" className={compseg === 'email-template' ? 'active' : ''}>Email Templates</Link>
                                                </li>
                                            </ul>
                                        </li>
                                    }
                                    <li>
                                        <Link to="/my-account/profile" className={compseg === 'profile' ? 'active' : ''}>Profile</Link>
                                    </li>
                                    <li>
                                        <Link to="/my-account/change-password" className={(compseg === 'change-password') ? 'active' : ''}>Change Password</Link>
                                    </li>
                                    <li><Link to="#" onClick={handleLogout}>Logout</Link></li>

                                </>
                            )}
                        </ul>
                    </div>
                </div >
            </div >
            <header className="themeholy-header header-layout1 header-layout2">
                <div className="header-top">
                    <div className="container themeholy-container">
                        <div className="row justify-content-center justify-content-lg-between align-items-center gy-2">
                            <div className="col-auto d-none d-lg-block">
                                <p className="header-notice">Welcome to Safety Ninja.</p>
                            </div>
                            <div className="col-auto">
                                <div className="header-links">
                                    <ul>
                                        <li className="d-none"><i className="fal fa-clock"></i>Work Time: Mon - Fri 09AM - 6PM</li>
                                        {userType && <li>{userType + ' Dashboard'}<span className='text-white d-none d-lg-inline-block'>&nbsp;&nbsp;|&nbsp;&nbsp;</span></li>}
                                        {userType && <li className='text-yellow'>Logged in as {_user_name}</li>}
                                        <li className={"d-none d-lg-inline-block " + (!userType ? "no-pip" : "")}>
                                            <div className="login-link">
                                                {!showHeaderMenu ? (
                                                    <>
                                                        <Link to='https://app.safetyninja.360membershipcrm.com.au/'>Login</Link>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Link to='/my-account' className={(props.menu === 'my-account') ? 'active' : ''}>My Account</Link> &nbsp;&nbsp; | &nbsp;&nbsp; <Link onClick={handleLogout} to='#'>Log Out</Link>
                                                    </>
                                                )}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-wrapper">
                    <div className="menu-area">
                        <div className="container themeholy-container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto">
                                    <div className="header-logo"><Link to={webBaseUrl}><img src={Logo} alt="Safety Ninja" /></Link></div>
                                </div>
                                <div className="col">
                                    <div className={"menu-top d-none d-xl-block " + showHeaderMenu}>
                                        <div className="row justify-content-between align-items-center">
                                            <div className="col-auto">
                                                <div className="header-info">
                                                    <div className="icon-btn"><i className="fas fa-phone"></i></div>
                                                    <div className="media-body"><span className="header-info_label">Call Us Any Time:</span> <Link to="tel:1300175528" className="header-info_link">1300 175 528</Link></div>
                                                </div>
                                            </div>
                                            <div className="col-auto">
                                                <div className="header-info">
                                                    <div className="icon-btn"><i className="fas fa-messages"></i></div>
                                                    <div className="media-body"><span className="header-info_label">Email Us: </span><Link to="mailto:info@safetyninja.com.au" className="header-info_link">info@SafetyNinja.com.au</Link></div>
                                                </div>
                                            </div>
                                            <div className="col-auto"><Link to={webBaseUrl + "contact.html"} className="themeholy-btn style4">Get a Quote</Link></div>
                                        </div>
                                    </div>
                                    <div className="menu-wrap">
                                        <div className="row">
                                            <div className="col text-end text-xl-start">
                                                <nav className="main-menu d-none d-lg-inline-block">
                                                    <ul>
                                                        {!showHeaderMenu ? (
                                                            <>
                                                                <li>
                                                                    <Link to={webBaseUrl}>Home</Link>
                                                                </li>
                                                                <li><Link to={webBaseUrl + "our-services.html"}>Our Services</Link></li>
                                                                <li><Link to={webBaseUrl + "why-choose-our-services.html"}>Why Choose Our Service?</Link></li>
                                                                <li><Link to={webBaseUrl + "safety-resources.html"}>Safety Resources</Link></li>
                                                                <li><Link to={webBaseUrl + "pricing.html"}>Pricing</Link></li>
                                                                <li ><Link to={webBaseUrl + "contact.html"} className="active">Contact</Link></li>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {checkPermission('DASHBOARD', 'view') && <li><Link to="/dashboard" className={(props.menu === 'dashboard') ? 'active' : ''}>Dashboard</Link></li>}
                                                                {checkPermission('USERS', 'add') &&
                                                                    <li><Link to='/users' className={(props.menu === 'users') ? 'active' : ''}>Users</Link></li>
                                                                }
                                                                {checkPermission('LANDLORD', 'add') &&
                                                                    <li><Link to="/land-lords" className={(props.menu === 'users') ? 'active' : ''}>Land Lords</Link></li>
                                                                }
                                                                {(checkPermission('PROPERTY', 'view') || checkPermission('PROPERTY', 'add') || checkPermission('PROPERTY', 'send')) &&
                                                                    <>
                                                                        {(_role !== 'LDLORD') &&
                                                                            <li><Link to="/properties" className={(props.menu === 'properties') ? 'active' : ''}>Properties</Link></li>
                                                                        }
                                                                        {_is_landlord === 'Y' &&
                                                                            <li><Link to="/my-properties" className={(props.menu === 'my-properties') ? 'active' : ''}>My Properties</Link></li>
                                                                        }
                                                                    </>
                                                                }
                                                                {checkPermission('JOB', 'view') &&
                                                                    <li><Link to="/jobs" className={(props.menu === 'jobs') ? 'active' : ''}>Jobs</Link></li>
                                                                }
                                                                {/* checkPermission('JOB', 'viewinvoice') &&
                                                                    <li><Link to="/invoices" className={(props.menu === 'invoices') ? 'active' : ''}>Invoices</Link></li>
                                                                 */}
                                                                {checkPermission('SETTINGS', 'add') &&
                                                                    <li><Link to="/my-account" className={(props.menu === 'my-account') ? 'active' : ''}>Settings</Link></li>
                                                                }
                                                            </>
                                                        )}
                                                    </ul>
                                                </nav>
                                                <button type="button" className="themeholy-menu-toggle d-block d-lg-none" onClick={() => handleMenuToggle('themeholy-body-visible')}><i className="far fa-bars"></i></button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="logo-bg"></div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default SiteHeader;