import { useState, useEffect, forwardRef, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Alert, IconButton, Collapse, Button, Chip, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DataTableList from '../DataTableList';
import { axiosFunction } from '../../common';
import { MasterForm } from "./MasterForm";
import { AccountContext } from "../../pages/MyAccount";
export const FormContext = createContext(null);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Masters = (props) => {
    const navigate = useNavigate(null);
    const { setBackDropOpen } = useContext(AccountContext);
    const [loader, setLoader] = useState(true);
    const [loaderText, setLoaderText] = useState(false);
    const [DTColumns, setDTColumns] = useState([]);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [confirmopen, setConfirmOpen] = useState(false);
    const [delData, setDelData] = useState({});
    const [masterTypeId, setMasterTypeId] = useState('');
    let mTypeArr = { 'TYPE': 'User Type', 'PREFIX': 'Prefix', 'JBSTS': 'Job Status' }
    const masterFormType = mTypeArr[props.type];
    const [formField, setFormField] = useState({
        'code': '',
        'description': '',
        'status': 'Y',
        'action': '',
    });
    const [alertMessage, setAlertMessage] = useState('');

    const fetchData = async () => {
        try {
            setBackDropOpen(true);
            var params = { type: props.type };
            const masterData = await axiosFunction('lkps', 'get', params);

            if (masterData.status === 'success') {
                setDTColumns(masterData.data);
                setLoader(false);
                setBackDropOpen(false);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(masterData.message);
                setBackDropOpen(false);
                /* if (masterData?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setBackDropOpen(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    }
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.type]);

    const rows: GridRowsProp = DTColumns;
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 100, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'code', headerName: 'Code', width: 250 },
        { field: 'description', headerName: 'Description', width: 300 },
        { field: 'status', headerName: 'Status', width: 200, renderCell: (params) => (params.row.status === 'Y' || params.row.status === 'D') ? <Chip label="Active" color="primary" size="small" /> : <Chip label="Inactive" color="warning" size="small" />, valueGetter: (params) => (params.row.status === 'Y') ? 'Active' : 'Inactive' },
        { field: 'actions', headerName: 'Actions', width: 150, sortable: false, renderCell: (params) => { return params.row.status !== 'D' ? <><Tooltip title={"Edit " + masterFormType} placement="top" arrow ><Button type="button" onClick={(e) => handleEdit(params.api.getRowIndexRelativeToVisibleRows(params.row.id), params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button></Tooltip> <Tooltip title={"Delete " + masterFormType} placement="top" arrow ><Button type="button" onClick={(e) => deleteRecord(params.row.id, params.row.category)} style={{ minWidth: '24px' }}><i className="fa fa-trash"></i></Button></Tooltip></> : '' } },
    ];

    const handleEdit = (key, data) => {
        setLoaderText(false);
        setMasterTypeId(data.id);
        setFormField({
            'code': data.code,
            'description': data.description,
            'status': data.status,
            'action': 'edit',
        });
        setDialogOpen(true);
        setTimeout(() => { document.getElementById('code').focus() }, 100);
    };

    const handleAdd = () => {
        setLoaderText(false);
        setMasterTypeId('');
        setFormField({
            'code': '',
            'description': '',
            'status': 'Y',
            'action': 'add',
        });
        setDialogOpen(true);
        setTimeout(() => { document.getElementById('code').focus() }, 100);
    };

    const deleteRecord = (id, category) => {
        var data = {
            "id": id,
            "category": category,
            "_method": 'DELETE'
        }
        setDelData(data);
        setConfirmOpen(true);
    }

    const handleDelete = async () => {
        try {
            const delResponse = await axiosFunction('lkps/' + delData.id, 'post', delData);
            setAlertOpen(true);
            if (delResponse.status === 'success') {
                setAlertStatus('success');
                setAlertMessage(delResponse.message);
                handleAutoClose();
                setLoaderText(false);
                setConfirmOpen(false);
                setDelData({});
                fetchData();
            } else {
                setAlertStatus('error');
                setAlertMessage(delResponse.message);
                setLoaderText(false);
                setConfirmOpen(false);
                setDelData({});
                /*  if (delResponse?.response?.status === 401) {
                     localStorage.clear();
                     navigate('/login');
                 } */
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoaderText(false);
            setConfirmOpen(false);
            setDelData({});
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    };

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setMasterTypeId('');
        setFormField({
            'code': '',
            'description': '',
            'status': 'Y',
            'action': '',
        });
    };

    const handleConfirmClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setConfirmOpen(false);
            setDelData({});
        }
    };

    const onSubmit = async (data) => {
        try {
            setLoaderText(true);
            //console.log(data); 
            let saveResponse = null;
            data.category = props.type;

            if (formField.action === 'edit') {
                data._method = 'PUT';
                saveResponse = await axiosFunction('lkps/' + masterTypeId, 'post', data);
            } else if (formField.action === 'add') {
                saveResponse = await axiosFunction('lkps', 'post', data);
            }

            setAlertOpen(true);
            if (saveResponse.status === 'success') {
                setAlertStatus('success');
                setAlertMessage(saveResponse.message);
                handleAutoClose();
                handleDialogClose();
                setLoaderText(false);
                fetchData();
            } else {
                setAlertStatus('error');
                setAlertMessage(saveResponse.message);
                setLoaderText(false);
                document.querySelector('#masterform').scrollIntoView({ behavior: 'smooth' });
                /* if (saveResponse?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoaderText(false);
            document.querySelector('#masterform').scrollIntoView({ behavior: 'smooth' });
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    };

    return (
        <>
            <Collapse in={alertOpen}>
                <Alert variant="filled" severity={alertStatus}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {alertMessage}
                </Alert>
            </Collapse>
            <Dialog
                open={confirmopen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleConfirmClose}
            >
                <DialogTitle>Are you sure you want to delete this {masterFormType}?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        If yes, {masterFormType} will be deleted from the records and no longer be exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                    <Button className="themeholy-btn btn-primary" onClick={() => handleDelete({ delData })}>Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullScreen
                open={dialogOpen}
                onClose={handleDialogClose}
                TransitionComponent={Transition}
            >
                <FormContext.Provider value={{
                    handleDialogClose, onSubmit, loaderText, formField, setFormField, alertStatus,
                    alertMessage,
                    alertOpen,
                    setAlertOpen
                }}>
                    <MasterForm formType={props.type} />
                </FormContext.Provider>
            </Dialog>
            <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New {masterFormType}</Button>
            </div>
            <DataTableList columns={columns} rows={rows} loading={loader} />
        </>
    );
}
export default Masters;