import { useState, useEffect, Fragment } from 'react';
import { useNavigate } from "react-router-dom";
import {
    Button, Dialog, DialogContent, DialogContentText, DialogTitle, useMediaQuery, useTheme, Grid, Box, TextareaAutosize, IconButton
} from '@mui/material';
import { useForm } from "react-hook-form";
import { axiosFunction } from '../../common';
import ninja32icon from '../../assets/img/ninja32.png';
import { CheckCircleOutline, WarningAmber, Send, Close } from '@mui/icons-material';

const MessageSuccess = ({ handleClose, setMessageHeading }) => {
    useEffect(() => {
        setMessageHeading(<><div className='text-center'>
            <CheckCircleOutline sx={{ fontSize: '5em', margin: 'auto' }} className='text-white' />
        </div>
            ⚡ Ninja Strike Successful! ⚡</>);
    }, [setMessageHeading]);
    return (<Fragment>
        <DialogContentText>
            Your message has been sent successfully. Great job!

            Keep those ninja skills strong! 💪<img src={ninja32icon} width='18px' alt='ninja32icon' />
        </DialogContentText>
        <div className='mt-3'>
            <div className='row'>
                <div className='col-12 mb-3 text-center'>
                    <Button onClick={handleClose} className="themeholy-btn btn-primary">
                        Close
                    </Button>
                </div>
            </div>
        </div>
    </Fragment>
    )
}

const MessageFailure = ({ handleClose, handleMessage, setMessageHeading }) => {
    useEffect(() => {
        setMessageHeading(<><div className='text-center'>
            <WarningAmber sx={{ fontSize: '5em', margin: 'auto' }} className='text-white' />
        </div>
            💔 Ninja Missed the Mark! 💔</>);
    }, [setMessageHeading]);
    return (<Fragment>
        <DialogContentText>
            Uh-oh, your message didn’t go through. <img src={ninja32icon} width='18px' alt='ninja32icon' />💳 But don't fret! Ninjas are all about perseverance. Let’s double back and give it another go!

            Ready to make it happen? Try again! 🥋🚀
        </DialogContentText>
        <div className='mt-3'>
            <div className='row'>
                <div className='col-12 col-md-6 mb-3'>
                    <Button onClick={handleClose} className="themeholy-btn btn-secondary w-100">
                        Close
                    </Button>
                </div>
                <div className='col-12 col-md-6'>
                    <Button onClick={() => handleMessage('message')} autoFocus className="themeholy-btn btn-primary w-100">
                        Try Again!
                    </Button>
                </div>
            </div>
        </div>
    </Fragment>
    )
}

const MessageForm = ({ messageData, setMsgSteps, setUpdateTabList }) => {
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate(null);
    //const ccNameFld = useRef();
    const { register, handleSubmit, formState: { errors }, trigger,
        setValue } = useForm();

    const validation = (e, name) => {
        setValue(name, e.target.value, true);
        trigger(name);
    };

    useEffect(() => {
        register("message.message", { required: "Enter a message to send" });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [register]);

    //console.log(meta);
    const onFormSubmit = async (data) => {
        let msgData = {
            property_id: messageData.property_id,
            to: messageData.to,
            user_code: messageData.usr_code,
            user_id: messageData.usr_id,
            type: 'SMS',
            message: data.message.message
        }
        //console.log(msgData);
        try {
            setLoader(true);
            const response = await axiosFunction('messages', 'post', msgData);
            if (response.status === 'success') {
                setMsgSteps((prev) => ({ ...prev, message: false, success: true }));
                setUpdateTabList(true);
                setLoader(false);
            } else {
                setMsgSteps((prev) => ({ ...prev, message: false, failure: true }));
                setLoader(false);
                /* if (response?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setMsgSteps((prev) => ({ ...prev, message: false, failure: true }));
            setLoader(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    };

    return (
        <Box component="form" noValidate id="messageForm" autoComplete="off" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={7}>
                    <h5 className='text-theme'>To: {messageData?.to}</h5>
                </Grid>
                <Grid item xs={5} className='text-end'>
                    <Button type="submit" autoFocus className="bg-theme2 text-white" disabled={loader}>
                        {(loader) ? <i className="fa fa-spin fa-spinner"></i> : <Send />}
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <TextareaAutosize style={{ minHeight: '100px' }} name="message.message" placeholder='Enter a message to send' maxLength={160} onChange={(e) => validation(e, "message.message")}
                    ></TextareaAutosize>
                    <label className="w-full error text-red text-sm text-start">{errors?.message?.message?.message}</label>
                </Grid>
            </Grid>
        </Box>
    )
}

export const MessageDialog = ({ openMessageDialog, messageData, setMessageData, setOpenMessageDialog, setUpdateTabList }) => {
    const [messageHeading, setMessageHeading] = useState('Send Message');
    const [msgSteps, setMsgSteps] = useState({
        message: false,
        success: false,
        failure: false
    });
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    //const { handleLogout } = useContext(AppContext);

    useEffect(() => {
        setMsgSteps({
            message: true,
            success: false,
            failure: false
        });
        setMessageHeading(<>Send Message to {messageData?.name}</>)
    }, [messageData, openMessageDialog]);

    /* const handleClickOpen = () => {
        setOpen(true);
    }; */

    const handleClose = () => {
        setOpenMessageDialog(false);
        setMessageHeading('');
        // setOpen(false);
        setMsgSteps({
            message: false,
            success: false,
            failure: false
        });
        setMessageData({});
    };

    const handleMessage = (step) => {
        if (step === 'message') {
            setMsgSteps((prev) => ({ ...prev, message: true, failure: false }));
        }
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={openMessageDialog}
            scroll='body'
        >
            <DialogTitle id="responsive-message-dialog-title" className='bg-theme text-white'>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        color: 'black',
                        zIndex: '1000'
                    }}
                >
                    <Close />
                </IconButton>
                <div>
                    {messageHeading}
                    <Grid className='text-yellow'><small>Property:{messageData.property_details}</small></Grid>
                </div>
            </DialogTitle>
            <DialogContent className='p-3'>
                {msgSteps.message === true && <MessageForm messageData={messageData} handleMessage={handleMessage} setMsgSteps={setMsgSteps} setUpdateTabList={setUpdateTabList} />}

                {msgSteps.success === true && <MessageSuccess handleClose={handleClose} setMessageHeading={setMessageHeading} />}

                {msgSteps.failure === true && <MessageFailure handleClose={handleClose} handleMessage={handleMessage} setMessageHeading={setMessageHeading} />}
            </DialogContent>
        </Dialog>
    );
}