import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { axiosFunction } from '../../common';
import {
    Box, CircularProgress, Fade, Typography, Button, TextField,
    Collapse, Alert, IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const InvoiceForm = ({ action, handleDialogClose }) => {
    const navigate = useNavigate(null);
    //const _role = localStorage.getItem("_role");
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loader, setLoader] = useState(true);
    const [loaderText, setLoaderText] = useState(false);
    const [invoiceItems, setInvoiceItems] = useState([{ description: "", amount: "" }]);
    const [formField, setFormField] = useState({ subtotal: '0.00', tax_rate: '0.00', total: '0.00' })

    const { handleSubmit } = useForm();
    // const curDate = new Date();
    const actionFun = action;

    useEffect(() => {
        setLoader(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFieldChange = (i, e) => {
        let newFormValues = [...invoiceItems];
        var subtotal = '0.00';
        var tax_rate = '0.00';
        var total = '0.00';
        newFormValues[i][e.target.name] = e.target.value;
        if (e.target.name === 'amount') {
            newFormValues.map(element => subtotal = (element.amount === '') ? parseFloat(subtotal) + 0 : parseFloat(subtotal) + parseFloat(element.amount));
            subtotal = subtotal.toFixed(2);
            tax_rate = parseFloat(subtotal) * (10 / 100);
            tax_rate = tax_rate.toFixed(2);
            total = parseFloat(subtotal) + parseFloat(tax_rate);
            total = total.toFixed(2);
            setFormField({ subtotal: subtotal, tax_rate: tax_rate, total: total });
        }
        setInvoiceItems(newFormValues);
    }

    const addInvoiceItem = () => {
        setInvoiceItems([...invoiceItems, { description: "", amount: "" }])
    }

    const removeInvoiceItem = (i) => {
        let newFormValues = [...invoiceItems];
        newFormValues.splice(i, 1);
        setInvoiceItems(newFormValues)
    }

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const onInvoiceSubmit = async (data) => {
        data.items = formField;
        // console.log(data);
        try {
            let saveResponse = null;

            if (action === 'edit') {
                data._method = 'PUT';
                saveResponse = await axiosFunction.post('invoices/' + formField.id, 'post', data);
            } else if (action === 'add') {
                saveResponse = await axiosFunction('invoices', 'post', data);
            }

            setAlertOpen(true);
            if (saveResponse.status === 'success') {
                setAlertStatus('success');
                setAlertMessage(saveResponse.message);
                handleAutoClose();
                setLoaderText(false);
            } else {
                setAlertStatus('error');
                setAlertMessage(saveResponse.message);
                setLoaderText(false);
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoaderText(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    };

    return (
        <>
            <Typography
                variant='h5'
                className='header-top text-white p-3'
            >{actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Invoice</Typography>

            {loader && <Box sx={{ textAlign: 'center', marginTop: '40px' }}>
                <Fade
                    in={loader}
                    unmountOnExit
                >
                    <CircularProgress />
                </Fade>
            </Box>}
            {!loader && <div className="container">
                <Box component="form" id="invoice-form" name="invoice-form" noValidate autoComplete="off" onSubmit={handleSubmit(onInvoiceSubmit)}>
                    <div className="row">
                        {/* <div className="col-md-4">
                            <strong>Invoice Number:</strong> {formField.invoice_number ? formField.invoice_number : '#####'}
                        </div> */}
                        <div className="col-md-12">
                            <div className="row">
                                <div className="col-md-12 text-end">
                                    <Button type="button" autoFocus className="themeholy-btn btn-primary m-3" onClick={() => addInvoiceItem()}>
                                        Add Item
                                    </Button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-1 fw-bold m-auto">S.No</div>
                                <div className="col-5 fw-bold">Description</div>
                                <div className="col-3 fw-bold">Amount AUD</div>
                                <div className="col-1 fw-bold m-auto"></div>
                            </div>
                            {invoiceItems.map((element, index) => (
                                <div className="row" key={index}>
                                    <div className="col-1 fw-bold m-auto">{index + 1}</div>
                                    <div className="col-5">
                                        <TextField
                                            fullWidth
                                            id={"description_" + index}
                                            name="description"
                                            variant="outlined"
                                            inputProps={{ value: element.description, maxLength: 100 }}
                                            onChange={e => handleFieldChange(index, e)}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <TextField
                                            fullWidth
                                            id={"amount_" + index}
                                            name="amount"
                                            variant="outlined"
                                            inputProps={{ value: element.amount, maxLength: 4 }}
                                            onChange={e => handleFieldChange(index, e)}
                                        /></div>
                                    <div className="col-md-1 m-auto text-end">
                                        {
                                            index ?
                                                <Link to='#' onClick={() => removeInvoiceItem(index)}><i className="fa fa-trash" ></i></Link>
                                                : null
                                        }
                                    </div>
                                </div>
                            ))}

                            <div className="row mt-3 fs-xxl fw-bold">

                                <div className="col-7 text-end p-3">
                                    Subtotal AUD :
                                </div>
                                <div className="col-5 p-3">
                                    {formField.subtotal}
                                </div>
                                <div className="col-7 text-end p-3">
                                    GST 10% AUD :
                                </div>
                                <div className="col-5 p-3">
                                    {formField.tax_rate}
                                </div>
                                <div className="col-7 text-end p-3">
                                    Total AUD :
                                </div>
                                <div className="col-5 p-3">
                                    {formField.total}
                                </div>

                            </div>
                        </div>
                        <div className="col-md-12 text-center p-5">
                            <Collapse in={alertOpen}>
                                <Alert variant="filled" severity={alertStatus}
                                    action={
                                        <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                                setAlertOpen(false);
                                            }}
                                        >
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    }
                                    sx={{ mb: 2 }}
                                >
                                    {alertMessage}
                                </Alert>
                            </Collapse>
                            <Button type="button" className="themeholy-btn btn-primary m-3" onClick={handleDialogClose}>
                                Close
                            </Button>
                            <Button type="submit" autoFocus className="themeholy-btn btn-primary m-3" disabled={loaderText}>{loaderText ? 'Submitting' : 'Submit'}
                            </Button>
                        </div>
                    </div>
                </Box>

            </div>}

        </>
    );
}

export default InvoiceForm;