import { useState, useEffect, useContext, forwardRef } from "react";
import { useNavigate, Link } from 'react-router-dom';
import { axiosFunction, checkPermission } from '../../common';
import { Box, IconButton, AppBar, Toolbar, Typography, Chip, Button, Collapse, Alert, Slide, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Backdrop, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { FormContext } from "./ManageProperties";
import DataTableList from '../DataTableList';
import OwnerInfo from "./ownerinfo";
import OwnerForm from "./ownerform";
import { PropertyTabs } from "./PropertyTabs";
import { PaymentAlert } from "../Modal/PaymentDialog";
import { Textsms } from '@mui/icons-material';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const PropertyView = ({ own }) => {
    const navigate = useNavigate(null);
    const _role = localStorage.getItem("_role");
    const _profile_id = localStorage.getItem("_profile_id");
    //const [doNot, setDoNot] = useState(_do_not_show);   
    const [backdropopen, setBackDropOpen] = useState(false);

    let { handleViewDialogClose, handleAdd, handleEdit, handleView, userOptions, postcodeOptions, propertyId, setPropertyId, viewField, setViewField, viewUpdateFlag, openForm, updateTabList, setUpdateTabList, handleSMS } = useContext(FormContext);

    const [DTColumns, setDTColumns] = useState([]);
    const [loader, setLoader] = useState(true);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [noteOpen, setNoteOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [ownerEdit, setOwnerEdit] = useState(false);
    const [loaderText, setLoaderText] = useState(false);
    const [viewChangeFlag, setViewChangeFlag] = useState(false);
    const [paymentData, setPaymentData] = useState({ openPayment: 'close' });
    //const [updateTabList, setUpdateTabList] = useState(false);
    const [openPaymentDialog, setOpenPaymentDialog] = useState(false);

    //let defaultViewData = {};

    const fetchData = async () => {
        var params = {};
        var payData = {};

        if (!viewField.owner_id && (_role === 'LDLORD' || own === 'Y')) {
            params.owner_id = _profile_id;
        } else {
            params.owner_id = viewField.owner_id;
        }

        if (params.owner_id) {
            params.own = own;
            try {
                setBackDropOpen(true);
                const response = await axiosFunction('propertyview', 'post', params);

                if (response.status === 'success') {
                    setDTColumns(response.data);
                    if (!propertyId && response.data.length > 0) {
                        setPropertyId(response.data[0].id);
                    }
                    if (response.data.length > 0) {
                        response.data.map(pdata => {
                            if (pdata.id === propertyId) {
                                var data = pdata;
                                setPropertyId(data.id);

                                setViewField({
                                    'property_id': data.id,
                                    'owner_id': data.owner_id,
                                    'owner_prefix': data.owner.prefix,
                                    'owner_name': data.owner.prefix + '. ' + data.owner.firstname + ' ' + data.owner.lastname,
                                    'owner_email': data.owner.individualuser.email,
                                    'owner_mobile': data.owner.individualuser.mobile,
                                    'owner_address1': data.owner.address1,
                                    'owner_address2': data.owner.address2,
                                    'owner_suburb': data.owner.suburb,
                                    'owner_state': data.owner.state,
                                    'owner_postcode': data.owner.postcode,
                                    'owner_credit': data.owner.credit,
                                    'agent_id': data.agent_id,
                                    'agent_prefix': data.agent ? data.agent.prefix : '',
                                    'tenancy_name': data.tenancy_name,
                                    'tenancy_email': data.tenancy_email,
                                    'tenancy_mobile': data.tenancy_mobile,
                                    'agent_name': data.agent ? data.agent.prefix + '. ' + (data.agent.firstname ? data.agent.firstname + ' ' : '') + data.agent.lastname : '',
                                    'agency_name': data.agent ? data.agent.agency_name : '',
                                    'agent_email': data.agent ? data.agent.individualuser.email : '',
                                    'agent_mobile': data.agent ? data.agent.individualuser.mobile : '',
                                    'address1': data.address1,
                                    'address2': data.address2,
                                    'suburb': data.suburb,
                                    'state': data.state,
                                    'postcode': data.postcode,
                                    'product': data.product,
                                    'product_code': data.product_code,
                                    'product_desc': data.product.description + ' - $' + data.product.service_charge,
                                    'status': data.status,
                                    'payments': data.payments,
                                    'action': 'view'
                                });

                                if (_role === 'LDLORD' || _role === 'AGENT') {
                                    payData = data.payments;
                                    payData.property_details = data.address1 + (data.address2 && data.address1 + ', ') + ', ' + data.suburb + ', ' + data.state + ' - ' + data.postcode;

                                    payData.paytype = 'owner';
                                    setPaymentData(payData);
                                    payData.openPayment = (localStorage.getItem("_do_not_show") === "N" && payData.owner.overall > 0) ? 'auto' : 'close';

                                    if (localStorage.getItem("_do_not_show") === "N" && payData.owner.overall > 0) {
                                        setOpenPaymentDialog(true)
                                    }
                                }

                                //defaultViewData = pdata;
                                return false;
                            }
                            return true;
                        });
                    } else {
                        try {
                            const response = await axiosFunction('individuals/' + _profile_id, 'get');
                            if (response.status === 'success') {
                                setViewField({
                                    'owner_id': response.data.id,
                                    'owner_name': response.data.prefix + '. ' + response.data.firstname + ' ' + response.data.lastname,
                                    'owner_email': response.data.individualuser.email,
                                    'owner_mobile': response.data.individualuser.mobile,
                                    'owner_address1': response.data.address1,
                                    'owner_address2': response.data.address2,
                                    'owner_suburb': response.data.suburb,
                                    'owner_state': response.data.state,
                                    'owner_postcode': response.data.postcode,
                                    'owner_credit': response.data.credit,
                                });
                            } else {
                                /* if (response?.response?.status === 401) {
                                    localStorage.clear();
                                    navigate('/login');
                                } */
                            }
                        } catch (e) {
                            if (e?.response?.status === 401) {
                                localStorage.clear();
                                navigate('/login');
                            } else {
                                console.log(e);
                            }
                        }
                    }
                    setLoader(false);
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(response.message);
                    //handleAutoClose();
                    /* if (response?.response?.status === 401) {
                        localStorage.clear();
                        navigate('/login');
                    } */
                }
                setBackDropOpen(false);
            } catch (e) {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(e.message);
                setBackDropOpen(false);
                if (e?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                }
            }

        }
    }


    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [propertyId, viewUpdateFlag]);


    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleOpenAdd = () => {
        //setDialogViewOpen(false); 
        //console.log(viewField.owner_id);
        const data = {
            owner_id: viewField.owner_id,
            owner_prefix: viewField.owner_prefix,
            owner_name: viewField.owner_name,
            agent_id: viewField.agent_id,
            agent_prefix: viewField.agent_prefix,
            agent_name: viewField.agent_name,
            tenancy_id: viewField.tenancy_id,
            tenant_prefix: viewField.tenancy_prefix,
            tenant_name: viewField.tenancy_name,
            agency_name: viewField.agency_name
        }
        handleAdd(data);
        //setTimeout(() => { document.getElementById('address1').focus() }, 100);
    };
    /*
        const handleOpenEdit = (key, data) => {
            //setDialogViewOpen(false);     
            handleEdit('viewedit', key, data);
            setTimeout(() => { document.getElementById('address1').focus() }, 100);
    
        }; */

    const viewAlert = (notes) => {
        setNoteOpen(true);
        setNotes(notes);
    };


    const handleNoteClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setNoteOpen(false);
            setNotes('');
        }
    };

    const handleOwnerEdit = () => {
        // setViewField(defaultViewData);
        setOwnerEdit(true);
    }

    const handleOwnerView = (e) => {
        setOwnerEdit(false);
        setLoaderText(false);
        document.getElementById('propertyview').scrollIntoView({ behavior: 'smooth' });
    }

    const handlePay = (data, paytype) => {
        var payData = {};
        if (paytype === 'invoice') {
            payData.overall = data.total;
            payData.invoice_id = data.id;
            payData.invoice_number = data.invoice_number;
            payData.property_details = data.services.property.address1 + (data.services.property.address2 && ', ' + data.services.property.address2) + ', ' + data.services.property.suburb + ', ' + data.services.property.state + ' - ' + data.services.property.postcode;
        } else {
            payData = data.payments;
            payData.property_details = data.address1 + (data.address2 && ', ' + data.address2) + ', ' + data.suburb + ', ' + data.state + ' - ' + data.postcode;
        }

        payData.openPayment = (payData.openPayment === 'click') ? 'press' : 'click';
        setOpenPaymentDialog(true);
        payData.paytype = paytype;
        setPaymentData(payData);
    }

    const onSubmit = async (data) => {
        try {
            setLoaderText(true);
            //console.log(data);
            //console.log(propertyId);
            if (_role === 'AGENT') data.agent_id = viewField.agent_id;

            data.id = propertyId;
            //setFormField((prev) => ({ ...prev, owner_id: data.owner_id }));
            if (viewChangeFlag) {
                const saveresponse = await axiosFunction('updateowner', 'post', data)
                setAlertOpen(true);
                if (saveresponse.status === 'success') {
                    fetchData();
                    setAlertStatus('success');
                    setAlertMessage(saveresponse.message);
                    handleAutoClose();
                } else {
                    setAlertStatus('error');
                    setAlertMessage(saveresponse.message);
                    /*  if (saveresponse?.response?.status === 401) {
                         localStorage.clear();
                         navigate('/login');
                     } */
                }

                setLoaderText(false);
                setOwnerEdit(false);

            } else {
                setLoaderText(false);
                setOwnerEdit(false);
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    };

    const rows: GridRowsProp = DTColumns;
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        {
            field: 'tenancy_name', headerName: 'Tenant', width: 200, renderCell: (params) => {
                return (<div><div>N: {params.row.tenancy_name}</div><div>E: {params.row.tenancy_email}</div><div> M: {params.row.tenancy_mobile} {params.row.tenancy_mobile && checkPermission('PROPERTY', 'send') && <Link to='#' onClick={() => handleSMS(params.row, 'TENANT', false)}><Textsms color='error' /></Link>}</div></div >)
            }, valueGetter: (params) => params.row.tenancy_name + ' ' + params.row.tenancy_email + ' ' + params.row.tenancy_mobile
        },
        { field: 'service_duedate', headerName: 'Service Due Date', width: 140 },
        { field: 'address', headerName: 'Address', width: 180, renderCell: (params) => (params.row.address1 + (params.row.address2 ? ', ' + params.row.address2 : '') + ', ' + params.row.suburb + ', ' + params.row.state + ' - ' + params.row.postcode), valueGetter: (params) => params.row.address1 + (params.row.address2 ? ', ' + params.row.address2 : '') + ', ' + params.row.suburb + ', ' + params.row.state + ' - ' + params.row.postcode },
        { field: 'status', headerName: 'Status', width: 100, renderCell: (params) => (params.row.status === 'Y') ? <Chip label="Active" color="success" size="small" /> : (params.row.status === 'P') ? <Chip label="Pending" color="error" size="small" /> : <Chip label="Inactive" color="warning" size="small" />, valueGetter: (params) => (params.row.status === 'Y') ? 'Active' : (params.row.status === 'P') ? 'Pending' : 'Inactive' },
        { field: 'overdue', headerName: 'Overdue AUD', width: 180, renderCell: (params) => <>{params.row.payments.overall ? params.row.payments.overall.toFixed(2) : '0.00'} {['AGENT', 'LDLORD'].includes(_role) && params.row.payments.overall > 0 && <Button type="button" onClick={(e) => handlePay(params.row, 'property')} className="btn text-white bg-theme" style={{ marginLeft: '15px' }}>Pay</Button>}</>, valueGetter: (params) => params.row.payments.overall ? params.row.payments.overall.toFixed(2) : '0.00' },
        {
            field: 'actions', headerName: 'Actions', width: 150, sortable: false, renderCell: (params) => {
                return (<>
                    {checkPermission('PROPERTY', 'view') && <Tooltip title="View Property" placement="top" arrow ><Button type="button" onClick={(e) => { handleView(params.row); setPaymentData(params.row.payments); }} style={{ minWidth: '24px' }}><i className="fa fa-eye"></i></Button ></Tooltip>}
                    {checkPermission('PROPERTY', 'edit') && <Tooltip title="Edit Property" placement="top" arrow ><Button type="button" onClick={(e) => handleEdit(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button></Tooltip>}
                    {checkPermission('PROPERTY', 'notes') && params.row.showalert === 'Y' && <Tooltip title="View Alert" placement="top" arrow ><Button type="button" onClick={(e) => viewAlert(params.row.alertnote)} style={{ minWidth: '24px' }}><i className="fa fa-message"></i></Button></Tooltip>}
                </>)
            }
        },

    ];

    // console.log(images);

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropopen}
            >
                {/*<CircularProgress color="inherit" />*/}
                <div className="circle bg-theme text-center">
                    Data is loading.<br />
                    It will take a moment.<br />
                    <div className="loaderDots">Please wait</div>
                </div>
            </Backdrop>
            {viewField && (
                <>
                    <Box>
                        {(own !== 'Y') && (
                            <AppBar sx={{ position: 'fixed' }} className="app-header-top">
                                <Toolbar>
                                    <IconButton
                                        edge="start"
                                        color="inherit"
                                        onClick={(e) => handleViewDialogClose(e, viewChangeFlag)}
                                        aria-label="close"
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                                        View Property
                                    </Typography>

                                </Toolbar>
                            </AppBar>
                        )}
                        <Dialog
                            fullWidth
                            open={noteOpen}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={handleNoteClose}
                        >
                            <DialogTitle sx={{ bgcolor: '#000000', color: '#ffffff', marginBottom: '15px' }}>Notes</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-notes">
                                    <i className="fa fa-info-circle"></i> {notes}
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button className="themeholy-btn btn-primary" onClick={handleNoteClose}>Ok</Button>
                            </DialogActions>
                        </Dialog>

                        {own !== 'Y' && <div style={{ margin: "45px" }}>&nbsp;</div>}

                        <div className="container" id="propertyview">
                            <div className="row">
                                <div className="col-md-12 col-lg-6 d-flex" >
                                    <div className="card border-radius w-100">
                                        <div className="card-header">
                                            <div className="row">
                                                <h5 className="mt-1 col-md-8" style={{ textAlign: 'left' }}>{propertyId && 'Property '}Land Lord Info:</h5>
                                                {!ownerEdit && openForm && (propertyId ? <Link onClick={handleOwnerEdit} className="col-md-4 mt-3 text-end"><i className="fa fa-pencil"></i></Link> : <Link to='/my-account' className="col-md-4 mt-3 text-end"><i className="fa fa-pencil"></i></Link>)}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <FormContext.Provider value={
                                                {
                                                    viewField,
                                                    setViewField,
                                                    userOptions,
                                                    postcodeOptions,
                                                    onSubmit,
                                                    handleOwnerView,
                                                    loaderText,
                                                    viewChangeFlag,
                                                    setViewChangeFlag,
                                                    propertyId,
                                                    handleSMS

                                                }
                                            }>
                                                {ownerEdit ? <OwnerForm /> : <OwnerInfo />}

                                            </FormContext.Provider>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12 col-lg-6 d-flex">
                                    <div className="card border-radius w-100">
                                        <div className="card-header">
                                            <h5 className="mt-1" style={{ textAlign: 'left' }}>Payment Info:</h5>
                                        </div>
                                        <div className="card-body text-start">
                                            <div className="row">
                                                <label className="col-6 fw-bold">Overall Due Amount (incl. GST)</label>
                                                <div className="col-6">: ${viewField.payments && viewField.payments.owner && viewField.payments.owner.overall ? viewField.payments.owner.overall.toFixed(2) : '0.00'}</div>
                                            </div>
                                            <div className="row">
                                                <label className="col-6 fw-bold">Available Credit</label>
                                                <div className="col-6">: ${viewField.owner_credit ? viewField.owner_credit.toFixed(2) : '0.00'}</div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12 mt-3">
                                                    {(['AGENT', 'LDLORD'].includes(_role) && viewField.payments && viewField.payments.owner && viewField.payments.owner.overall > 0) ?
                                                        <Button className="themeholy-btn btn-primary w-100" onClick={(e) => handlePay(viewField, 'owner')}>Pay Now</Button>
                                                        : <Button className="themeholy-btn btn-primary w-100" disabled style={{ backgroundColor: '#bdbdbd' }}>Pay Now</Button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>

                    <div className="container mt-3">
                        <Collapse in={alertOpen}>
                            <Alert variant="filled" severity={alertStatus}
                                action={
                                    <IconButton
                                        aria-label="close"
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            setAlertOpen(false);
                                        }}
                                    >
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                }
                                sx={{ mb: 2 }}
                            >
                                {alertMessage}
                            </Alert>
                        </Collapse>
                        {checkPermission('PROPERTY', 'add') &&
                            <div style={{ display: "flex", marginBottom: "15px" }}>
                                <Button type="button" className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={() => handleOpenAdd()}>Add {own === 'Y' ? 'My' : ''}  New Property</Button>
                            </div>
                        }
                        <DataTableList columns={columns} rows={rows} loading={loader} id={propertyId} columnVisibilityModel={{ actions: (checkPermission('PROPERTY', 'view') || checkPermission('PROPERTY', 'edit')) }} />
                    </div>

                    {propertyId && viewField.address1 && <div className="container mt-5 text-start">
                        <Typography sx={{ mb: 5 }} variant="h4" component="div">
                            {viewField.address1 + (viewField.address2 ? ', ' + viewField.address2 : '') + ', ' + viewField.suburb + ', ' + viewField.state + ' - ' + viewField.postcode}
                        </Typography>
                        <PropertyTabs propertyId={propertyId} handlePay={handlePay} updateTabList={updateTabList} />
                    </div>}

                    <PaymentAlert paymentData={paymentData} setPaymentData={setPaymentData} fetchData={fetchData} setUpdateTabList={setUpdateTabList} openPaymentDialog={openPaymentDialog} setOpenPaymentDialog={setOpenPaymentDialog} />
                </>
            )}
        </>
    );
}