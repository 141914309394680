import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import { checkPermission, axiosFunction } from '../common';
import CardInfo from "../components/Dashboard/CardInfo";
import ListBox from "../components/Dashboard/ListBox";
//import LineAreaChart from "../components/Dashboard/LineAreaChart";
//import PieBoxChart from "../components/Dashboard/PieBoxChart";
//import MonthCalendar from "../components/Dashboard/MonthCalendar";
import { Alert, Collapse, Backdrop, IconButton } from "@mui/material";
import { MapsHomeWorkRounded, Diversity3Rounded, GroupsRounded, HandymanRounded } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

const Placeholder = () => {
    return (
        <div className="row">
            <div className="col-md-3 p-2"><span className="skeleton-box" style={{ width: '100%', height: '200px' }}></span></div>
            <div className="col-md-3 p-2"><span className="skeleton-box" style={{ width: '100%', height: '200px' }}></span></div>
            <div className="col-md-3 p-2"><span className="skeleton-box" style={{ width: '100%', height: '200px' }}></span></div>
            <div className="col-md-3 p-2"><span className="skeleton-box" style={{ width: '100%', height: '200px' }}></span></div>
            <div className="col-12 col-lg-6 p-2"><span className="skeleton-box" style={{ width: '100%', height: '300px' }}></span></div>
            <div className="col-12 col-lg-6 p-2"><span className="skeleton-box" style={{ width: '100%', height: '300px' }}></span></div>
            <div className="col-12 p-2"><span className="skeleton-box" style={{ width: '100%', height: '500px' }}></span></div>
        </div>
    )
}

const Dashboard = () => {
    const _user_name = localStorage.getItem("_user_name");
    const navigate = useNavigate(null);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [backdropopen, setBackDropOpen] = useState(false);
    const [showCard, setShowCard] = useState(false);
    const [showCalendar, setShowCalendar] = useState(false);
    const [events, setEvents] = useState([]);
    const [state, setState] = useState({
        'active_agents': 0,
        'inactive_agents': 0,
        'active_owners': 0,
        'inactive_owners': 0,
        'active_properties': 0,
        'inactive_properties': 0,
        'this_month_services': 0,
        'job_list': [],
        'pending_property_list': []
    });
    const analyticsRef = useRef(null);
    const propertyRef = useRef(null);
    const calendarRef = useRef(null);

    const fetchData = async () => {
        try {
            setBackDropOpen(true);
            const response = await axiosFunction('auth/dashboard', 'get', {});
            if (response.status === 'success') {
                var items = response.item;
                setState({
                    'active_agents': items.individual && items.individual.active_agents,
                    'inactive_agents': items.individual && items.individual.inactive_agents,
                    'active_owners': items.individual && items.individual.active_owners,
                    'inactive_owners': items.individual && items.individual.inactive_owners,
                    'active_properties': items.property.active_properties ? items.property.active_properties : 0,
                    'inactive_properties': items.property.inactive_properties ? items.property.inactive_properties : 0,
                    'pending_properties': items.property.pending_properties ? items.property.pending_properties : 0,
                    'this_month_services': items.property.this_month_services ? items.property.this_month_services : 0,
                    'job_list': items.job_list && items.job_list,
                    'pending_property_list': items.pending_property_list && items.pending_property_list,
                });
                setShowCard(true);
                setBackDropOpen(false);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(response.message);
                setBackDropOpen(false);
                /* if (response?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setBackDropOpen(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    }

    //var events = [];
    /* events = [
        { title: 'Electrical Service', start: '2024-03-04T11:00:00' },
        { title: 'Electrical Service', start: '2024-03-05T09:00:00' },
        { title: 'Plumber Service', start: '2024-03-05T12:00:00' },
        { title: 'Electrical Service', start: '2024-03-06T14:00:00' },
        { title: 'Electrical Service', start: '2024-03-07T10:00:00' },
    ]; */

    const fetchServiceData = async () => {
        try {
            setBackDropOpen(true);
            const response = await axiosFunction('scheduleservices', 'get', {});
            if (response.status === 'success') {
                /*var events = [];
                 var startDateArr = [];
                // eslint-disable-next-line
                resData.data.map((value)=>{
                    startDateArr = value.service_date.split('-');
                    events.push({
                        title: value.property.address1 + (value.property.address2?', '+value.property.address2:'') + ', ' + value.property.suburb + ', ' + value.property.state + ' - ' + value.property.postcode,
                        start: startDateArr[2]+'-'+startDateArr[1]+'-'+startDateArr[0],
                    })
                }); 
                setEvents(events);*/
                setEvents(response.item);
                setShowCalendar(true);
                setBackDropOpen(false);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(response.message);
                setBackDropOpen(false);
                /* if (response?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setBackDropOpen(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (checkPermission('DASHBOARD', 'view')) fetchData();
        fetchServiceData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    }; */

    const currMonth = new Date().toLocaleString([], {
        month: 'long',
    });

    const currYear = new Date().getFullYear();

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropopen}
            >
                {/*<CircularProgress color="inherit" />*/}
                <div className="circle bg-theme">
                    Data is loading.<br />
                    It will take a moment.<br />
                    <div className="loaderDots">Please wait</div>
                </div>
            </Backdrop>
            <SiteHeader menu='dashboard' />
            <div className="row">
                {checkPermission('DASHBOARD', 'all') &&
                    <div className="col-lg-2 d-none d-lg-inline-block sidebar">
                        <nav className="sidebar-menu">
                            <ul>
                                <li>
                                    <Link to="#" onClick={() => { window.scrollTo({ top: analyticsRef.current.offsetTop, behavior: 'smooth', }) }}>Analytics</Link>
                                </li>
                                <li>
                                    <Link to="#" onClick={() => { window.scrollTo({ top: propertyRef.current.offsetTop, behavior: 'smooth', }) }}>Properties</Link>
                                </li>
                                <li>
                                    <Link to="#" onClick={() => { window.scrollTo({ top: calendarRef.current.offsetTop, behavior: 'smooth', }) }}>Service Calendar</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                }
                <div className={(checkPermission('DASHBOARD', 'all') ? "col-md-12 col-lg-10" : "col-12") + " user-panel"}>
                    <div className="d-flex mb-5">
                        <div className="title flex-fill text-start">Dashboard</div>
                        <div className="text-theme2 flex-fill text-end m-3">Logged in as {_user_name}</div>
                    </div>
                    <Collapse in={alertOpen}>
                        <Alert variant="filled" severity={alertStatus}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlertOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2, id: "alertmessage" }}
                        >
                            {alertMessage}
                        </Alert>
                    </Collapse>
                    <div className="container">
                        {backdropopen && <Placeholder />}
                        <div className="row" ref={analyticsRef}>
                            {showCard && checkPermission('DASHBOARD', 'all') && (
                                <>
                                    <div className="col-md-3 d-flex mb-5"><CardInfo title="Agents" nactive={state.active_agents} ninactive={state.inactive_agents} icon=<Diversity3Rounded /> link='/users/agents' /></div>
                                    <div className="col-md-3 d-flex mb-5"><CardInfo title="Land Lords" nactive={state.active_owners} ninactive={state.inactive_owners} icon=<GroupsRounded /> link='/users/land-lords' /></div>
                                    <div className="col-md-3 d-flex mb-5"><CardInfo title="Properties" nactive={state.active_properties} ninactive={state.inactive_properties} npending={state.pending_properties} icon=<MapsHomeWorkRounded /> link='/properties' /></div>
                                    <div className="col-md-3 d-flex mb-5"><CardInfo title="This Month" total={state.this_month_services} subtitle='Services' titlelabel={currMonth + ' ' + currYear} icon=<HandymanRounded /> link='/jobs' /></div>
                                    <div className="col-lg-6 col-md-12 d-flex mb-5" ref={propertyRef}>
                                        <ListBox data={state.job_list} title="Upcoming Safety Check" nodatamsg="There is no upcoming safety check" listtype='jobs' linktext='View All Safety Check' />
                                    </div>
                                    <div className="col-lg-6 col-md-12 d-flex mb-5">
                                        <ListBox data={state.pending_property_list} title="Properties waiting for activate" nodatamsg="There is no pending properties" listtype='properties' linktext='View All Properties Status' />
                                    </div>
                                    {/* <div className="col-md-6 d-flex mt-5">
                            <PieBoxChart className="m-auto" />
                        </div> */}
                                </>
                            )}
                            {showCalendar && checkPermission('DASHBOARD', 'schedule') && (
                                <div className="col-md-12" ref={calendarRef}>
                                    <FullCalendar
                                        plugins={[dayGridPlugin]}
                                        initialView='dayGridMonth'
                                        weekends={true}
                                        events={events}
                                        contentHeight="auto"
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default Dashboard;