import { useState } from "react";
import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";
import { Link } from 'react-router-dom';
import { axiosFunction } from '../common';
import { useForm } from "react-hook-form";
import { Alert, IconButton, Collapse } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { CheckCircleOutline } from '@mui/icons-material';

const ForgotPassward = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loader, setLoader] = useState(false);
    const [alertStatus, setAlertStatus] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [email, setEmail] = useState('');


    const onSubmit = async (data) => {
        try {
            setLoader(true);
            if (data.email) {
                const user = {
                    email: data.email
                };
                const response = await axiosFunction('auth/forgotpassword', 'post', user);
                if (response.status === 'success') {
                    setEmail('');
                    setAlertOpen(true);
                    setAlertStatus('success');
                    setAlertMessage(<div className="container bg-white p-5" style={{ borderRadius: '10px' }}><div className='text-center'>
                        <CheckCircleOutline color='success' sx={{ fontSize: '5em', margin: 'auto' }} />
                    </div><p className="text-start">{response.message}</p><p>Please <Link to="/login" className="text-yellow">click here</Link> to login.</p></div>);
                    setLoader(false);
                } else {
                    setAlertOpen(true);
                    setAlertStatus('error');
                    setAlertMessage(<>{response.message}</>);
                    setLoader(false);
                }
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoader(false);
        }
    };

    return (
        <>
            <SiteHeader menu='forgot' />
            <div className="space-bottom">
                <div className="container">
                    <div className="bg-smoke bg-bottom-right">
                        <div className="row">
                            <div className="col-xl-6 m-auto">
                                <div className="quote-form-box bg-transparent">
                                    <h4 className="form-title text-center">Forgot Password?</h4>
                                    {alertStatus === 'success' ?
                                        alertMessage
                                        : <><Collapse in={alertOpen}>
                                            <Alert variant="filled" severity={alertStatus}
                                                action={
                                                    <IconButton
                                                        aria-label="close"
                                                        color="inherit"
                                                        size="small"
                                                        onClick={() => {
                                                            setAlertOpen(false);
                                                        }}
                                                    >
                                                        <CloseIcon fontSize="inherit" />
                                                    </IconButton>
                                                }
                                                sx={{ mb: 2 }}
                                                className="text-start">
                                                {alertMessage}
                                            </Alert>
                                        </Collapse>
                                            <p className="text-start">Dont't worry! It happens. Please enter the email address or mobile number associated with your account. We will send you the instruction to reset your password.</p>
                                            <form method="POST" className="contact-form ajax-contact" id="frmForgot" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                                                <div className="row">
                                                    <div className="form-group col-md-12">
                                                        <input type="text" maxLength={100} className={"form-control " + (errors.email ? 'is-invalid' : '')} placeholder="Email Address or Mobile Number" autoComplete="off" {...register("email", { required: "Email Address or Mobile number is required" })} value={email} onChange={(e) => setEmail(e.target.value)} />
                                                        <label className="w-full error text-red text-sm text-start">{errors.email?.message}</label>
                                                    </div>
                                                    <div className="col-12 col-md-6 mb-3">
                                                        <Link to="/login"><button type="button" className="themeholy-btn style4 btn-fw">Back to login</button></Link>
                                                    </div>
                                                    <div className="col-12 col-md-6">
                                                        <button type="submit" className="themeholy-btn btn-fw" disabled={(loader) ? 'disabled' : ''}>{(loader) ? 'Submitting...' : 'Submit'}</button>
                                                    </div>
                                                </div>
                                                <p className="form-messages mb-0 mt-3"></p>
                                            </form>
                                        </>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default ForgotPassward;