import { useState, createContext } from "react";
import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";
import { Routes, Route, Link, useLocation } from "react-router-dom";
import { Backdrop } from "@mui/material";
//, CircularProgress
import Profile from "../components/My-Account/Profile";
import Masters from "../components/My-Account/Masters"
import PostCode from "../components/My-Account/PostCode";
import EmailTemplate from "../components/My-Account/EmailTemplate";
import ChangePassword from "../components/My-Account/ChangePassword";

export const AccountContext = createContext(null);

//import { apiBaseUrl, webBaseUrl } from '../common';

const MyAccount = () => {
    //const navigate = useNavigate(null);
    const _role = localStorage.getItem("_role");
    const comp = useLocation();
    let compseg = comp.pathname.split('/')[2];
    const compTitle = compseg ? compseg.replace('-', ' ') : 'Profile';
    const [backdropopen, setBackDropOpen] = useState(false);


    /* useEffect(() => {
        // eslint-disable-next-line   
        //const _token = localStorage.getItem("_token");       

        //if (!_token) navigate('/login');
    }, []); */

    const handleClose = (event, reason) => {
        // if (reason && reason === "backdropClick") return false;

        setBackDropOpen(false);
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropopen}
            >
                {/*<CircularProgress color="inherit" />*/}
                <div className="circle bg-theme">
                    Data is loading.<br />
                    It will take a moment.<br />
                    <div className="loaderDots">Please wait</div>
                </div>
            </Backdrop>
            <SiteHeader menu='my-account' />
            <div className="container-fluid" >
                <div className="row" >
                    <div className="col-lg-2 d-none d-lg-inline-block sidebar">
                        <nav className="sidebar-menu">
                            <ul>
                                <li>
                                    <Link to="/my-account/profile" className={(!compseg || compseg === 'profile') ? 'active' : ''}>Profile</Link>
                                </li>
                                <li>
                                    <Link to="/my-account/change-password" className={(compseg === 'change-password') ? 'active' : ''}>Change Password</Link>
                                </li>
                                {(_role === 'SYS' || _role === 'ADMSTF') && (
                                    <>
                                        {/* <li>
                                            <Link to="/my-account/user-types" className={compseg === 'user-types' ? 'active' : ''}>User Types</Link>
                                        </li> */}
                                        <li>
                                            <Link to="/my-account/prefix" className={compseg === 'prefix' ? 'active' : ''}>Prefix</Link>
                                        </li>
                                        <li>
                                            <Link to="/my-account/postcode" className={compseg === 'postcode' ? 'active' : ''}>Postcode</Link>
                                        </li>
                                        <li>
                                            <Link to="/my-account/job-status" className={compseg === 'job-status' ? 'active' : ''}>Job Status</Link>
                                        </li>
                                        <li>
                                            <Link to="/my-account/email-template" className={compseg === 'email-template' ? 'active' : ''}>Email Templates</Link>
                                        </li>
                                    </>
                                )}
                            </ul>
                        </nav>
                    </div>
                    <div className="col-md-12 col-lg-10 user-panel">
                        <div className="title mb-4">My Account<i className="fa fa-chevron-right"></i><span>{compTitle}</span>
                        </div>
                        <AccountContext.Provider value={{ backdropopen, setBackDropOpen, handleClose }}>
                            <Routes>
                                <Route basename='/my-account'>
                                    <Route path='/' element={<Profile />} />
                                    <Route path='/profile' element={<Profile />} />
                                    <Route path='/change-password' element={<ChangePassword />} />
                                    <Route path="/user-types" element={<Masters type="TYPE" />} />
                                    <Route path="/prefix" element={<Masters type="PREFIX" />} />
                                    <Route path="/job-status" element={<Masters type="JBSTS" />} />
                                    <Route path="/postcode" element={<PostCode />} />
                                    <Route path="/email-template" element={<EmailTemplate />} />
                                </Route>
                            </Routes>
                        </AccountContext.Provider>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default MyAccount;