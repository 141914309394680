import { useState, useEffect, forwardRef, createContext, useContext } from "react";
import { useNavigate } from "react-router-dom"; //, Link
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Alert, IconButton, Collapse, Button, Chip, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DataTableList from '../DataTableList';
import { axiosFunction, checkPermission } from '../../common';
import { JobContext } from "../../pages/Jobs";
import JobForm from "./JobForm";
import ServiceReport from "../Modal/ServiceReport";
import InvoiceDialog from "../Modal/InvoiceDialog";
import Checklist from "./Checklist";
import dayjs from 'dayjs';
import { Base64 } from 'js-base64';

export const FormContext = createContext(null);

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ManageJobs = () => {
    const navigate = useNavigate(null);
    const { setBackDropOpen, serviceUsersOptions, jobStatusOptions } = useContext(JobContext);

    //const { setBackDropOpen } = useContext(AccountContext);
    const [loader, setLoader] = useState(true);
    const [loaderText, setLoaderText] = useState(false);
    const [DTColumns, setDTColumns] = useState([]);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [invoiceOpen, setInvoiceOpen] = useState(false);
    const [checkListOpen, setCheckListOpen] = useState(false);
    const [jobData, setJobData] = useState({});
    const [invoiceDialogOpen, setInvoiceDialogOpen] = useState(false);
    const [invoiceData, setInvoiceData] = useState({});

    const [confirmopen, setConfirmOpen] = useState(false);
    const [delData, setDelData] = useState({});

    const [serviceId, setServiceId] = useState('');
    const [subServiceId, setSubServiceId] = useState('');

    const [serviceReportOpen, setServiceReportDialogOpen] = useState(false);

    const [formField, setFormField] = useState({
        'property_id': '',
        'job_status': '',
        'product_code': '',
        'technician_id': '',
        // 'service_date': '',
        'job_date': '',
        'job_time': '',
        'service_type': '',
        'action': '',
    });
    //const [rowKeyVal, setRowKeyVal] = useState(0);
    const [alertMessage, setAlertMessage] = useState('');
    const [mailOpen, setMailOpen] = useState(false);

    const fetchData = async (property_id) => {
        try {
            setBackDropOpen(true);
            var params = {};
            if (property_id) params.property_id = property_id;
            const subserviceData = await axiosFunction('subservices', 'get', params);

            if (subserviceData.status === 'success') {
                setDTColumns(subserviceData.data);
                setBackDropOpen(false);
                setLoader(false);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(subserviceData.message);
                handleAutoClose();
                setBackDropOpen(false);
                setLoader(false);
                /* if (subserviceData?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            handleAutoClose();
            setBackDropOpen(false);
            setLoader(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    }

    useEffect(() => {

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const actionButtons = (params) => {
        return (
            <>
                {checkPermission('JOB', 'edit') && <Tooltip title="Edit Job" placement="top" arrow ><Button type="button" onClick={(e) => handleEdit(params.api.getRowIndexRelativeToVisibleRows(params.row.id), params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button></Tooltip>
                }
                {checkPermission('JOB', 'delete') &&
                    <Tooltip title="Delete Job" placement="top" arrow ><Button type="button" onClick={(e) => deleteRecord(params.row.id)} style={{ minWidth: '24px', color: 'red' }}><i className="fa fa-trash"></i></Button></Tooltip>
                }

                {(/Assigned/.test(params.row.job_status) && checkPermission('JOB', 'checklist') && params.row.technician) && <Tooltip title="Update Checklist" placement="top" arrow ><Button type="button" onClick={(e) => handleChecklist(params.row)} style={{ minWidth: '24px', color: 'green' }}><i className="fa fa-th-list"></i></Button>
                </Tooltip>
                }
                {/Completed|Closed|Lead/.test(params.row.job_status) && checkPermission('JOB', 'report') && <Tooltip title="View Service Report" placement="top" arrow ><Button type="button" style={{ minWidth: '24px', color: 'purple' }} onClick={(e) => openServiceReport(params.row)}><i className="fa fa-eye"></i></Button></Tooltip>}
                {(/Approved|Closed|Lead/.test(params.row.job_status)) && checkPermission('JOB', 'viewinvoice') && params.row.service.invoice && params.row.service.invoice.id && <Tooltip title="View Invoice" placement="top" arrow ><Button type="button" onClick={(e) => handleInvoice(params.row)} style={{ minWidth: '24px', color: 'green' }}><i className="fa fa-file-invoice"></i></Button></Tooltip>}
            </>);

    }

    const handleStatusColor = (status) => {
        var color = 'info';
        if (/Unassigned/.test(status)) {
            color = 'default';
        } else if (/Assigned/.test(status)) {
            color = 'primary';
        } else if (/Approved/.test(status)) {
            color = 'success';
        } else if (/Pending Approval/.test(status)) {
            color = 'secondary';
        } else if (/Cancelled/.test(status)) {
            color = 'error';
        } else if (/Reschedule/.test(status)) {
            color = 'warning';
        }
        return color;
    }

    const showText = (params) => {
        let stype = params.row.service_type;

        if (params.row.service.product.code === 'PROD3LMSM' || params.row.service.product.code === 'PROD4LMSM') {
            stype = stype === 'ES' ? 'Electrical Safety Check Only' : 'Smoke Alarm Safety Check Only';
        } else {
            stype = stype === 'ES' ? 'Electrical & Smoke Alarm Safety Check Only' : 'Gas Safety Check Only';;
        }

        return (<>S: {params.row.service.product.description} <br /> T: {stype} <br />Inv #: {params.row.service.invoice && params.row.service.invoice.invoice_number}</>);
    }

    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, resizable: true, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'id', headerName: 'Job ID', width: 80, resizable: true, renderCell: (params) => params.row.service.id, valueGetter: (params) => params.row.service.id },
        //{ field: 'service_date', headerName: 'Service Date', width: 100 },
        { field: 'job_date', headerName: 'Job Date', width: 150, resizable: true, renderCell: (params) => (<>D: {params.row.job_date} <br /> T: {params.row.job_time}</>), valueGetter: (params) => params.row.job_date + ' ' + params.row.job_time },
        {
            field: 'property_owner', headerName: 'Land Lord', width: 250, resizable: true, renderCell: (params) => (<>N: {params.row.service.property.propertyowner.prefix ? params.row.service.property.propertyowner.prefix + '. ' : ''} {params.row.service.property.propertyowner.firstname} {params.row.service.property.propertyowner.lastname}<br />E: {params.row.service.property.propertyowner.individualuser.email}<br />M: {params.row.service.property.propertyowner.individualuser.mobile}</>), valueGetter: (params) => (params.row.service.property.propertyowner.prefix ? params.row.service.property.propertyowner.prefix + '. ' : '') + ' ' + params.row.service.property.propertyowner.firstname + ' ' + params.row.service.property.propertyowner.lastname + ' ' + params.row.service.property.propertyowner.individualuser.email + ' ' + params.row.service.property.propertyowner.individualuser.mobile
        },
        { field: 'property_id', headerName: 'Property', width: 200, hideable: false, resizable: true, renderCell: (params) => (params.row.service.property.address1 + (params.row.service.property.address2 ? ', ' + params.row.service.property.address2 : '') + ', ' + params.row.service.property.suburb + ', ' + params.row.service.property.state + ' - ' + params.row.service.property.postcode), valueGetter: (params) => params.row.service.property.address1 + (params.row.service.property.address2 ? ', ' + params.row.service.property.address2 : '') + ', ' + params.row.service.property.suburb + ', ' + params.row.service.property.state + ' - ' + params.row.service.property.postcode },
        { field: 'product_code', headerName: 'Service Type', width: 280, resizable: true, renderCell: (params) => showText(params), valueGetter: (params) => showText(params) },
        { field: 'technician_id', headerName: 'Technician', width: 125, resizable: true, renderCell: (params) => (params.row.technician && ((params.row.technician.lastname ? params.row.technician.lastname : '') + ' ' + (params.row.technician.firstname ? params.row.technician.firstname : ''))), valueGetter: (params) => (params.row.technician && ((params.row.technician.lastname ? params.row.technician.lastname : '') + ' ' + (params.row.technician.firstname ? params.row.technician.firstname : ''))) },
        {
            field: 'job_status', headerName: 'Status', width: 200, resizable: true, renderCell: (params) => (params.row.job_status ? <Chip sx={{
                height: 'auto',
                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                },
            }} label={params.row.job_status} color={handleStatusColor(params.row.job_status)} size="small" /> : ''), valueGetter: (params) => params.row.job_status ? params.row.job_status : ''
        },
        { field: 'actions', headerName: 'Actions', width: 150, sortable: false, hideable: false, renderCell: (params) => actionButtons(params) },
    ];

    const handleEdit = (key, data) => {
        setLoaderText(false);

        setServiceId(data.service_id);
        setSubServiceId(data.id);


        //setRowKeyVal(key);

        //const service_date = data.service_date.split('/');
        const job_date = data.job_date.split('/');

        setFormField({
            'property_id': data.property_id,
            'job_status': data.job_status ? data.job_status : '',
            'service_type': data.service_type,
            'technician_id': data.technician_id ? data.technician_id : '',
            'technician_name': (data.technician && (data.technician.lastname ? data.technician.lastname : '') + ' ' + (data.technician.firstname ? data.technician.firstname : '')),
            // 'service_date': service_date[2] + '/' + service_date[1] + '/' + service_date[0],
            'job_date': job_date[2] + '/' + job_date[1] + '/' + job_date[0],
            'job_time': data.job_time,
            'action': 'edit',
        });
        setDialogOpen(true);

        //setTimeout(() => {document.getElementById('service_date').focus()}, 100);
    };

    /*  const handleAdd = (data) => {
         setLoaderText(false);
         setServiceId('');
         setSubServiceId('');
         setInvoiceData({})
         setFormField({
             'property_id': data.service.property_id,
             'job_status': 'Assigned - Pending Field',
             'service_type': data.service_type,
             'technician_id': '',
             'technician_name': '',
             // 'service_date': '',
             'job_date': '',
             'job_time': '',
             'action': 'add',
         });
         setDialogOpen(true);
         //setTimeout(() => {document.getElementById('service_date').focus()}, 100);
     }; */

    const deleteRecord = (id) => {
        var data = {
            "id": id,
            "_method": 'DELETE'
        }
        setDelData(data);
        setConfirmOpen(true);
    }

    const handleDelete = async () => {
        try {
            const delResponse = await axiosFunction('subservices/' + delData.id, 'post', delData);
            setAlertOpen(true);
            if (delResponse.status === 'success') {
                setAlertStatus('success');
                setAlertMessage(delResponse.message);
                handleAutoClose();
                fetchData();
                setLoaderText(false);
                setConfirmOpen(false);
                setDelData({});
            } else {
                setAlertStatus('error');
                setAlertMessage(delResponse.message);
                setLoaderText(false);
                setConfirmOpen(false);
                setDelData({});
                /* if (delResponse?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoaderText(false);
            setConfirmOpen(false);
            setDelData({});
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    };

    const openServiceReport = (data) => {
        setJobData(data);
        setServiceReportDialogOpen(true);
    }


    const handleServiceReportDialogClose = () => {
        setServiceReportDialogOpen(false);
    }

    const handleInvoice = (data) => {
        let invoiceData = { ...data.service.invoice, 'services': { 'service_date': data.job_date, 'property': data.service.property } }
        setInvoiceData(invoiceData);
        setInvoiceDialogOpen(true);
    }


    const handleInvoiceDialogClose = () => {
        setInvoiceData({});
        setInvoiceDialogOpen(false);
    }


    const handleChecklist = (data) => {
        setServiceId(data.service.id);
        setSubServiceId(data.id);
        setJobData(data);
        setCheckListOpen(true);
    }

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleDialogClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setDialogOpen(false);
        setServiceId('');
        setSubServiceId('');
        setFormField({
            'property_id': '',
            'job_status': '',
            'service_type': '',
            'technician_id': '',
            'technician_name': '',
            // 'service_date': '',
            'job_date': '',
            'job_time': '',
            'action': '',
        });
    };

    const handleInvoiceClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setInvoiceOpen(false);
        setServiceId('');
        setSubServiceId('');
        setFormField({
            'invoice_id': '',
            'invoice_date': '',
            'description': '',
            'amount': '',
            'received': '',
            'balance': '',
            'paid_status': '',
            'action': '',
        });
    };

    const handleChecklistClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;
        setCheckListOpen(false);
        setServiceId('');
        setSubServiceId('');
        setJobData({});
    };

    const handleConfirmClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setConfirmOpen(false);
            setDelData({});
        }
    };

    const handleMailClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setMailOpen(false);
        }
    };

    const onSubmit = async (data) => {
        try {
            setLoaderText(true);
            //console.log(serviceId);
            //data.service_date = dayjs(data.service_date).format('DD-MM-YYYY');
            data.job_date = dayjs(data.job_date).format('DD-MM-YYYY');
            // console.log(data);
            var saveResponse = null;
            if (formField.action === 'edit') {
                data._method = 'PUT';
                saveResponse = await axiosFunction('subservices/' + subServiceId, 'post', data);
            } else if (formField.action === 'add') {
                data.job_status = formField.job_status;
                data.property_id = formField.property_id;
                saveResponse = await axiosFunction('subservices', 'post', data);
            }
            setAlertOpen(true);
            if (saveResponse.status === 'success') {
                if (saveResponse.item.invoice_id) {
                    window.open('/generateinvoice/' + Base64.encode(saveResponse.item.invoice_id, true), '_blank')
                }
                setAlertStatus('success');
                handleDialogClose();
                fetchData();
                setAlertMessage(saveResponse.message);
                handleAutoClose();
                setLoaderText(false);
                handleDialogClose();
            } else {
                setAlertStatus('error');
                setAlertMessage(saveResponse.message);
                setLoaderText(false);
                handleDialogClose();
                /* if (saveResponse?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoaderText(false);
            handleDialogClose();
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }

    };

    /* const onInvoiceSubmit = async (data) => {
        try{
        setLoaderText(true);
    
        //console.log(serviceId);
        data.service_id = serviceId;
        data.invoice_date = dayjs(data.invoice_date).format('DD-MM-YYYY');
    
        // console.log(data);
    
        let saveResponse = null;
    
        if (formField.action === 'edit') {
            data._method = 'PUT';
            saveResponse = await axiosFunction('invoices/' + formField.invoice_id, 'post', data);
        } else if (formField.action === 'add') {
            saveResponse = await axiosFunction('invoices', 'post' data);
        }
    
    
        setAlertOpen(true);
        if (saveResponse.status === 'success') {
            setAlertStatus('success');
            handleInvoiceClose();
            fetchData();
            setAlertMessage(saveResponse.message);
            handleAutoClose();
            setLoaderText(false);
            handleInvoiceClose();
        } else {
            setAlertStatus('error');
            setAlertMessage(saveResponse.message);
            setLoaderText(false);
            handleInvoiceClose();
            //if (saveResponse?.response?.status === 401) {
               // localStorage.clear();
               // navigate('/login');
            //}
        }
            } catch (e) {
             setAlertStatus('error');
            setAlertMessage(e.message);
            setLoaderText(false);
            handleInvoiceClose();
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    
    } */

    return (
        <>
            <Collapse in={alertOpen}>
                <Alert variant="filled" severity={alertStatus}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {alertMessage}
                </Alert>
            </Collapse>

            <Dialog
                open={confirmopen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleConfirmClose}
            >
                <DialogTitle>Are you sure you want to delete this Job?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        If yes, job will be deleted from the records and no longer be exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                    <Button className="themeholy-btn btn-primary" onClick={() => handleDelete({ delData })}>Yes</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={mailOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleMailClose}
            >
                <DialogTitle>Save Checklist</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Save and Mail has been sent successfully.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-primary" onClick={(e) => handleMailClose(e, 'ok')}>OK</Button>
                </DialogActions>
            </Dialog>


            <FormContext.Provider value={{
                handleDialogClose, onSubmit, loaderText, formField, setFormField, alertStatus,
                alertMessage,
                alertOpen,
                setAlertOpen,
                setAlertStatus,
                setAlertMessage,
                dialogOpen,
                serviceUsersOptions,
                jobStatusOptions,
                handleInvoiceClose,
                invoiceOpen,
                serviceId,
                subServiceId,
                // onInvoiceSubmit,
                checkListOpen,
                handleChecklistClose,
                jobData,
                fetchData
            }}>
                {dialogOpen && <JobForm />}
                {/* invoiceOpen && <InvoiceForm /> */}
                {checkListOpen && <Checklist />}
            </FormContext.Provider>
            {/*(_role === 'SYS' || _role === 'ADMSTF') && (
            <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Job</Button>
            </div>
            )*/}

            <DataTableList columns={columns} rows={rows} loading={loader} columnVisibilityModel={{ actions: checkPermission('JOB', 'report') }} />


            {serviceReportOpen && <ServiceReport serviceReportOpen={true} handleServiceReportDialogClose={handleServiceReportDialogClose} jobData={jobData} />}
            {invoiceDialogOpen && <InvoiceDialog invoiceDialogOpen={invoiceDialogOpen} handleInvoiceDialogClose={handleInvoiceDialogClose} invoiceData={invoiceData} />}

        </>
    );
}

export default ManageJobs;