import { useState, createContext } from "react";
import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";
import { Backdrop, Button, Drawer } from "@mui/material";
//import { ServiceUsersData } from '../services/ServiceUsersData';
//import { JobStatusData } from '../services/JobStatusData';
import { Invoice } from "../services/PropertyData";
import InvoiceForm from "../components/Jobs/InvoiceForm";

export const InvoiceContext = createContext(null);

const Invoices = () => {
    const [backdropopen, setBackDropOpen] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [formField, setFormField] = useState({});
    //const [serviceUsersOptions, setServiceUsersOptions] = useState(null);
    //const [jobStatusOptions, setJobStatusOptions] = useState(null);

    /* const loadData = () => {   
        ServiceUsersData.fetchData().then(serviceUsersDataList => {
            setServiceUsersOptions(serviceUsersDataList);

            JobStatusData.fetchData().then(jobStatusDataList => {
                setJobStatusOptions(jobStatusDataList);
                           
            });
        });    
    }
 */
    /* useEffect(() => {
        //loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); */

    const handleAdd = () => {
        setFormField({ action: 'add' });
        setDrawerOpen(true);
    }

    const handleDialogClose = () => {
        setDrawerOpen(false);
        setFormField({});
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropopen}
            >
                {/*<CircularProgress color="inherit" />*/}
                <div className="circle bg-theme">
                    Data is loading.<br />
                    It will take a moment.<br />
                    <div className="loaderDots">Please wait</div>
                </div>
            </Backdrop>
            <SiteHeader menu='invoices' />
            <div className="user-panel">
                <div className="title text-start">Invoices</div>
                <div className="container-fluid">
                    <InvoiceContext.Provider value={
                        {
                            setBackDropOpen
                            //serviceUsersOptions,
                            //jobStatusOptions
                        }
                    }>
                        <div className="row">
                            <div className="col-md-12 text-end">
                                <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New Invoice</Button>
                            </div>
                            <div className="col-md-12">
                                <Invoice />
                            </div>
                        </div>
                    </InvoiceContext.Provider>
                </div>
            </div>
            {drawerOpen && <Drawer anchor="right" open={drawerOpen} sx={{ zIndex: 1400 }} PaperProps={{ className: 'container p-0' }}>
                <InvoiceForm action={formField.action} handleDialogClose={handleDialogClose} />
            </Drawer>}
            <Footer />
        </>
    );
}

export default Invoices;