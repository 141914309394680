import { useContext } from "react";
import { Link } from "react-router-dom";
import { FormContext } from "./ManageProperties";
import { Textsms } from '@mui/icons-material';
import { checkPermission } from "../../common";

const OwnerInfo = () => {
    let { propertyId, viewField, handleSMS } = useContext(FormContext);
    //console.log(viewField);
    return (
        <div id="viewForm" className="text-start">
            <div className="row">
                <label className="col-5 fw-bold">Land Lord Name</label>
                <div className="col-7">: {viewField.owner_name}</div>
            </div>
            <div className="row">
                <label className="col-5 fw-bold">Email</label>
                <div className="col-7">: {viewField.owner_email}</div>
            </div>
            <div className="row">
                <label className="col-5 fw-bold">Mobile</label>
                <div className="col-7">: {viewField.owner_mobile} {viewField.owner_mobile && checkPermission('PROPERTY', 'send') && <Link to='#' onClick={() => handleSMS(viewField, 'LDLORD', true)}><Textsms color='error' /></Link>}</div>
            </div>
            <div className="row">
                <label className="col-5 fw-bold">Address</label>
                <div className="col-7">: <span>{viewField.owner_address1} {viewField.owner_address2} {viewField.owner_suburb} {viewField.owner_state} {viewField.owner_state ? '-' : ''} {viewField.owner_postcode}</span></div>
            </div>
            {propertyId && (
                <><div className="row">
                    <label className="col-5 fw-bold">Agent Name</label>
                    <div className="col-7">: {viewField.agent_name}</div>
                </div>
                    <div className="row">
                        <label className="col-5 fw-bold">Agency Name</label>
                        <div className="col-7">: {viewField.agency_name}</div>
                    </div>
                    <div className="row">
                        <label className="col-5 fw-bold">Agent Mobile</label>
                        <div className="col-7">: {viewField.agent_mobile} {viewField.agent_mobile && checkPermission('PROPERTY', 'send') && <Link to='#' onClick={() => handleSMS(viewField, 'AGENT', true)}><Textsms color='error' /></Link>}</div>
                    </div></>
            )}
        </div>
    );
}

export default OwnerInfo;