import { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { Box, Alert, TextField, IconButton, Button, AppBar, Toolbar, Typography, FormControl, InputLabel, Select, MenuItem, Collapse } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { FormContext } from "./PostCode";

export const PostCodeForm = () => {

    const { handleDialogClose, onSubmit, loaderText, formField, setFormField, alertStatus,
        alertMessage,
        alertOpen,
        setAlertOpen } = useContext(FormContext);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const actionFun = formField.action;

    useEffect(() => {

        setFormField({
            'code': formField.code,
            'suburb': formField.suburb,
            'state_shortname': formField.state_shortname,
            //'state_fullname': formField.state_fullname,
            'status': formField.status,
            'action': formField.action,
            'changedFlag': 'N',
        });

    }, [formField, setFormField]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <Box component="form" id="postcodeform" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <AppBar sx={{ position: 'fixed' }} className="app-header-top">
                <Toolbar className="dflex">
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                        className="me-auto"
                    >
                        <CloseIcon />
                        <Typography sx={{ p: 2, flex: 1, width: 'auto' }} variant="h6" component="div" className="d-none d-lg-block">
                            {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Postcode
                        </Typography>
                    </IconButton>

                    <Button type="button" className="themeholy-btn btn-secondary" style={{ marginRight: '10px' }} onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    <Button type="submit" autoFocus className="themeholy-btn btn-normal" disabled={loaderText}>
                        {(loaderText) ? 'Saving...' : 'Save'}
                    </Button>
                </Toolbar>
            </AppBar>
            <div className="container" style={{ paddingTop: '90px' }}>
                <Collapse in={alertOpen}>
                    <Alert variant="filled" severity={alertStatus}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {alertMessage}
                    </Alert>
                </Collapse>

                {formField.action && (
                    <div className="row">
                        <div className="col-md-12 d-block d-lg-none">
                            <Typography sx={{ marginBottom: '15px' }} variant="h6" component="div">
                                {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Postcode
                            </Typography>
                        </div>
                        <div className="col-md-12 col-lg-6 m-auto">

                            <TextField
                                fullWidth
                                id="suburb" name="suburb"
                                variant="outlined" error={errors.suburb ? true : false}
                                label="Suburb"
                                className="col-md-12"
                                helperText={errors.suburb?.message}
                                inputProps={{ maxLength: 40, value: formField.suburb }}

                                {...register("suburb", { required: "Suburb is required" })}
                                onChange={(e) => setFormField((prev) => ({ ...prev, suburb: e.target.value, changedFlag: 'Y' }))}
                            />

                            <TextField
                                fullWidth
                                id="code" name="code" variant="outlined" error={errors.code ? true : false} label="Postcode"
                                helperText={errors.code?.message}
                                className="col-md-12"

                                inputProps={{ maxLength: 10, value: formField.code }}

                                {...register("code", { required: "Code is required" })}

                                onChange={(e) => setFormField((prev) => ({ ...prev, code: e.target.value }))}

                            />

                            <TextField
                                fullWidth
                                id="state_shortname" name="state_shortname"
                                variant="outlined" error={errors.state_shortname ? true : false}
                                label="State Shortname"
                                className="col-md-12"
                                helperText={errors.state_shortname?.message}
                                inputProps={{ maxLength: 10, value: formField.state_shortname }}

                                {...register("state_shortname", { required: "State Shortname is required" })}
                                onChange={(e) => setFormField((prev) => ({ ...prev, state_shortname: e.target.value, changedFlag: 'Y' }))}
                            />

                            {actionFun === 'edit' && (
                                <FormControl fullWidth>
                                    <InputLabel id="status-select-label"> Select Status </InputLabel>
                                    <Select
                                        labelId="status-select-label"
                                        id="status"
                                        name="status"
                                        error={errors.status ? true : false}
                                        value={formField.status}
                                        label="Select Status"
                                        {...register("status", { required: "Status is required" })}
                                        onChange={(e) => setFormField((prev) => ({ ...prev, status: e.target.value }))}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value='Y'>Active</MenuItem>
                                        <MenuItem value='N'>Inactive</MenuItem>
                                    </Select>
                                </FormControl>
                            )}
                            <div className="row">
                                <div className="col-md-12 col-lg-6">
                                    <Button type="button" className="themeholy-btn btn-secondary mb-3 w-100" onClick={handleDialogClose}>Cancel</Button>
                                </div>
                                <div className="col-md-12 col-lg-6">
                                    <Button type="submit" autoFocus className="themeholy-btn btn-primary w-100" disabled={loaderText}>{(loaderText) ? 'Saving...' : 'Save'}</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </Box>
    );


}