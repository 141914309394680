import React from 'react';
import ReactDOM from 'react-dom/client';

import "./assets/css/app.min.css";
import "./assets/css/fontawesome.min.css";
//import './index.css';
import './style.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <App />

);

window.addEventListener("contextmenu", (function (e) {
    e.preventDefault()
}), !1);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
