import React, { useState, useEffect } from 'react';
import Main from './pages/Main';
import Logo from './assets/img/favlogo.png';
import './App.css';

function App() {
  const [splash, setSplash] = useState(true);

  useEffect(() => {
    const splashScreen = async () => {
      try {
        if (splash) setTimeout(() => { setSplash(false) }, 500);
      } catch (e) {
        setSplash(true);
      }
    };
    splashScreen();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      {splash ? (
        <header className="App-header">
          <div className='bg-logo'><img src={Logo} className="App-logo" alt="logo" /></div>
        </header>
      ) : (
        <Main />
      )}
    </div>
  );
}

export default App;
