import { useEffect, useContext } from 'react';
import { useForm, Controller } from "react-hook-form";
import dayjs from 'dayjs';
//import 'dayjs/locale/en-au';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, TextField, Autocomplete, Typography } from '@mui/material';
//import Checkbox from '@mui/material/Checkbox';
import { FormContext } from "./ManageJobs";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@mui/icons-material/CheckBox';
//import * as moment from 'moment'

/* const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
 */
const JobForm = () => {

    const _role = localStorage.getItem("_role");

    const { loaderText, dialogOpen, handleDialogClose, serviceUsersOptions, jobStatusOptions, formField, setFormField, onSubmit } = useContext(FormContext);
    //const [startDate, setStartDate] = useState(dayjs(new Date()));
    //const [endDate, setEndDate] = useState(dayjs(new Date()));

    //const electriciansData = serviceUsersOptions ? serviceUsersOptions.filter(a => a.code === "E-TRADIE") : [];
    //const plumbersData = serviceUsersOptions ? serviceUsersOptions.filter(a => a.code === "P-TRADIE") : [];

    //const [technicianInput, setTechnicianInput] = useState(formField.technician_name);

    const actionFun = formField.action;

    const service_type = (formField.service_type === 'ES' || formField.service_type === 'SA') ? 'E-TRADIE' : 'P-TRADIE';

    const { reset, handleSubmit, register, formState: { errors }, control } = useForm();

    useEffect(() => {

        //if(formField.property_id){        
        reset(formField);
        //} 

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formField]);



    const getSelectedItem = (label) => {
        // eslint-disable-next-line
        const item = jobStatusOptions.find((opt) => {
            if (opt && opt.label === label) {
                return opt;
            };

        })

        return item || null;
    }

    const getSelectedTechnician = (id, code) => {
        // eslint-disable-next-line
        const item = serviceUsersOptions.filter(a => a.code === code).find((opt) => {

            if (opt && opt.id === id) {

                return opt;
            };

        });


        return item || null;
    }

    const filterStatus = (a) => {
        if (_role === 'SYS' || _role === 'ADMIN') {
            if (/Approved/.test(formField.job_status) && /Approved|Closed|Lead/.test(a.label)) {
                return a
            } else if (/Unassigned|Cancelled|Reschedule/.test(formField.job_status) && /Unassigned|Assigned|Cancelled|Reschedule/.test(a.label)) {
                return a
            } else if (/Assigned/.test(formField.job_status) && /Unassigned|Assigned|Completed - Pending/.test(a.label)) {
                return a
            } else if (/Completed - Pending/.test(formField.job_status) && /Assigned|Completed/.test(a.label)) {
                return a
            } else if (/Closed|Lead/.test(formField.job_status) && /Closed|Lead/.test(a.label)) {
                return a
            }
        } else {
            if (/Unassigned/.test(formField.job_status) && /Assigned/.test(a.label)) {
                return a
            } else if (/Assigned|Completed - Pending/.test(formField.job_status) && /Completed - Pending|Assigned/.test(a.label)) {
                return a
            }
        }
    }

    return (
        <Dialog
            fullWidth
            open={dialogOpen}
            onClose={handleDialogClose}
            scroll='body'
        >
            <DialogTitle id="scroll-dialog-title">{actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} Job</DialogTitle>
            <Box component="form" id="job-form" name="job-form" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>

                <DialogContent dividers={false}>
                    <Controller
                        control={control}
                        name="job_date"
                        defaultValue={dayjs(formField.job_date)}
                        rules={{ required: 'Job Date is required' }}
                        render={({ field, }) => {
                            return (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disablePast
                                        label="Job Date"
                                        format="DD/MM/YYYY"
                                        value={dayjs(formField?.job_date)}
                                        defaultValue={dayjs(formField?.job_date)}
                                        onChange={(newValue) => { field.onChange(newValue); setFormField((prev) => ({ ...prev, job_date: newValue })) }}
                                        sx={{ marginRight: '15px' }}
                                        slotProps={{
                                            textField: {
                                                error: errors.job_date ? true : false,
                                                helperText: errors.job_date?.message,

                                            },
                                        }}
                                    />
                                </LocalizationProvider>);
                        }}
                    />
                    <TextField
                        id="job_time" name="job_time" variant="outlined" label="Job Time"
                        // helperText='Example Format: 9:00 AM - 2:00 PM'

                        inputProps={{ maxLength: 100, value: formField.job_time ? formField.job_time : '' }}
                        {...register("job_time", { required: /Assigned|Completed|Closed|Lead/.test(formField.job_status) ? "Job Time is required" : false, pattern: { value: /[0-9]{1,2}:[0-9]{2}\s[AM|PM]/, message: "Invalid Time Format. Example Format: 9:00 AM - 2:00 PM" } })}
                        onChange={(e) => setFormField((prev) => ({ ...prev, job_time: e.target.value }))}
                        error={errors.job_time ? true : false}
                        helperText={errors.job_time?.message ? errors.job_time.message : 'Example Format: 9:00 AM - 2:00 PM'}
                    />
                    <Typography
                        sx={{ display: 'block', marginTop: '15px', marginBottom: '15px' }}
                        component="h6"
                        variant="subtitle-1"
                        color="text.primary"
                    >
                        Assign Technician
                    </Typography>

                    <Controller
                        name="technician_id"
                        control={control}
                        rules={{
                            required: /Unassigned|Cancelled|Rescheduled/.test(formField.job_status) ? false : "Technician is required"
                        }}
                        render={({ field, fieldState }) => (
                            <Autocomplete
                                disablePortal
                                readOnly={(_role === 'SYS' || _role === 'MG') ? false : true}
                                id="technician_id"
                                options={serviceUsersOptions.filter(a => a.code === service_type)}
                                getOptionLabel={(option) => option.label}

                                sx={{ width: '100%', marginTop: '20px' }}
                                renderOption={(props, option) => (
                                    <Box component="li" {...props} key={option.id}>
                                        {option.label}
                                    </Box>
                                )}
                                isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                renderInput={(params) => <TextField {...params} label="Select Technician" variant="outlined"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}

                                />}
                                onChange={(_, data) => {
                                    field.onChange(data ? data.label : '')
                                    setFormField((prev) => ({ ...prev, technician_id: data ? data.id : '', technician_name: data ? data.label : '' }))
                                }}

                                value={getSelectedTechnician(formField.technician_id, service_type)}
                            />
                        )}
                    />
                    {actionFun === 'edit' &&
                        <Controller
                            name="job_status"
                            control={control}
                            rules={{
                                required: "Job Status is required"
                            }}
                            render={({ field, fieldState }) => (
                                <Autocomplete
                                    disablePortal
                                    disableClearable
                                    id="job_status"
                                    options={jobStatusOptions.filter(a => filterStatus(a))}
                                    getOptionLabel={(option) => option.label}
                                    sx={{ width: '100%', marginTop: '20px' }}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={option.id}>
                                            {option.label}
                                        </Box>
                                    )}
                                    isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                    renderInput={(params) => <TextField {...params} label="Status" variant="outlined"
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}

                                    />}
                                    onChange={(_, data) => {
                                        field.onChange(data ? data.label : '')
                                        setFormField((prev) => ({ ...prev, job_status: data ? data.label : '' }))
                                    }}

                                    value={getSelectedItem(formField?.job_status)}
                                />
                            )}
                        />
                    }

                </DialogContent>
                <DialogActions className='p-4'>
                    <Button type="button" className="themeholy-btn btn-secondary" onClick={handleDialogClose}>Cancel</Button>
                    <Button type="submit" className="themeholy-btn btn-primary" disabled={loaderText}>{(loaderText) ? 'Submitting...' : 'Submit'}</Button>
                </DialogActions>
            </Box>
        </Dialog>
    );

}

export default JobForm;