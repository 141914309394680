import { useEffect, useContext } from "react";
import { useForm } from "react-hook-form";
import { Box, FormControl, TextField, IconButton, Button, AppBar, Toolbar, Typography, Select, MenuItem, InputLabel, Alert, Collapse } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { FormContext } from "./Masters";

export const MasterForm = (props) => {

    const { handleDialogClose, onSubmit, loaderText, formField, setFormField, alertStatus,
        alertMessage,
        alertOpen,
        setAlertOpen } = useContext(FormContext);

    const { register, handleSubmit, formState: { errors } } = useForm();

    //const codeRef = useRef();

    let mTypeArr = { 'TYPE': 'User Type', 'PREFIX': 'Prefix', 'JBSTS': 'Job Status' }
    const masterFormType = mTypeArr[props.formType];
    const actionFun = formField.action;
    /* const [formField, setFormField] = useState({
        'code': '',
        'description': '',
        'status': 'Y',
        'action': '',
        'changedFlag':'N'
    });  */

    useEffect(() => {

        setFormField({
            'code': formField.code,
            'description': formField.description,
            'status': formField.status,
            'action': formField.action,
            'changedFlag': 'N',
        });
        //return () => {
        //codeRef && codeRef.current?.focus();    
        //}
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);



    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    return (
        <Box component="form" id="masterform" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <AppBar sx={{ position: 'fixed' }} className="app-header-top">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                        className="me-auto"
                    >
                        <CloseIcon />
                        <Typography sx={{ p: 2, flex: 1, width: 'auto' }} variant="h6" component="div" className="d-none d-lg-block">
                            {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} {masterFormType}
                        </Typography>
                    </IconButton>

                    <Button type="button" className="themeholy-btn btn-secondary" style={{ marginRight: '10px' }} onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    <Button type="submit" autoFocus className="themeholy-btn btn-normal" disabled={loaderText}>
                        {(loaderText) ? 'Saving...' : 'Save'}
                    </Button>
                </Toolbar>
            </AppBar>

            <div className="container" style={{ paddingTop: '90px' }}>
                <Collapse in={alertOpen}>
                    <Alert variant="filled" severity={alertStatus}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }
                        sx={{ mb: 2 }}
                    >
                        {alertMessage}
                    </Alert>
                </Collapse>
                <div className="container">
                    {formField.action && (
                        <div className="row">
                            <div className="col-md-12 d-block d-lg-none">
                                <Typography sx={{ marginBottom: '15px' }} variant="h6" component="div">
                                    {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} {masterFormType}
                                </Typography>
                            </div>
                            <div className="col-md-12 col-lg-6 m-auto">

                                <TextField
                                    fullWidth
                                    id="code" name="code" variant="outlined" error={errors.code ? true : false} label="Code"
                                    helperText={errors.code?.message}


                                    inputProps={{ maxLength: 10, value: formField.code, autoFocus: true }}


                                    {...register("code", { required: "Code is required" })}

                                    onChange={(e) => actionFun === 'add' ? setFormField((prev) => ({ ...prev, code: e.target.value.toUpperCase() })) : ''}

                                />

                                <TextField
                                    fullWidth
                                    id="ut_description" name="description"
                                    variant="outlined" error={errors.description ? true : false}
                                    label="Description"
                                    helperText={errors.description?.message}
                                    inputProps={{ maxLength: 40, value: formField.description }}

                                    {...register("description", { required: "Description is required" })}
                                    onChange={(e) => setFormField((prev) => ({ ...prev, description: e.target.value }))}
                                />

                                {actionFun === 'edit' && (
                                    <FormControl fullWidth >
                                        <InputLabel id="status-select-label"> Select Status </InputLabel>
                                        <Select
                                            labelId="status-select-label"
                                            className="text-start"
                                            id="status"
                                            name="status"
                                            error={errors.status ? true : false}
                                            value={formField.status}
                                            label="Select Status"
                                            {...register("status", { required: "Status is required" })}
                                            onChange={(e) => setFormField((prev) => ({ ...prev, status: e.target.value }))}
                                            MenuProps={MenuProps}
                                        >
                                            <MenuItem value='Y'>Active</MenuItem>
                                            <MenuItem value='N'>Inactive</MenuItem>
                                        </Select>
                                    </FormControl>
                                )}
                                <div className="row">
                                    <div className="col-md-12 col-lg-6">
                                        <Button type="button" className="themeholy-btn btn-secondary mb-3 w-100" onClick={handleDialogClose}>Cancel</Button>
                                    </div>
                                    <div className="col-md-12 col-lg-6">
                                        <Button type="submit" autoFocus className="themeholy-btn btn-primary w-100" disabled={loaderText}>{(loaderText) ? 'Saving...' : 'Save'}</Button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    )}
                </div>
            </div>
        </Box>
    );


}