import { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {
    Typography, Table, TableBody, TableRow, TableHead, TableFooter, TableCell, Box
} from '@mui/material';
import dayjs from 'dayjs';
import { axiosFunction } from '../../common';
import jsPDF from 'jspdf';
import Banner from "../../assets/img/Header.png";

const Invoice = ({ upload, invoiceData }) => {
    const _token = localStorage.getItem("_token");
    const invoiceRef = useRef(null);
    const navigate = useNavigate(null);
    const comp = useLocation();
    const invoiceid = comp.pathname.split('/')[2];
    const maxWidth = '900px';

    const [invoiceDetails, setInvoiceDetails] = useState({});

    const doc = new jsPDF('p', 'px', 'a4', true);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize('16px');

    const invoiceFetchData = async (invid) => {
        if (invid) {
            try {
                const invoiceData = await axiosFunction('auth/getinvoice/' + invid, 'get', {});
                if (invoiceData.status === 'success') {
                    setInvoiceDetails(invoiceData.data);
                    if (_token && upload === 'Y') {
                        setTimeout(() => {
                            doc.setProperties({
                                title: "invoice_" + invid + ".pdf"
                            }).html(invoiceRef.current, {
                                autoPaging: 'text',
                                margin: [20, 20, 20, 20],
                                callback: async function (doc) {
                                    doc.setFont('helvetica', 'normal');
                                    var out = doc.output('datauristring');
                                    //doc.output('dataurlnewwindow', { filename: "invoice_" + invid + ".pdf" });
                                    if (out) {
                                        const pdfData = { invoice_id: invid, pdfContent: out }
                                        const response = await axiosFunction('invoices/uploadpdf', 'post', pdfData);

                                        if (response.status !== 'success') {
                                            if (response?.response?.status === 401) {
                                                localStorage.clear();
                                                navigate('/login');
                                            }
                                        }

                                    }
                                },
                                html2canvas: {
                                    allowTaint: true,
                                    letterRendering: true,
                                    logging: false,
                                    scale: 0.45, // Adjust the scale to fit content
                                }
                            });

                        }, 2000);
                    }
                } else {
                    /* if (invoiceData?.response?.status === 401) {
                        localStorage.clear();
                        navigate('/login');
                    } */
                }
            } catch (e) {
                if (e?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } else {
                    console.log(e);
                }
            }

        }
    }

    useEffect(() => {
        if (invoiceData) setInvoiceDetails(invoiceData)
        if (invoiceid) invoiceFetchData(invoiceid);
        // eslint-disable-next-line
    }, [invoiceid])

    return (
        <Box ref={invoiceRef} style={{ backgroundColor: '#FFFFFF', fontSize: '16px', textAlign: 'left', padding: '15px', fontFamily: 'helvetica', width: maxWidth }} className="container" id='pdfcontent'>
            {invoiceDetails.services && <>
                <img alt="banner" src={Banner} width='100%' />
                <div className='container'>
                    <Typography
                        sx={{ display: 'block', margin: '15px 0px' }}
                        component="h3"
                        variant="subtitle-1"
                        className='text-theme'
                    >
                        Invoice
                    </Typography>
                    <div className='row'>
                        <div className='col-7 mb-3'>
                            <div>
                                <strong>Property Address:</strong>
                                <Typography
                                    sx={{ display: 'block' }}
                                    variant="body"
                                >
                                    {invoiceDetails.services.property.address1 + ', '}
                                    {invoiceDetails.services.property.address2 && invoiceDetails.services.property.address2 + ','}<br />
                                    {invoiceDetails.services.property.suburb + ', '}
                                    {invoiceDetails.services.property.state + ' - ' + invoiceDetails.services.property.postcode}

                                </Typography>
                            </div>
                            <div className='mt-3'>
                                <strong>Land Lord:</strong>
                                <Typography
                                    sx={{ display: 'block' }}
                                    variant="body"
                                >
                                    {invoiceDetails.services.property.propertyowner.prefix ? invoiceDetails.services.property.propertyowner.prefix + '. ' : ''} {invoiceDetails.services.property.propertyowner.firstname + ' ' + invoiceDetails.services.property.propertyowner.lastname}

                                </Typography>
                            </div>
                        </div>
                        <div className='col-5'>
                            <div className='row'>
                                <div className='col-12 col-md-6 fw-bold text-md-end'>Invoice Date: </div>
                                <div className='col-12 col-md-6 text-md-end'>{dayjs(invoiceDetails.invoice_date).format('DD-MM-YYYY')}</div>
                                <div className='col-12 col-md-6 fw-bold text-md-end'>Invoice #: </div>
                                <div className='col-12 col-md-6 text-md-end'> {invoiceDetails.invoice_number}</div>
                                <div className='col-12 col-md-6 fw-bold text-md-end'>Service Date: </div>
                                <div className='col-12 col-md-6 text-md-end'>{invoiceData ? invoiceData.services.service_date : dayjs(invoiceDetails.services.service_date).format('DD-MM-YYYY')}</div>
                            </div>
                        </div>
                        <div className='col-md-12 p-5 text-end'>
                            <span style={{ borderRadius: '25px', textAlign: 'center', width: '100px', padding: '5px 15px', color: 'white', fontWeight: 'bold', backgroundColor: invoiceDetails.paid_status === 'Y' ? 'green' : 'red' }}>
                                {invoiceDetails.paid_status === 'Y' ? 'PAID' : 'NOT PAID'}
                            </span>
                        </div>
                        <div className='col-md-12'>
                            <Table>
                                <TableHead className="bg-smoke text-body fs-md fw-bold">
                                    <TableRow>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Service Charge AUD</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell>
                                            {invoiceDetails.description ? invoiceDetails.description : invoiceDetails.services.product.description}
                                        </TableCell>
                                        <TableCell className='text-end'>{invoiceDetails.amount ? invoiceDetails.amount.toFixed(2) : '0.00'}</TableCell>
                                    </TableRow>
                                </TableBody>
                                <TableFooter>
                                    {invoiceDetails.credit > 0 &&
                                        <TableRow>
                                            <TableCell className='text-end'>Credit AUD</TableCell>
                                            <TableCell className='text-end'>{invoiceDetails.credit ? invoiceDetails.credit.toFixed(2) : '0.00'}</TableCell>
                                        </TableRow>
                                    }
                                    <TableRow>
                                        <TableCell className='text-end'>Subtotal AUD</TableCell>
                                        <TableCell className='text-end'>{(invoiceDetails.amount - invoiceDetails.credit).toFixed(2)}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='fs-md text-end'>Total GST 10%</TableCell>
                                        <TableCell className='fs-md text-end'>{invoiceDetails.tax_rate ? invoiceDetails.tax_rate.toFixed(2) : '0.00'}</TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell className='fs-md text-end'>Total Amount AUD</TableCell>
                                        <TableCell className='fs-md text-end'>{invoiceDetails.total ? invoiceDetails.total.toFixed(2) : '0.00'}</TableCell>
                                    </TableRow>

                                </TableFooter>
                            </Table>
                        </div>
                    </div>
                </div>
            </>
            }
        </Box >
    )
}
export default Invoice;