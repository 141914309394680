import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";

function NoPage() {
    return (
        <>
            <SiteHeader />
            <div className="pb-5">
                <div className="container">
                    <div className="bg-smoke bg-bottom-right p-5">
                        <h6 className="text-theme">
                            OOPS, Page Not Found!
                        </h6>
                        <p>It may have expired! or there could be a typo. <br /> Perhaps you can click the menu above to find what you need on our home page.</p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default NoPage;