import axios from 'axios';

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const webBaseUrl = process.env.REACT_APP_WEB_BASE_URL;
export const appBaseUrl = process.env.REACT_APP_BASE_URL;

export const placeholder = {
    PREFIX: '[PREFIX]',
    FIRSTNAME: '[FIRST_NAME]',
    LASTNAME: '[LAST_NAME]',
    DUEDATE: '[DUE_DATE]',
    AGENT: '[AGENT]',
    SERVICETYPE: '[SERVICE_TYPE]',
    INVOICENO: '[INVOICE_NUMBER]',
    INVOICEDATE: '[INVOICE_DATE]',
    PAIDAMOUNT: '[PAID_AMOUNT]',
}

export const checkPermission = (module, action) => {
    const _role = localStorage.getItem('_role');
    let permissions = [];
    if (_role === 'SYS') {
        permissions.push('all');
        return (module === 'LANDLORD') ? false : true;
    } else if (module === 'DASHBOARD') {
        if (['STAFF', 'MG', 'AC', 'E-TRADIE', 'P-TRADIE'].includes(_role)) {
            permissions.push('view');
            permissions.push('schedule');
        }
        if (['STAFF', 'MG', 'AC'].includes(_role)) {
            permissions.push('all');
        }

    } else if (module === 'USERS') {
        if (['STAFF', 'MG'].includes(_role)) {
            permissions.push('add');
            permissions.push('view');
        }
        if (_role === 'MG') {
            permissions.push('notes');
            permissions.push('edit');
        }
    } else if (module === 'ADMINSTAFF') {
        if (_role === 'MG') {
            permissions.push('add');
            permissions.push('view');
            permissions.push('notes');
            permissions.push('edit');
        }
    } else if (module === 'PROPERTY') {
        if (_role === 'STAFF') {
            permissions.push('add');
            permissions.push('send');
        } else if (_role === 'MG') {
            permissions.push('add');
            permissions.push('view');
            permissions.push('edit');
            permissions.push('notes');
            permissions.push('send');
        } else if (_role === 'AC') {
            permissions.push('view');
        } else if (_role === 'REPNST') {
            permissions.push('send');
        } else if (['LDLORD', 'AGENT'].includes(_role)) {
            permissions.push('add');
            permissions.push('view');
            permissions.push('edit');
        }
        if (['MG', 'AC', 'LDLORD', 'AGENT'].includes(_role)) {
            permissions.push('viewinvoice');
            permissions.push('viewpayment');
            permissions.push('viewappointment');
            permissions.push('viewreport');
            permissions.push('viewcommunication');
        }
    } else if (module === 'JOB') {
        if (['STAFF', 'MG', 'AC', 'REPNST', 'E-TRADIE', 'P-TRADIE'].includes(_role)) {
            permissions.push('view');
        }
        if (['MG', 'AC', 'E-TRADIE', 'P-TRADIE'].includes(_role)) {
            if (_role !== 'AC') permissions.push('edit');
            permissions.push('report');
        }
        if (['E-TRADIE', 'P-TRADIE'].includes(_role)) {
            permissions.push('checklist');
        }
        if (['MG', 'AC'].includes(_role)) {
            if (_role !== 'AC') permissions.push('invoice');
            permissions.push('viewinvoice')
        }
    } else if (module === 'LANDLORD') {
        if (_role === 'AGENT') {
            permissions.push('add');
            permissions.push('edit');
        }
    }
    return permissions.includes(action);
}

export const axiosFunction = (api, method, params) => {
    const _token = localStorage.getItem("_token");
    axios.defaults.withCredentials = true;
    axios.defaults.headers['Accept'] = 'application/json';
    axios.defaults.headers['Content-Type'] = 'application/json';
    if (_token) axios.defaults.headers['Authorization'] = 'Bearer ' + _token;

    var axiosCall = null;

    if (method === 'post') {
        axiosCall = axios.post(apiBaseUrl + api, params);
    } else {
        axiosCall = axios.get(apiBaseUrl + api, { params });
    }

    axiosCall.catch((error) => {
        error.status = 'fail';
        if (error.code === "ERR_NETWORK") {
            error.message = "Something went wrong. Server is not repond to the request. Please contact administrator.";
        } else if (error.response.status === 401) {
            error.message = "Your session has expired!";
            localStorage.clear();
            //window.location.reload(false);
        } else {
            error.message = "Something went wrong. Server is not repond to the request. Please contact administrator.";
        }
        return error;
    });

    return axiosCall.then(response => response.data).then(resData => resData);
}

