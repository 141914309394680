import { useState } from 'react';
import {
  DataGrid, GridToolbar, gridPageCountSelector,
  gridPageSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid';

import { Pagination, PaginationItem, Stack } from '@mui/material';

export default function DataTableList(props) {

  //const [togglecol, settogglecol] = useState({});
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(props.columnVisibilityModel);

  /* useEffect(() => {
    settogglecol(props.columnVisibilityModel);
  }, [props.columnVisibilityModel]) */


  function CustomPagination() {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    return (
      <Stack spacing={2}>
        <Pagination
          showFirstButton
          showLastButton
          color="error"
          page={page + 1}
          count={pageCount}
          // @ts-expect-error
          renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
          onChange={(event, value) => apiRef.current.setPage(value - 1)}
        />
      </Stack>
    );
  }


  return (
    //checkboxSelection disableColumnMenu disableColumnSelector disableDensitySelector disableColumnFilter

    <div style={{ width: '100%', minHeight: '50px' }}>
      <DataGrid
        rowHeight={50}
        rows={props.rows}
        columns={props.columns}
        loading={props.loading}
        autosizeOptions={{ includeOutliers: true }}
        autoHeight={true}
        pageSizeOptions={[10, 25, 50, 100]}
        slots={{
          toolbar: GridToolbar,
          pagination: CustomPagination
        }}
        getRowHeight={() => 'auto'}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterExcludeHiddenColumns: true,
            },
          },
          pagination: {
            paginationModel: { pageSize: 10, page: 0 },
          }
        }}
        getRowClassName={(params) => {
          return (props.id && params.id === props.id) ? 'bg-theme2 text-white' : "";
        }}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '5px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '5px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '5px' },
        }}
      />
    </div>
  );
}
