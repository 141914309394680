import { useState, useEffect, createContext } from "react";
import { useLocation } from "react-router-dom";
import SiteHeader from "../components/SiteHeader";
import Footer from "../components/Footer";
import { Backdrop } from "@mui/material";
import ManageProperties from "../components/Properties/ManageProperties";
//import { Link } from "react-router-dom";
//import { useNavigate } from 'react-router-dom';

//import { apiBaseUrl, webBaseUrl } from '../common';

export const PropertyContext = createContext(null);

const Properties = () => {
    //const navigate = useNavigate(null);
    const [backdropopen, setBackDropOpen] = useState(false);
    //const _role = localStorage.getItem("_role");
    const comp = useLocation();
    let page = comp.pathname.split('/')[1];

    useEffect(() => {
        // eslint-disable-next-line   
        //const _token = localStorage.getItem("_token");   
        //if (!_token) navigate('/login');
    }, []);

    const handleClose = (event, reason) => {
        //if (reason && reason === "backdropClick") return false;

        setBackDropOpen(false);
    };

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropopen}
            >
                {/*<CircularProgress color="inherit" />*/}
                <div className="circle bg-theme">
                    Data is loading.<br />
                    It will take a moment.<br />
                    <div className="loaderDots">Please wait</div>
                </div>
            </Backdrop>
            <SiteHeader menu={page} />
            <div className="user-panel" >
                <div className="title text-start">{page === 'my-properties' ? 'My' : ''} Properties</div>
                <PropertyContext.Provider value={{ backdropopen, setBackDropOpen, handleClose }}>
                    <ManageProperties own={page === 'my-properties' ? 'Y' : 'N'} />
                </PropertyContext.Provider>
            </div>

            <Footer />
        </>
    );
}

export default Properties;