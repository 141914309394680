import { axiosFunction } from '../common';
export const ServiceUsersData = {
    // eslint-disable-next-line
    async fetchData() {
        try {
            const response = await axiosFunction('auth/serviceusers', 'post', {});
            if (response.status === 'success') {
                return response.data;
            } else {
                /*  if (response?.response?.status === 401) {
                     localStorage.clear();
                 } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.clear();
            } else {
                console.log(e);
            }
        }
    }
};