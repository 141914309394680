import { useState, useEffect, forwardRef, createContext, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Alert, IconButton, Collapse, Button, Dialog, Slide, DialogTitle, DialogContent, DialogContentText, DialogActions, Chip, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DataTableList from '../DataTableList';
import { checkPermission, axiosFunction } from '../../common';
import { UserForm } from "./UserForm";
import { UsersContext } from "../../pages/Users";

export const UserFormContext = createContext(null);
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ManageUsers = (props) => {
    const navigate = useNavigate(null);
    const _role = localStorage.getItem("_role");
    const _profile_id = localStorage.getItem("_profile_id");
    const { setBackDropOpen } = useContext(UsersContext);
    const [loader, setLoader] = useState(true);
    const [loaderText, setLoaderText] = useState(false);
    const [DTColumns, setDTColumns] = useState([]);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [confirmopen, setConfirmOpen] = useState(false);
    const [delData, setDelData] = useState({});
    const [noteOpen, setNoteOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [userOptions, setUserOptions] = useState(null);
    const [prefixOptions, setPrefixOptions] = useState(null);
    const [postcodeOptions, setPostcodeOptions] = useState(null);
    const [roleOptions, setRoleOptions] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [userId, setUserId] = useState('');
    let uTypeArr = { 'ADMSTF': 'Admin Staff', 'AGENT': 'Agent', 'LDLORD': 'Land Lord', 'TENANT': 'Tenant', 'E-TRADIE': 'Electrician Tradie', 'P-TRADIE': 'Plumber Tradie' }
    const userFormType = uTypeArr[props.type];

    const [formField, setFormField] = useState({
        'firstname': '',
        'lastname': '',
        'agents': [],
        'agency_name': '',
        'company': '',
        'license_number': '',
        'credit': '',
        'is_landlord': 'N',
        'phone': '',
        'prefix': '',
        'role_id': '',
        'email': '',
        'mobile': '',
        'address1': '',
        'address2': '',
        'suburb': '',
        'state': '',
        'postcode': '',
        'alertnote': '',
        'showalert': '',
        'status': 'Y',
        'action': ''
    });
    const [alertMessage, setAlertMessage] = useState('');
    const fetchData = async () => {
        try {
            setBackDropOpen(true);
            const params = { type: props.type };
            const sysUserData = await axiosFunction('individuals', 'get', params);
            if (sysUserData.status === 'success') {
                setDTColumns(sysUserData.data);
                setBackDropOpen(false);
                setLoader(false);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(sysUserData.message);
                setBackDropOpen(false);
                setLoader(false);
                /* if (sysUserData?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setBackDropOpen(false);
            setLoader(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    }

    const loadData = async () => {
        try {
            const prefixDataList = await axiosFunction('auth/prefix', 'get', {});
            const postCodeDataList = await axiosFunction('auth/postcodes', 'get', {});
            const userDataList = await axiosFunction('auth/users', 'post', {});
            const roleDataList = await axiosFunction('auth/roles', 'get', {});
            if (prefixDataList.status !== 'success') {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(prefixDataList.message);
                setLoader(false);
                /* if (prefixDataList?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
            if (prefixDataList.status === 'success' && postCodeDataList.status === 'success' && userDataList.status === 'success' && roleDataList.status === 'success') {
                setPrefixOptions(prefixDataList.data);
                setPostcodeOptions(postCodeDataList.data);
                setUserOptions(userDataList.data);
                setRoleOptions(roleDataList.data);
                setOpenForm(true);
                setLoader(false);
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoader(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (!openForm) loadData();
        if (openForm) fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.type, openForm]);

    const rows: GridRowsProp = DTColumns;

    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, filterable: false, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'lastname', headerName: 'Name', width: 150, filterable: true, renderCell: (params) => { return params.row.firstname + ' ' + params.row.lastname }, valueGetter: (params) => params.row.firstname + ' ' + params.row.lastname },
        { field: (props.type === 'AGENT') ? 'agency_name' : (props.type === 'ADMSTF') ? 'role_id' : 'license_number', headerName: (props.type === 'AGENT') ? 'Agency Name' : (props.type === 'ADMSTF') ? 'Role' : 'License Number', renderCell: (params) => (props.type === 'AGENT') ? params.row.agency_name : (props.type === 'ADMSTF') ? params.row.role && params.row.role.description : params.row.license_number, valueGetter: (params) => (props.type === 'AGENT') ? params.row.agency_name : (props.type === 'ADMSTF') ? params.row.role && params.row.role.description : params.row.license_number, width: 150, hide: true, filterable: true },
        { field: 'email', headerName: 'Email', width: 225, filterable: true, renderCell: (params) => { return params.row.individualuser.email }, valueGetter: (params) => params.row.individualuser.email },
        { field: 'mobile', headerName: 'Mobile', width: 125, filterable: true, renderCell: (params) => { return params.row.individualuser.mobile }, valueGetter: (params) => params.row.individualuser.mobile },
        { field: 'address', headerName: 'Address', width: 280, filterable: true, renderCell: (params) => { return (<>{params.row.address1}{params.row.address2 ? ', ' + params.row.address2 : ''}{params.row.suburb ? ', ' + params.row.suburb : ''}{params.row.state ? ', ' + params.row.state : ''} {params.row.postcode ? '-' + params.row.postcode : ''}</>) }, valueGetter: (params) => params.row.address1 + ' ' + params.row.address2 + ' ' + params.row.suburb + ' ' + params.row.state + ' ' + params.row.postcode },
        { field: 'status', headerName: 'Status', width: 100, filterable: true, renderCell: (params) => (params.row.status === 'Y') ? <Chip label="Active" color="primary" size="small" /> : <Chip label="Inactive" color="warning" size="small" />, valueGetter: (params) => (params.row.status === 'Y') ? 'Active' : 'Inactive' },
        {
            field: 'actions', headerName: 'Actions', width: 100, sortable: false, filterable: false, renderCell: (params) => {
                return (<>
                    {(checkPermission('USERS', 'edit') || checkPermission('LANDLORD', 'edit')) && <Tooltip title={"Edit " + userFormType} placement="top" arrow><Button type="button" onClick={() => handleEdit(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button></Tooltip>}
                    {checkPermission('USERS', 'delete') && <Tooltip title={"Delete " + userFormType} placement="top" arrow><Button type="button" onClick={() => deleteRecord(params.row.id)} style={{ minWidth: '24px' }}><i className="fa fa-trash"></i></Button></Tooltip>}
                    {checkPermission('USERS', 'notes') && params.row.showalert === 'Y' && <Tooltip title={"View " + userFormType} placement="top" arrow><Button type="button" onClick={(e) => viewAlert(params.row.alertnote)} style={{ minWidth: '24px' }}><i className="fa fa-message"></i></Button></Tooltip>}
                </>)
            }
        },
    ];

    const handleEdit = (data) => {
        setLoaderText(false);
        setUserId(data.id);
        setFormField({
            'firstname': data.firstname,
            'lastname': data.lastname,
            'agents': data.agents,
            'agency_name': data.agency_name,
            'company': data.company,
            'license_number': data.license_number,
            'credit': data.credit,
            'is_landlord': data.is_landlord,
            'phone': data.phone,
            'prefix': data.prefix,
            'role_id': data.role_id,
            'email': data.individualuser.email,
            'mobile': data.individualuser.mobile,
            'address1': data.address1,
            'address2': data.address2,
            'suburb': data.suburb,
            'state': data.state,
            'postcode': data.postcode,
            'alertnote': data.alertnote,
            'showalert': data.showalert,
            'status': data.status,
            'action': 'edit'
        });
        setDialogOpen(true);
    };

    const handleAdd = () => {
        setLoaderText(false);
        setUserId('');
        setFormField({
            'firstname': '',
            'lastname': '',
            'agents': [],
            'agency_name': '',
            'company': '',
            'license_number': '',
            'credit': '',
            'is_landlord': 'N',
            'phone': '',
            'prefix': '',
            'role_id': '',
            'email': '',
            'mobile': '',
            'address1': '',
            'address2': '',
            'suburb': '',
            'state': '',
            'postcode': '',
            'alertnote': '',
            'showalert': '',
            'status': 'Y',
            'action': 'add'
        });
        setDialogOpen(true);
    };

    const deleteRecord = (id) => {
        var data = {
            "id": id,
            "_method": 'DELETE'
        }
        setDelData(data);
        setConfirmOpen(true);
    }

    const handleDelete = async () => {
        try {
            const delResponse = await axiosFunction('individuals/' + delData.id, 'post', delData);
            setAlertOpen(true);
            if (delResponse.status === 'success') {
                setAlertStatus('success');
                setAlertMessage(delResponse.message);
                handleAutoClose();
                setConfirmOpen(false);
                setDelData({});
                fetchData();
            } else {
                setAlertStatus('error');
                setAlertMessage(delResponse.message);
                setConfirmOpen(false);
                setDelData({});
                /* if (delResponse?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setConfirmOpen(false);
            setDelData({});
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    };

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setUserId('');
        setFormField({
            'firstname': '',
            'lastname': '',
            'agents': [],
            'agency_name': '',
            'company': '',
            'license_number': '',
            'credit': '',
            'is_landlord': 'N',
            'phone': '',
            'perfix': '',
            'role_id': '',
            'email': '',
            'mobile': '',
            'address1': '',
            'address2': '',
            'suburb': '',
            'state': '',
            'postcode': '',
            'alertnote': '',
            'showalert': '',
            'status': '',
            'action': '',
        });
    };

    const handleConfirmClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setConfirmOpen(false);
            setDelData({});
        }
    };

    const onSubmit = async (data) => {
        //console.log(formField);  
        setLoaderText(true);
        //console.log(formField.showalert);
        //console.log(userId);        
        data.suburb = formField.suburb;
        data.state = formField.state;
        data.postcode = formField.postcode;
        data.showalert = formField.showalert;
        data.agents = [];
        if (props.type === 'LDLORD' && _role === 'AGENT') {
            data.agents = formField.agents;
        } else {
            data.agents.push(_profile_id);
        }
        if ((_role === 'SYS' || _role === 'MG') && props.type === 'LDLORD') data.credit = formField.credit;
        data.phone = formField.phone;
        data.is_landlord = props.type === 'LDLORD' ? 'Y' : formField?.is_landlord;
        //console.log(data); 
        try {
            let saveResponse = null;
            if (formField.action === 'edit') {
                data._method = 'PUT';
                saveResponse = await axiosFunction('individuals/' + userId, 'post', data);
            } else if (formField.action === 'add') {
                data.code = props.type;
                saveResponse = await axiosFunction('individuals', 'post', data);
            }
            setAlertOpen(true);
            if (saveResponse.status === 'success') {
                setAlertStatus('success');
                setAlertMessage(saveResponse.message);
                handleAutoClose();
                handleDialogClose();
                setLoaderText(false);
                fetchData();
            } else {
                setAlertStatus('error');
                setAlertMessage(saveResponse.message);
                setLoaderText(false);
                document.querySelector('#userform').scrollIntoView({ behavior: 'smooth' });
                /*  if (saveResponse?.response?.status === 401) {
                     localStorage.clear();
                     navigate('/login');
                 } */
            }
        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoaderText(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    };

    const viewAlert = (notes) => {
        setNoteOpen(true);
        setNotes(notes);
    };

    const handleNoteClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setNoteOpen(false);
            setNotes('');
        }
    };

    return (
        <>
            <Collapse in={alertOpen}>
                <Alert variant="filled" severity={alertStatus}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                                setAlertOpen(false);
                            }}
                        >
                            <CloseIcon fontSize="inherit" />
                        </IconButton>
                    }
                    sx={{ mb: 2 }}
                >
                    {alertMessage}
                </Alert>
            </Collapse>
            <Dialog
                open={confirmopen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleConfirmClose}
            >
                <DialogTitle>Are you sure you want to delete this {userFormType}?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        If yes, {userFormType} will be deleted from the records and no longer be exists.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                    <Button className="themeholy-btn btn-primary" onClick={() => handleDelete({ delData })}>Yes</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                open={noteOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleNoteClose}
            >
                <DialogTitle sx={{ bgcolor: '#000000', color: '#ffffff', marginBottom: '15px' }}>Notes</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-notes">
                        <i className="fa fa-info-circle"></i> {notes}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button className="themeholy-btn btn-primary" onClick={handleNoteClose}>Ok</Button>
                </DialogActions>
            </Dialog>
            {openForm && (
                <Dialog
                    fullScreen
                    open={dialogOpen}
                    onClose={handleDialogClose}
                    TransitionComponent={Transition}
                >

                    <UserFormContext.Provider value={
                        {
                            handleDialogClose,
                            onSubmit,
                            loaderText,
                            formField,
                            setFormField,
                            alertStatus,
                            alertMessage,
                            alertOpen,
                            setAlertOpen,
                            postcodeOptions,
                            prefixOptions,
                            userOptions,
                            roleOptions
                        }
                    }>
                        <UserForm formType={props.type} />
                    </UserFormContext.Provider>
                </Dialog>
            )}
            {(checkPermission('USERS', 'add') || checkPermission('LANDLORD', 'add')) &&
                <div style={{ display: "flex", marginBottom: "15px" }}>
                    <Button className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={handleAdd}>Add New {userFormType}</Button>
                </div>
            }
            <DataTableList columns={columns} rows={rows} loading={loader} columnVisibilityModel={{
                license_number: (props.type === 'E-TRADIE' || props.type === 'P-TRADIE'),
                actions: (checkPermission('USERS', 'edit') || checkPermission('LANDLORD', 'edit'))
            }} />
        </>
    );
}
export default ManageUsers;