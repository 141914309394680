import { useState, useEffect, createContext, forwardRef, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Alert, IconButton, Collapse, Button, Dialog, Slide, Chip, DialogTitle, DialogContent, DialogContentText, DialogActions, Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DataTableList from '../DataTableList';
import { checkPermission, axiosFunction } from '../../common';
import { PropertyForm } from "./PropertyForm";
import { PropertyView } from "./PropertyView";
import { PropertyContext } from "../../pages/Properties";
import dayjs from 'dayjs';
import { Textsms } from '@mui/icons-material';
import { MessageDialog } from "../Modal/MessageDialog";

export const FormContext = createContext(null);
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ManageProperties = ({ own }) => {
    const navigate = useNavigate(null);
    const _role = localStorage.getItem("_role");
    const _is_landlord = localStorage.getItem("_is_landlord");
    const { setBackDropOpen } = useContext(PropertyContext);
    const [loader, setLoader] = useState(true);
    const [loaderText, setLoaderText] = useState(false);
    const [DTColumns, setDTColumns] = useState([]);
    const [alertStatus, setAlertStatus] = useState('success');
    const [alertOpen, setAlertOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogViewOpen, setDialogViewOpen] = useState(false);
    const [confirmopen, setConfirmOpen] = useState(false);
    const [noteOpen, setNoteOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [delData, setDelData] = useState({});
    const [formChangeFlag, setFormChangeFlag] = useState(false);
    const [viewUpdateFlag, setViewUpdateFlag] = useState(false);
    const [userOptions, setUserOptions] = useState(null);
    const [postcodeOptions, setPostcodeOptions] = useState(null);
    const [lookUpsOptions, setLookUpsOptions] = useState(null);
    const [openForm, setOpenForm] = useState(false);
    const [propertyId, setPropertyId] = useState('');
    const [formField, setFormField] = useState({
        'property_id': '',
        'owner_id': '',
        'agent_id': '',
        'tenancy_name': '',
        'tenancy_email': '',
        'tenancy_mobile': '',
        'agent_name': '',
        'agency_name': '',
        'address1': '',
        'address2': '',
        'suburb': '',
        'state': '',
        'postcode': '',
        'alertnote': '',
        'showalert': '',
        'service_duedate': '',
        'product_code': '',
        'product_desc': '',
        'status': 'Y',
        'action': ''
    });
    const [currentVal, setCurrentVal] = useState({ product_code: '' });
    const [confirmSubmit, setConfirmSubmit] = useState(false);
    const [submitConfirmOpen, setSubmitConfirmOpen] = useState(false);
    const [openMessageDialog, setOpenMessageDialog] = useState(false);
    const [messageData, setMessageData] = useState({});
    const [updateTabList, setUpdateTabList] = useState(false);
    const [viewField, setViewField] = useState({
        'owner_id': '',
        'agent_id': '',
        'tenancy_name': '',
        'tenancy_email': '',
        'tenancy_mobile': '',
        'owner_prefix': '',
        'owner_name': '',
        'owner_credit': 0,
        'agent_prefix': '',
        'agent_name': '',
        'agency_name': '',
        'owner_email': '',
        'owner_mobile': '',
        'agent_mobile': '',
        'address1': '',
        'address2': '',
        'suburb': '',
        'state': '',
        'postcode': '',
        'owner_address1': '',
        'owner_address2': '',
        'owner_suburb': '',
        'owner_state': '',
        'owner_postcode': '',
        'status': '',
        'payments': {
            'overall': 0.00,
            'owner': {
                'overall': 0.00,
                'credit': 0.00
            }
        }
    });
    const [alertMessage, setAlertMessage] = useState('');
    const fetchData = async () => {
        try {
            setBackDropOpen(true);
            var params = { 'own': own }
            const propertyData = await axiosFunction('properties', 'get', params);
            if (propertyData.status === 'success') {
                setDTColumns(propertyData.data);
                setBackDropOpen(false);
                setLoader(false);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(propertyData.message);
                setBackDropOpen(false);
                setLoader(false);
                /* if (propertyData?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setBackDropOpen(false);
            setLoader(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    }

    const loadData = async () => {
        try {
            const lookUpsDataList = await axiosFunction('auth/lookups', 'get', {});
            const postCodeDataList = await axiosFunction('auth/postcodes', 'get', {});
            const userDataList = await axiosFunction('auth/users', 'post', {});
            if (lookUpsDataList.status === 'success') {
                setUserOptions(userDataList.data);
                setPostcodeOptions(postCodeDataList.data);
                setLookUpsOptions(lookUpsDataList.data);
                setOpenForm(true);
            } else {
                setAlertOpen(true);
                setAlertStatus('error');
                setAlertMessage(lookUpsDataList.message);
                setLoader(false);
                /* if (lookUpsDataList?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            setAlertOpen(true);
            setAlertStatus('error');
            setAlertMessage(e.message);
            setLoader(false);
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    }

    useEffect(() => {
        if (!openForm) loadData();
        if (openForm && _role !== 'LDLORD') {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openForm, own]);

    const handleSMS = (data, usrcode, viewFlag) => {
        if (viewFlag) {
            setMessageData({
                property_id: data.property_id,
                property_details: <><div>{`${data.address1} ${(data.address2 ? ', ' + data.address2 : '')}`},</div><div>{`${data.suburb}, ${data.state} - ${data.postcode}`}</div></>,
                usr_code: usrcode,
                usr_id: usrcode === 'LDLORD' ? data.owner_id : usrcode === 'AGENT' ? data.agent_id : '',
                to: usrcode === 'LDLORD' ? data.owner_mobile : usrcode === 'AGENT' ? data.agent_mobile : data.tenancy_mobile,
                name: usrcode === 'LDLORD' ? data.owner_name : usrcode === 'AGENT' ? data.agent_name : data.tenancy_name,
                email: usrcode === 'LDLORD' ? data.owner_email : usrcode === 'AGENT' ? data.agent_email : data.tenancy_email
            });
        } else {
            setMessageData({
                property_id: data.id,
                property_details: <><div>{`${data.address1} ${(data.address2 ? ', ' + data.address2 : '')}`},</div><div>{`${data.suburb}, ${data.state} - ${data.postcode}`}</div></>,
                usr_code: usrcode,
                usr_id: usrcode === 'LDLORD' ? data.owner.individualuser.id : usrcode === 'AGENT' ? data.agent.individualuser.id : '',
                to: usrcode === 'LDLORD' ? data.owner.individualuser.mobile : usrcode === 'AGENT' ? data.agent.individualuser.mobile : data.tenancy_mobile,
                name: usrcode === 'LDLORD' ? (data.owner.prefix + '. ' + data.owner.firstname + ' ' + data.owner.lastname) : usrcode === 'AGENT' ? (data.agent.prefix + '. ' + data.agent.firstname + ' ' + data.agent.lastname) : data.tenancy_name,
                email: usrcode === 'LDLORD' ? data.owner.individualuser.email : usrcode === 'AGENT' ? data.agent.individualuser.email : data.tenancy_email
            });
        }
        setOpenMessageDialog(true);
    }

    const rows: GridRowsProp = DTColumns;
    const columns: GridColDef[] = [
        { field: 'sno', headerName: '#', width: 50, renderCell: (params) => params.api.getAllRowIds().indexOf(params.id) + 1 },
        { field: 'owner.lastname', headerName: 'Land Lord', width: 200, renderCell: (params) => { return (<div><div>N: {params.row.owner.firstname}  {params.row.owner.lastname}</div><div>E: {params.row.owner.individualuser.email}</div><div>M: {params.row.owner.individualuser.mobile} {params.row.owner.individualuser.mobile && checkPermission('PROPERTY', 'send') && <Link to='#' onClick={() => handleSMS(params.row, 'LDLORD', false)}><Textsms color='error' /></Link>}</div></div>) }, valueGetter: (params) => params.row.owner.firstname + ' ' + params.row.owner.lastname + ' ' + params.row.owner.individualuser.email + ' ' + params.row.owner.individualuser.mobile },
        { field: 'agent_id', headerName: 'Agent', width: 200, renderCell: (params) => { return ((params.row.agent && params.row.agent.individualuser) ? <div><div>N: {params.row.agent.firstname} {params.row.agent.lastname}</div><div>E: {params.row.agent.individualuser.email}</div><div>M: {params.row.agent.individualuser.mobile} {params.row.agent.individualuser.mobile && checkPermission('PROPERTY', 'send') && <Link to='#' onClick={() => handleSMS(params.row, 'AGENT', false)}><Textsms color='error' /></Link>}</div></div> : '') }, valueGetter: (params) => (params.row.agent && params.row.agent.individualuser) ? params.row.agent.firstname + ' ' + params.row.agent.lastname + ' ' + params.row.agent.individualuser.email + ' ' + params.row.agent.individualuser.mobile : '' },
        { field: 'tenancy_name', headerName: 'Tenant', width: 200, renderCell: (params) => { return (<div><div>N: {params.row.tenancy_name}</div><div>E: {params.row.tenancy_email}</div><div>M: {params.row.tenancy_mobile} {params.row.tenancy_mobile && checkPermission('PROPERTY', 'send') && <Link to='#' onClick={() => handleSMS(params.row, 'TENANT', false)}><Textsms color='error' /></Link>}</div></div>) }, valueGetter: (params) => params.row.tenancy_name + ' ' + params.row.tenancy_email + ' ' + params.row.tenancy_mobile },
        { field: 'service_duedate', headerName: 'Service Due Date', width: 130 },
        { field: 'address', headerName: 'Address', width: 200, renderCell: (params) => (params.row.address1 + (params.row.address2 ? ', ' + params.row.address2 : '') + ', ' + params.row.suburb + ', ' + params.row.state + ' - ' + params.row.postcode), valueGetter: (params) => params.row.address1 + (params.row.address2 ? ', ' + params.row.address2 : '') + ', ' + params.row.suburb + ', ' + params.row.state + ' - ' + params.row.postcode },

        { field: 'status', headerName: 'Status', width: 100, renderCell: (params) => (params.row.status === 'Y') ? <Chip label="Active" color="primary" size="small" /> : (params.row.status === 'P') ? <Chip label="Pending" color="error" size="small" /> : <Chip label="Inactive" color="warning" size="small" />, valueGetter: (params) => (params.row.status === 'Y') ? 'Active' : (params.row.status === 'P') ? 'Pending' : 'Inactive' },
        {
            field: 'actions', headerName: 'Actions', width: 100, sortable: false, renderCell: (params) => {
                return (<>
                    {checkPermission('PROPERTY', 'view') && <Tooltip title="View Property" placement="top" arrow ><Button type="button" onClick={(e) => handleView(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-eye"></i></Button></Tooltip>}
                    {checkPermission('PROPERTY', 'edit') && <Tooltip title="Edit Property" placement="top" arrow ><Button type="button" onClick={(e) => handleEdit(params.row)} style={{ minWidth: '24px' }}><i className="fa fa-edit"></i></Button></Tooltip>}
                    {checkPermission('PROPERTY', 'delete') && <Tooltip title="Delete Property" placement="top" arrow ><Button type="button" onClick={(e) => deleteRecord(params.row.id)} style={{ minWidth: '24px' }}><i className="fa fa-trash"></i></Button></Tooltip>}
                    {checkPermission('PROPERTY', 'notes') && params.row.showalert === 'Y' && <Tooltip title="View Alert" placement="top" arrow ><Button type="button" onClick={(e) => viewAlert(params.row.alertnote)} style={{ minWidth: '24px' }}><i className="fa fa-message"></i></Button></Tooltip>}
                </>)
            }, hide: true
        },
    ];

    const handleView = (data) => {
        setPropertyId(data.id);
        setLoaderText(false);
        setViewUpdateFlag(true);
        setViewField({
            'owner_id': data.owner_id,
            'owner_prefix': data.owner.prefix,
            'owner_name': data.owner.firstname + ' ' + data.owner.lastname,
            'owner_email': data.owner.individualuser.email,
            'owner_mobile': data.owner.individualuser.mobile,
            'owner_address1': data.owner.address1,
            'owner_address2': data.owner.address2,
            'owner_suburb': data.owner.suburb,
            'owner_state': data.owner.state,
            'owner_postcode': data.owner.postcode,
            'owner_credit': data.owner.credit,
            'agent_id': data.agent_id,
            'agent_prefix': data.agent ? data.agent.prefix : '',
            'tenancy_name': data.tenancy_name,
            'tenancy_email': data.tenancy_email,
            'tenancy_mobile': data.tenancy_mobile,
            'agent_name': data.agent ? (data.agent.firstname ? data.agent.firstname + ' ' : '') + data.agent.lastname : '',
            'agency_name': data.agent ? data.agent.agency_name : '',
            'agent_mobile': data.agent && data.agent.individualuser ? data.agent.individualuser.mobile : '',
            'address1': data.address1,
            'address2': data.address2,
            'suburb': data.suburb,
            'state': data.state,
            'postcode': data.postcode,
            'product': data.product,
            'product_code': data.product_code,
            'product_desc': data.product.description ? data.product.description + ' - $' + data.product.service_charge : '',
            'status': data.status,
            'payments': data.payments,
            'action': 'view'
        });
        if (!dialogViewOpen) setDialogViewOpen(true);
    }

    const viewAlert = (notes) => {
        setNoteOpen(true);
        setNotes(notes);
    };

    const handleAdd = (data) => {
        setFormField({
            'property_id': '',
            'owner_id': (data.owner_id) ? data.owner_id : '',
            'owner_prefix': (data.owner_prefix) ? data.owner_prefix : '',
            'owner_name': (data.owner_name) ? data.owner_name : '',
            'owner_mobile': (data.owner_mbile) ? data.owner_mbile : '',
            'agent_id': (data.agent_id) ? data.agent_id : '',
            'agent_prefix': (data.agent_prefix) ? data.agent_prefix : '',
            'tenancy_name': (data.tenancy_name) ? data.tenancy_name : '',
            'tenancy_email': data.tenancy_email ? data.tenancy_email : '',
            'tenancy_mobile': (data.tenancy_mobile) ? data.tenancy_mobile : '',
            'agent_name': (data.agent_name) ? data.agent_name : '',
            'agency_name': (data.agency_name) ? data.agency_name : '',
            'agent_mobile': (data.agent_mobile) ? data.agent_mobile : '',
            'address1': '',
            'address2': '',
            'suburb': '',
            'state': '',
            'postcode': '',
            'alertnote': '',
            'showalert': '',
            'service_duedate': '',
            'picture': [],
            'product_code': '',
            'product_desc': '',
            'status': 'Y',
            'action': 'add'
        });
        setDialogOpen(true);
    };

    const handleEdit = (data) => {
        setLoaderText(false);
        const serviceduedate = data.service_duedate ? data.service_duedate.split('/') : null;
        setFormField({
            'property_id': data.id,
            'owner_id': data.owner_id,
            'owner_prefix': data.owner.prefix,
            'owner_name': data.owner.prefix + '. ' + data.owner.firstname + ' ' + data.owner.lastname,
            'owner_email': data.owner.individualuser.email,
            'owner_mobile': data.owner.individualuser.mobile,
            'agent_id': data.agent_id,
            'agent_prefix': data.agent ? data.agent.prefix : '',
            'tenancy_name': data.tenancy_name,
            'tenancy_email': data.tenancy_email,
            'tenancy_mobile': data.tenancy_mobile,
            'agent_name': data.agent ? data.agent.prefix + '. ' + (data.agent.firstname ? data.agent.firstname + ' ' : '') + data.agent.lastname : '',
            'agency_name': data.agent ? data.agent.agency_name : '',
            'agent_mobile': data.agent && data.agent.individualuser ? data.agent.individualuser.mobile : '',
            'address1': data.address1,
            'address2': data.address2,
            'suburb': data.suburb,
            'state': data.state,
            'postcode': data.postcode,
            'alertnote': data.alertnote,
            'service_duedate': serviceduedate ? dayjs(serviceduedate[2] + '-' + serviceduedate[1] + '-' + serviceduedate[0]) : null,
            'product_code': data.product_code,
            'product_desc': data.product.description ? data.product.description + ' - $' + data.product.service_charge : '',
            'showalert': data.showalert,
            'status': data.status,
            'action': 'edit'
        });

        setCurrentVal({ product_code: data.product_code });
        setFormChangeFlag(false);
        setDialogOpen(true);
    };

    const checkFormChange = () => {
        setFormChangeFlag(true);
    };

    const handleAutoClose = () => {
        window.scrollTo(0, 0);
        setTimeout(() => setAlertOpen(false), 5000);
    };

    const handleViewDialogClose = (_, refList) => {
        setDialogViewOpen(false);
        setPropertyId('');
        setViewField({
            'owner_id': '',
            'agent_id': '',
            'tenancy_name': '',
            'tenancy_email': '',
            'tenancy_mobile': '',
            'owner_prefix': '',
            'owner_name': '',
            'owner_credit': 0,
            'agent_prefix': '',
            'agent_name': '',
            'agency_name': '',
            'owner_email': '',
            'owner_mobile': '',
            'agent_mobile': '',
            'address1': '',
            'address2': '',
            'suburb': '',
            'state': '',
            'postcode': '',
            'owner_address1': '',
            'owner_address2': '',
            'owner_suburb': '',
            'owner_state': '',
            'owner_postcode': '',
            'status': '',
            'payments': {
                'overall': 0.00,
                'owner': {
                    'overall': 0.00,
                    'credit': 0.00
                }
            }
        });
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setFormField({
            'property_id': '',
            'owner_id': '',
            'agent_id': '',
            'tenancy_name': '',
            'tenancy_email': '',
            'tenancy_mobile': '',
            'agent_name': '',
            'agency_name': '',
            'address1': '',
            'address2': '',
            'suburb': '',
            'state': '',
            'postcode': '',
            'alertnote': '',
            'showalert': '',
            'service_duedate': '',
            'picture': [],
            'product_code': '',
            'product_desc': '',
            'status': '',
            'action': '',
        });
    };

    const deleteRecord = (id) => {
        var data = {
            "id": id,
            "_method": 'DELETE'
        }
        setDelData(data);
        setConfirmOpen(true);
    }

    const handleDelete = async () => {
        try {
            const delResponse = await axiosFunction('properties/' + delData.id, 'post', delData);
            setAlertOpen(true);
            if (delResponse.status === 'success') {
                setAlertStatus('success');
                setAlertMessage(delResponse.message);
                handleAutoClose();
                setConfirmOpen(false);
                setDelData({});
                fetchData();
            } else {
                setAlertStatus('error');
                setAlertMessage(delResponse.message);
                setConfirmOpen(false);
                setDelData({});
            }

        } catch (e) {
            setAlertStatus('error');
            setAlertMessage(e.message);
            setConfirmOpen(false);
            setDelData({});
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            }
        }
    };

    const handleConfirmClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setConfirmOpen(false);
            setDelData({});
        }
    };

    const handleNoteClose = (event, reason) => {
        if (reason !== 'backdropClick') {
            setNoteOpen(false);
            setNotes('');
        }
    };

    const onSubmit = async (data) => {
        //console.log(data)
        setLoaderText(true);
        if (confirmSubmit || (currentVal.product_code && currentVal.product_code === formField.product_code) || formField.action === 'add') {
            setSubmitConfirmOpen(false);
            if (formField.action === 'add') {
                if (_role === 'AGENT' || _is_landlord === 'Y') {
                    data.status = 'P';
                } else {
                    data.status = checkPermission('JOB', 'edit') ? 'Y' : 'N';
                }
            }
            if (!data.agent_id) {
                data.agent_id = formField.agent_id ? formField.agent_id : '';
            }
            //if (formField.owner_id !== data.owner_id) data.owner_id = formField.owner_id;
            //if (formField.agent_id !== data.agent_id) data.agent_id = formField.agent_id;
            // console.log(formField);
            data.suburb = formField.suburb;
            data.state = formField.state;
            data.postcode = formField.postcode;
            data.service_duedate = dayjs(formField.service_duedate).format('DD-MM-YYYY');
            data.product_code = formField.product_code;
            if (!data.showalert) {
                data.showalert = formField.showalert === 'Y' ? 'Y' : 'N';
            }

            // console.log(data); 
            //console.log(formChangeFlag);
            // console.log(formField.property_id);

            if (formChangeFlag) {
                //console.log(formField.action);
                //console.log(data);
                try {
                    let saveResponse = null;
                    if (formField.action === 'edit') {
                        data._method = 'PUT';
                        saveResponse = await axiosFunction('properties/' + formField.property_id, 'post', data);
                    } else if (formField.action === 'add') {
                        saveResponse = await axiosFunction('properties', 'post', data);
                    }
                    setAlertOpen(true);
                    if (saveResponse.status === 'success') {
                        setAlertStatus('success');
                        setAlertMessage(saveResponse.message);
                        handleAutoClose();
                        handleDialogClose();
                        setViewUpdateFlag(own === 'Y' ? true : false);
                        setLoaderText(false);
                        fetchData();
                    } else {
                        setAlertStatus('error');
                        setAlertMessage(saveResponse.message);
                        setLoaderText(false);
                        document.querySelector('#propertyForm').scrollIntoView({ behavior: 'smooth' });
                        /* if (saveResponse?.response?.status === 401) {
                            localStorage.clear();
                            navigate('/login');
                        } */
                    }
                } catch (e) {
                    setAlertStatus('error');
                    setAlertMessage(e.message);
                    setLoaderText(false);
                    document.querySelector('#propertyForm').scrollIntoView({ behavior: 'smooth' });
                    if (e?.response?.status === 401) {
                        localStorage.clear();
                        navigate('/login');
                    }
                }
            } else {
                handleDialogClose();
                setViewUpdateFlag(true);
                setLoaderText(false);
                //setFormField((prev) => ({ ...prev, owner_id: data.owner_id }));
                //if(!dialogViewOpen) setDialogViewOpen(true);
            }
        } else if (!confirmSubmit) {
            setSubmitConfirmOpen(true);
            setLoaderText(false);
        } else {
            setLoaderText(false);
        }
    };

    return (
        <>
            <Dialog
                fullScreen
                open={dialogOpen}
                onClose={handleDialogClose}
                TransitionComponent={Transition}
            >
                <FormContext.Provider value={
                    {
                        handleDialogClose,
                        onSubmit,
                        loaderText,
                        formField,
                        setFormField,
                        alertStatus,
                        alertMessage,
                        alertOpen,
                        setAlertOpen,
                        userOptions,
                        setUserOptions,
                        postcodeOptions,
                        lookUpsOptions,
                        propertyId,
                        checkFormChange,
                        openForm,
                        currentVal,
                        confirmSubmit,
                        setSubmitConfirmOpen,
                        submitConfirmOpen,
                        setConfirmSubmit
                    }
                }>
                    <PropertyForm own={own} />
                </FormContext.Provider>
            </Dialog>

            {own !== 'Y' ? (
                <>
                    <Collapse in={alertOpen}>
                        <Alert variant="filled" severity={alertStatus}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={() => {
                                        setAlertOpen(false);
                                    }}
                                >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            }
                            sx={{ mb: 2, id: "alertmessage" }}
                        >
                            {alertMessage}
                        </Alert>
                    </Collapse>
                    <Dialog
                        open={confirmopen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleConfirmClose}
                    >
                        <DialogTitle>Are you sure you want to delete this property?</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-description">
                                If yes, property will be deleted from the records and no longer be exists.
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="themeholy-btn btn-secondary" onClick={handleConfirmClose}>Cancel</Button>
                            <Button className="themeholy-btn btn-primary" onClick={() => handleDelete({ delData })}>Yes</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        fullWidth
                        open={noteOpen}
                        TransitionComponent={Transition}
                        keepMounted
                        onClose={handleNoteClose}
                    >
                        <DialogTitle sx={{ bgcolor: '#000000', color: '#ffffff', marginBottom: '15px' }}>Notes</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-slide-notes">
                                <i className="fa fa-info-circle"></i> {notes}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button className="themeholy-btn btn-primary" onClick={handleNoteClose}>Ok</Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        fullScreen
                        open={dialogViewOpen}
                        onClose={handleViewDialogClose}
                        TransitionComponent={Transition}
                    >
                        <FormContext.Provider value={
                            {
                                handleViewDialogClose,
                                setLoaderText,
                                handleAdd,
                                handleEdit,
                                handleView,
                                viewField,
                                setViewField,
                                userOptions,
                                postcodeOptions,
                                propertyId,
                                setPropertyId,
                                viewUpdateFlag,
                                openForm,
                                updateTabList,
                                setUpdateTabList,
                                handleSMS
                            }
                        }>
                            <PropertyView own='N' />
                        </FormContext.Provider>
                    </Dialog>
                    {checkPermission('PROPERTY', 'add') &&
                        <div style={{ display: "flex", marginBottom: "15px" }}>
                            <Button type="button" className="themeholy-btn btn-primary" style={{ marginLeft: "auto" }} onClick={() => handleAdd({})}>Add {own === 'Y' ? 'My' : ''} New Property</Button>
                        </div>
                    }
                    <DataTableList columns={columns} rows={rows} loading={loader} columnVisibilityModel={{ actions: (checkPermission('PROPERTY', 'view') || checkPermission('PROPERTY', 'edit')) }} /></>)
                : (
                    <FormContext.Provider value={
                        {
                            handleViewDialogClose,
                            setLoaderText,
                            handleAdd,
                            handleEdit,
                            handleView,
                            formField,
                            setFormField,
                            userOptions,
                            postcodeOptions,
                            propertyId,
                            setPropertyId,
                            viewField,
                            setViewField,
                            viewUpdateFlag,
                            openForm,
                            updateTabList,
                            setUpdateTabList,
                            handleSMS
                        }
                    }>
                        <PropertyView own={own} />
                    </FormContext.Provider>
                )}
            <MessageDialog openMessageDialog={openMessageDialog} messageData={messageData} setMessageData={setMessageData} setOpenMessageDialog={setOpenMessageDialog} setUpdateTabList={setUpdateTabList} />
        </>
    );
}
export default ManageProperties;