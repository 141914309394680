import { useState, useEffect, useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { Alert, Collapse, Box, TextField, IconButton, Button, AppBar, Toolbar, Typography, FormControl, InputLabel, Select, MenuItem, Autocomplete } from "@mui/material";
//createFilterOptions
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import { UserFormContext } from "./ManageUsers";
import { PatternFormat } from 'react-number-format';
//import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
//import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { NumericFormat } from 'react-number-format';

//const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
//const checkedIcon = <CheckBoxIcon fontSize="small" />;


export const UserForm = (props) => {
    const _role = localStorage.getItem("_role");
    //const _profile_id = localStorage.getItem("_profile_id");
    // const _user_name = localStorage.getItem("_user_name");
    const { handleDialogClose, onSubmit, loaderText, formField, setFormField, alertStatus, alertMessage, alertOpen, setAlertOpen, postcodeOptions, prefixOptions, roleOptions } = useContext(UserFormContext);
    const [pcodeValue, setPcodeValue] = useState({ code: formField.postcode, suburb: formField.suburb, state_shortname: formField.state });
    //const agentData = userOptions.filter(a => a.code === "AGENT");
    const [showAlertCheck, setShowAlertCheck] = useState(formField.showalert === 'Y' ? true : false);
    const [isLandlord, setIsLandlord] = useState(formField.is_landlord === 'Y' ? true : false);
    const { register, control, handleSubmit, formState: { errors }, reset } = useForm();

    let uTypeArr = { 'ADMSTF': 'Admin Staff', 'AGENT': 'Agent', 'LDLORD': 'Land Lord', 'TENANT': 'Tenant', 'E-TRADIE': 'Electrician Tradie', 'P-TRADIE': 'Plumber Tradie' }
    const userFormType = uTypeArr[props.formType];
    const actionFun = formField.action;

    //let inputRef;

    useEffect(() => {
        reset(formField);
        //if (inputRef) inputRef.focus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.formType]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    /* const filterOptions = createFilterOptions({
        stringify: (option) => option.label + ' ' + option.individualuser.mobile,
    }); */

    //console.log(formField.prefix);

    const getSelectedItem = (prefix) => {
        // eslint-disable-next-line
        const item = prefixOptions.find((opt) => {
            if (opt && opt.description === prefix) {
                //setPrefixValue(prefix);
                return opt;
            };

        })

        return item || null;
    }

    const getSelectedRoleItem = (role) => {
        // eslint-disable-next-line
        const item = roleOptions.find((opt) => {
            if (opt && opt.id === role) {
                return opt;
            };
        })
        return item || null;
    }



    /* const getSelectedAgents = (agents) => {
        // return  [agentData[0], agentData[5]];
        let agentItems = [];
        if (agents) {
            let i = 0;
            // eslint-disable-next-line   
            agentData.find((opt) => {
                // eslint-disable-next-line 
                agents.map((item) => {
                    if (opt.id === item) {
                        agentItems[i] = opt;
                        i++;
                    }
                });
            });
        }
        //console.log(agentItems);

        return agentItems || [];
    } */

    const handleMobileChange = (e) => {
        if (e.target.value.match("(_+)") === null) {
            setFormField((prev) => ({ ...prev, mobile: e.target.value }))
            return e.target.value;
        } else return '';
    }


    return (
        <Box component="form" id="userform" noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <AppBar sx={{ position: 'fixed' }} className="app-header-top">
                <Toolbar className="dflex">
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleDialogClose}
                        aria-label="close"
                        className="me-auto"
                    >
                        <CloseIcon />
                        <Typography sx={{ p: 2, flex: 1, width: 'auto' }} variant="h6" component="div" className="d-none d-lg-block">
                            {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} {userFormType}
                        </Typography>
                    </IconButton>

                    <Button type="button" className="themeholy-btn btn-secondary" style={{ marginRight: '10px' }} onClick={handleDialogClose}>
                        Cancel
                    </Button>
                    <Button type="submit" autoFocus className="themeholy-btn btn-normal" disabled={loaderText}>
                        {(loaderText) ? 'Saving...' : 'Save'}
                    </Button>
                </Toolbar>
            </AppBar>
            <div className="container" style={{ paddingTop: '90px' }}>
                <Collapse in={alertOpen}>
                    <Alert variant="filled" severity={alertStatus}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={() => {
                                    setAlertOpen(false);
                                }}
                            >
                                <CloseIcon fontSize="inherit" />
                            </IconButton>
                        }

                    >
                        {alertMessage}
                    </Alert>
                </Collapse>

                <div className="row">
                    <div className="col-md-12 d-block d-lg-none">
                        <Typography sx={{ marginBottom: '15px' }} variant="h6" component="div">
                            {actionFun && actionFun[0].toUpperCase() + actionFun.slice(1)} {userFormType}
                        </Typography>
                    </div>
                    <div className="col-md-6">
                        <h5 className="mb-3" style={{ textAlign: 'left' }}>Personal Info:</h5>

                        {/* props.formType === 'LDLORD' && (<>
                            {_role === 'AGENT' ?
                                <div className="row">
                                    <Typography sx={{ mb: 5 }} variant="body" component="label" className="col-md-4">
                                        Agent
                                    </Typography>
                                    <Typography sx={{ mb: 5 }} variant="body" component="div" className="col-md-8">
                                        {_user_name}
                                    </Typography>
                                </div>
                                :
                                <Controller
                                    render={(props) => (
                                        <Autocomplete
                                            multiple
                                            disableClearable
                                            disableCloseOnSelect
                                            options={agentData}
                                            getOptionLabel={(option) => option.label}
                                            value={formField.agents ? getSelectedAgents(formField.agents) : []}
                                            // filterOptions={filterOptions}
                                            renderOption={(args, option, { selected }) => (
                                                <Box component="li" {...args} key={"agents_" + option.id}>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.label}
                                                </Box>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Agents"
                                                    variant="outlined"
                                                    placeholder="choose agents"
                                                />
                                            )}
                                            onChange={(_, data) => {
                                                props.field.onChange(data.id);
                                                let agentArr = [];
                                                data.map((item, index) => agentArr[index] = item.id);
                                                //console.log(agentArr);
                                                setFormField((prev) => ({ ...prev, agents: agentArr }));
                                            }}

                                        />
                                    )}
                                    name="agents[]"
                                    control={control}
                                />
                            }
                        </>
                        ) */}

                        <Controller
                            name="prefix"
                            control={control}
                            rules={{
                                required: (props.formType === "AGENT" ? false : "Prefix is required")
                            }}
                            render={({ field, fieldState }) => (
                                <Autocomplete
                                    disablePortal
                                    id="prefix"
                                    options={prefixOptions}
                                    getOptionLabel={(option) => option.description ? option.description : ''}

                                    sx={{ width: '100%' }}
                                    renderOption={(props, option) => (
                                        <Box component="li" {...props} key={"prefix_" + option.id}>
                                            {option.description}
                                        </Box>
                                    )}
                                    //isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                    renderInput={(params) => <TextField {...params} label="Prefix" variant="outlined"
                                        error={!!fieldState.error}
                                        helperText={fieldState.error?.message}
                                    /* inputRef={input => {
                                        inputRef = input;
                                    }} */
                                    />}
                                    onChange={(_, data) => {
                                        field.onChange(data ? data.description : '')
                                        setFormField((prev) => ({ ...prev, prefix: data ? data.description : '' }))
                                    }}

                                    value={getSelectedItem(formField.prefix)}
                                />
                            )}
                        />


                        <TextField
                            fullWidth
                            id="firstname" name="firstname" variant="outlined" error={errors.firstname ? true : false} label="Firstname *"
                            helperText={errors.firstname?.message}

                            inputProps={{ maxLength: 40, value: formField.firstname }}

                            {...register("firstname", { required: "Firstname is required" })}

                            onChange={(e) => setFormField((prev) => ({ ...prev, firstname: e.target.value }))}
                        />


                        <TextField
                            fullWidth
                            id="lastname" name="lastname" variant="outlined" error={errors.lastname ? true : false} label="Lastname *"
                            helperText={errors.lastname?.message}

                            inputProps={{ maxLength: 40, value: formField.lastname }}

                            {...register("lastname", { required: "Lastname is required" })}

                            onChange={(e) => setFormField((prev) => ({ ...prev, lastname: e.target.value }))}
                        />

                        {props.formType === 'AGENT' && (

                            <TextField
                                fullWidth
                                id="agency_name" name="agency_name" variant="outlined" error={errors.agency_name ? true : false} label="Agency Name *"
                                helperText={errors.agency_name?.message}

                                inputProps={{ maxLength: 100, value: formField.agency_name }}

                                {...register("agency_name", { required: "Agency Name is required" })}

                                onChange={(e) => setFormField((prev) => ({ ...prev, agency_name: e.target.value }))}

                            />
                        )}

                        {(props.formType === 'E-TRADIE' || props.formType === 'P-TRADIE') && (
                            <>
                                <TextField
                                    fullWidth
                                    id="company" name="company" variant="outlined" error={errors.company ? true : false} label="Company *"
                                    helperText={errors.company?.message}

                                    inputProps={{ maxLength: 100, value: formField.company ? formField.company : '' }}

                                    {...register("company", { required: "Company is required" })}

                                    onChange={(e) => setFormField((prev) => ({ ...prev, company: e.target.value }))}

                                />
                                <TextField
                                    fullWidth
                                    id="license_number" name="license_number" variant="outlined" error={errors.license_number ? true : false} label="License Number *"
                                    helperText={errors.license_number?.message}

                                    inputProps={{ maxLength: 50, value: formField.license_number ? formField.license_number : '' }}

                                    {...register("license_number", { required: "License Number is required" })}

                                    onChange={(e) => setFormField((prev) => ({ ...prev, license_number: e.target.value }))}

                                /></>
                        )}

                        <TextField
                            fullWidth
                            id="email" name="email" variant="outlined" error={errors.email ? true : false} label="Email Address *"
                            helperText={errors.email?.message}

                            inputProps={{ maxLength: 60, value: formField.email }}

                            {...register("email", {
                                required: "Email Address is required", pattern: {
                                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                    message: 'Please enter a valid email',
                                }
                            })}

                            onChange={(e) => setFormField((prev) => ({ ...prev, email: e.target.value }))}
                        />

                        <Controller
                            name="mobile"
                            control={control}
                            rules={{
                                //required: "A valid mobile number is required",
                                required: false,
                                pattern: {
                                    value: /^04\d{8}$/,
                                    message: 'Please enter a valid mobile number',
                                }
                            }}

                            render={({ field, fieldState }) => (
                                <PatternFormat
                                    label="Mobile"
                                    customInput={TextField}
                                    type="tel"
                                    format="04########"
                                    allowEmptyFormatting
                                    fullWidth
                                    mask="_"
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    value={formField.mobile}
                                    onChange={(e) => { field.onChange(handleMobileChange(e)) }}
                                />
                            )}
                        />

                        {props.formType === 'ADMSTF' && (

                            <Controller
                                name="role_id"
                                control={control}
                                rules={{
                                    required: "Role is required"
                                }}
                                render={({ field, fieldState }) => (
                                    <Autocomplete
                                        disablePortal
                                        id="role"
                                        options={roleOptions}
                                        getOptionLabel={(option) => option.description ? option.description : ''}

                                        sx={{ width: '100%' }}
                                        renderOption={(props, option) => (
                                            <Box component="li" {...props} key={"role_" + option.id}>
                                                {option.description}
                                            </Box>
                                        )}
                                        isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}
                                        renderInput={(params) => <TextField {...params} label="User Role" variant="outlined"
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        /* inputRef={input => {
                                            inputRef = input;
                                        }} */
                                        />}
                                        onChange={(_, data) => {
                                            field.onChange(data ? data.id : '')
                                            setFormField((prev) => ({ ...prev, role_id: data ? data.id : '' }))
                                        }}

                                        value={getSelectedRoleItem(formField.role_id)}
                                    />
                                )}
                            />
                        )}

                        {(_role === 'SYS' || _role === 'MG') && isLandlord === true && (
                            <NumericFormat
                                customInput={TextField}
                                sx={{ width: '100%' }}
                                label="Credit"
                                placeholder="Enter credit in format 50.00 or 40.55"
                                value={formField.credit ? formField.credit : ''}
                                maxLength={10}
                                prefix="$"
                                decimalScale={2}
                                fixedDecimalScale
                                className="bg-white"
                                onValueChange={(values, sourceInfo) => {
                                    //console.log(values);
                                    setFormField((prev) => ({ ...prev, credit: values.value }))
                                }}
                            />
                        )}

                    </div>
                    <div className="col-md-6">
                        <h5 className="mb-3" style={{ textAlign: 'left' }}>{props.formType === 'LDLORD' ? 'Mailing Address' : 'Address Info'}:</h5>

                        <TextField
                            fullWidth
                            id="address1" name="address1" variant="outlined" error={errors.address1 ? true : false} label={(props.formType === 'ADMSTF') ? 'Address 1' : 'Address 1 *'}
                            helperText={errors.address1?.message}

                            inputProps={{ maxLength: 40, value: (formField.address1 ? formField.address1 : '') }}

                            {...register("address1", { required: (props.formType === 'ADMSTF') ? false : "Address 1 is required" })}

                            onChange={(e) => setFormField((prev) => ({ ...prev, address1: e.target.value }))}
                        />

                        <TextField
                            fullWidth
                            id="address2" name="address2" variant="outlined" error={errors.address2 ? true : false} label="Address 2"
                            helperText={errors.address2?.message}

                            inputProps={{ maxLength: 40, value: (formField.address2 ? formField.address2 : '') }}

                            {...register("address2", { required: false })}
                            onChange={(e) => setFormField((prev) => ({ ...prev, address2: e.target.value }))}
                        />

                        <Autocomplete
                            sx={{ width: '100%' }}
                            id="suburb"
                            name="suburb"
                            value={pcodeValue}
                            onChange={(event, newValue) => {
                                if (newValue) {
                                    setPcodeValue({ code: newValue.code, suburb: newValue.suburb, state_shortname: newValue.state_shortname });
                                    setFormField((prev) => ({ ...prev, suburb: newValue?.suburb, state: newValue?.state_shortname, postcode: newValue?.code }));
                                    //setPcodeInput(newValue.suburb);
                                } else {
                                    setPcodeValue({ code: '', suburb: '', state_shortname: '' });
                                    setFormField((prev) => ({ ...prev, suburb: '', state: '', postcode: '' }));
                                }
                            }}

                            /*  inputValue={pcodeInput}
     
                             onInputChange={(event, newInputValue, eventType) => {
                                 if (eventType === 'reset') {
                                     setPcodeInput(pcodeInput);
                                 } else {
                                     setPcodeInput(newInputValue);
                                 }
                             }} */

                            getOptionLabel={(option) => option.suburb ? option.suburb : ''}

                            options={postcodeOptions}

                            renderOption={(props, option) => (
                                <Box component="li" {...props} key={"suburb_" + option.id}>
                                    {option.suburb}, {option.state_shortname} - {option.code}
                                </Box>
                            )}

                            isOptionEqualToValue={(options, value) => options.valueOf === value.valueOf}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={(props.formType === 'ADMSTF') ? 'Search Suburb' : 'Search Suburb *'}
                                    error={errors.suburb ? true : false}
                                    helperText={errors.suburb?.message}
                                    {...register("suburb", { required: (props.formType === 'ADMSTF') ? false : "Suburb is required" })}
                                />
                            )}
                        />

                        <TextField fullWidth id="state" name="state" variant="outlined" error={errors.state ? true : false} label="State" helperText={errors.state?.message} {...register("state", { required: false })} inputProps={{ maxLength: 4, value: (formField.state ? formField.state : ''), readOnly: false }} onChange={(e) => { setFormField((prev) => ({ ...prev, state: e.target.value })) }} />

                        <TextField
                            fullWidth
                            id="postcode" name="postcode"
                            variant="outlined" error={errors.postcode ? true : false}
                            label="Postcode"
                            helperText={errors.postcode?.message}
                            inputProps={{ maxLength: 4, value: (formField.postcode ? formField.postcode : ''), readOnly: false }}

                            {...register("postcode", { required: false })}
                            onChange={(e) => setFormField((prev) => ({ ...prev, postcode: e.target.value }))}
                        />
                        <NumericFormat
                            customInput={TextField}
                            sx={{ width: '100%' }}
                            label="Contact Number"
                            value={formField.phone ? formField.phone : ''}
                            inputProps={{ maxLength: 10 }}
                            className="bg-white"
                            onValueChange={(values, sourceInfo) => {
                                setFormField((prev) => ({ ...prev, phone: values.value }))
                            }}
                        />
                        {props.formType !== 'LDLORD' &&
                            <div className="text-start">
                                <FormControlLabel control={<Checkbox name="is_landlord"
                                    checked={isLandlord}
                                    onChange={(event) => { setIsLandlord(event.target.checked); setFormField((prev) => ({ ...prev, is_landlord: event.target.checked ? 'Y' : 'N' })) }} />}
                                    label="Act as Land Lord too" labelPlacement="end" />
                            </div>
                        }
                        {actionFun === 'edit' && _role === 'SYS' && (
                            <FormControl fullWidth>
                                <InputLabel id="status-select-label"> Select Status </InputLabel>
                                <Select
                                    labelId="status-select-label"
                                    id="status"
                                    name="status"
                                    error={errors.status ? true : false}
                                    value={formField.status}
                                    label="Select Status"
                                    {...register("status", { required: false })}
                                    onChange={(e) => setFormField((prev) => ({ ...prev, status: e.target.value }))}
                                    MenuProps={MenuProps}
                                >
                                    <MenuItem value='Y'>Active</MenuItem>
                                    <MenuItem value='N'>Inactive</MenuItem>
                                </Select>
                            </FormControl>
                        )}
                    </div>
                    <div className="col-md-12">
                        <div className="text-end">
                            <FormControlLabel control={<Checkbox name="showalert"
                                checked={showAlertCheck}
                                onChange={(event) => { setShowAlertCheck(event.target.checked); setFormField((prev) => ({ ...prev, showalert: event.target.checked ? 'Y' : 'N' })) }} />}
                                label="Show Alert" labelPlacement="start" />
                        </div>
                        <textarea
                            id="alertnote"
                            name="alertnote"
                            rows={4}
                            style={{ backgroundColor: '#ffffff', border: '1px solid #ccc', borderRadius: '4px', width: '100%' }}
                            placeholder="Notes"

                            {...register("alertnote", { required: false })}
                            onChange={(e) => setFormField((prev) => ({ ...prev, alertnote: e.target.value }))}

                            defaultValue={formField.alertnote ? formField.alertnote : ''}

                        />
                    </div>
                    <div className="col-md-12 mt-3 text-center">
                        <div className='row'>
                            <div className='col-12 col-md-4 col-lg-6 col-sm-2 col-xl-6 col-xxl-6'></div>
                            <div className='col-12 col-md-4 col-lg-3 col-sm-5 col-xl-3 col-xxl-3 mb-3 text-end'>
                                <Button type="button" className="themeholy-btn btn-secondary w-100" onClick={handleDialogClose}>Cancel</Button>

                            </div>
                            <div className='col-12 col-md-4 col-lg-3 col-sm-5 col-xl-3 col-xxl-3 text-end'>
                                <Button type="submit" autoFocus className="themeholy-btn btn-primary w-100" disabled={loaderText}>{(loaderText) ? 'Saving...' : 'Save'}</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Box >
    );


}