import { Fragment, useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {
    Typography, Table, TableBody, TableRow, TableCell, TableHead, Box
} from '@mui/material';
import dayjs from 'dayjs';
import { axiosFunction } from '../../common';
import HomeIcon from '../../assets/img/HomeIcon.png';
import UserIcon from '../../assets/img/UserIcon.png';
import CalendarIcon from '../../assets/img/CalendarIcon.png';
import HashtagIcon from '../../assets/img/HashtagIcon.png';
import AgencyIcon from '../../assets/img/AgencyIcon.png';
import RadioCheckedIcon from '../../assets/img/RadioCheckedIcon.png';
import RadioUncheckedIcon from '../../assets/img/RadioUncheckedIcon.png';
import CheckboxCheckedIcon from '../../assets/img/CheckboxCheckedIcon.png';
import CheckboxUncheckedIcon from '../../assets/img/CheckboxUncheckedIcon.png';
import Banner from "../../assets/img/Header.png";
import jsPDF from 'jspdf';

const ServiceReport = ({ upload, jobData }) => {
    const serviceReportRef = useRef(null);
    const navigate = useNavigate(null);
    const comp = useLocation();
    const servChkLstId = comp.pathname.split('/')[2];
    const maxWidth = '900px';
    const [serviceDetails, setServiceDetails] = useState(jobData);
    const [checklist_title, setChecklistTitle] = useState('');
    const [checklist_name, setChecklistName] = useState('');
    const [nextInspectDt, setNextInspectDt] = useState('');

    let category = '';
    let num = 1;
    let secTitle = { 'MAIN': 'SUPPLY MAINS', 'SWITBOARD': 'SWITCHBOARD', 'WIRING': 'WIRING AND ACCESSORIES', 'ELECAPPL': 'FIXED ELECTRICAL APPLIANCES', 'DISTRIBUTION': 'DISTRIBUTION BOARDS', 'GENERATOR': 'SUPPLEMENTARY/STANDBY ENERGY SUPPLY', 'AUDITEST': 'Audit Tests', 'GSINSTALL': 'Installation Check For Application', 'GSSERVICE': 'Servicing For Application', 'GSSPILLAGE': 'Spillage And Combustion Test For Application' }

    const [checklistData, setChecklistData] = useState([]);

    //let checkedItems = { 'AD': 'Adequate', 'AT': 'Attention Recommended', 'US': 'Un Safe', 'NA': 'N/A', 'Y': 'Yes', 'N': 'No', 'P': 'Pass', 'F': 'Fail' }

    let inspectnextdt = '';

    const CLfetchData = async () => {
        try {
            const reportData = await axiosFunction('servicereport/' + jobData.id, 'get', {});
            if (reportData.status === 'success') {
                setChecklistData(Object.values(reportData.data));
                if (jobData) {
                    if (jobData.service.product.code === 'PROD3LMSM') {
                        setChecklistTitle('Electrical Safety Check Only');
                        setChecklistName('Electrical');
                    } else if (jobData.service.product.code === 'PROD4LMSM') {
                        setChecklistTitle('Smoke Alarm Safety Check Only');
                        setChecklistName('Smoke Alarm');
                    } else if (jobData.service_type === 'ES') {
                        setChecklistTitle('Electrical & Smoke Alarm Safety Check');
                        setChecklistName('Electrical');
                    } else if (jobData.service_type === 'GS') {
                        setChecklistTitle('Gas Safety Check');
                        setChecklistName('Gas');
                    }

                    if (jobData.service.product.code !== 'PROD4LMSM' && jobData.next_job_due_date) {
                        inspectnextdt = jobData.next_job_due_date.split('/');
                        let date = new Date(inspectnextdt[2] + '-' + inspectnextdt[1] + '-' + inspectnextdt[0]);
                        setNextInspectDt(dayjs(date).format('DD/MM/YYYY'));
                    }
                }
            } else {
                /* if (reportData?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            } else {
                console.log(e);
            }
        }
    }

    const doc = new jsPDF('p', 'px', 'a4', true);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize('16px');

    const CLfetchDataWithService = async (servChkLstId) => {
        try {
            const reportData = await axiosFunction('auth/getservicereport/' + servChkLstId, 'get', {});

            if (reportData.status === 'success') {
                setServiceDetails(reportData.data.subservice);
                setChecklistData(Object.values(reportData.data.checklistData));
                //let pdfFilename = '';
                if (reportData.data.subservice.service.product.code === 'PROD3LMSM') {
                    setChecklistTitle('Electrical Safety Check Only');
                    setChecklistName('Electrical');
                    //pdfFilename = 'Electrical_Safety_Check_Service_Report_' + servChkLstId + '.pdf';
                } else if (reportData.data.subservice.service.product.code === 'PROD4LMSM') {
                    setChecklistTitle('Smoke Alarm Safety Check Only');
                    setChecklistName('Smoke Alarm');
                    //pdfFilename = 'Smoke_Alarm_Safety_Check_Service_Report_' + servChkLstId + '.pdf';
                } else if (reportData.data.subservice.service_type === 'ES') {
                    setChecklistTitle('Electrical & Smoke Alarm Safety Check');
                    //pdfFilename = 'Electrical_Smoke_Alarm_Safety_Check_Service_Report_' + servChkLstId + '.pdf';
                    setChecklistName('Electrical');
                } else if (reportData.data.subservice.service_type === 'GS') {
                    setChecklistTitle('Gas Safety Check');
                    setChecklistName('Gas');
                    //pdfFilename = 'Gas_Safety_Check_Service_Report_' + servChkLstId + '.pdf';
                }

                if (jobData.service.product.code !== 'PROD4LMSM' && jobData.next_job_due_date) {
                    inspectnextdt = jobData.next_job_due_date.split('/');
                    let date = new Date(inspectnextdt[2] + '-' + inspectnextdt[1] + '-' + inspectnextdt[0]);
                    setNextInspectDt(dayjs(date).format('DD/MM/YYYY'));
                }
            } else {
                /* if (reportData?.response?.status === 401) {
                    localStorage.clear();
                    navigate('/login');
                } */
            }
        } catch (e) {
            if (e?.response?.status === 401) {
                localStorage.clear();
                navigate('/login');
            } else {
                console.log(e);
            }
        }
    }

    useEffect(() => {
        if (jobData) {
            CLfetchData();
        } else if (servChkLstId) {
            CLfetchDataWithService(servChkLstId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [servChkLstId]);

    return (
        <Box ref={serviceReportRef} style={{ backgroundColor: '#FFFFFF', fontSize: '16px', textAlign: 'left', padding: '15px', fontFamily: 'helvetica', width: maxWidth }} className="container-md" id='pdfcontent'>
            {serviceDetails && <>
                <img alt="banner" src={Banner} width='100%' />
                <div className="container">
                    <Typography
                        sx={{ display: 'block', marginTop: '15px', marginBottom: '15px' }}
                        component="h3"
                        variant="subtitle-1"
                        className='text-theme'
                    >
                        Service Report
                    </Typography>
                    <div className='row'>
                        <div className='col-6 mb-3'>
                            <div className='row'>
                                <div className='col-2'>
                                    <img src={HomeIcon} alt='HomeIcon' />
                                </div>
                                <div className='col-10 mb-2'>

                                    <div className='fw-bold'>Property Address: </div>
                                    <div>{serviceDetails.service.property.address1 + (serviceDetails.service.property.address2 ? ', ' + serviceDetails.service.property.address2 : '') + ', '} <br />{serviceDetails.service.property.suburb + ', ' + serviceDetails.service.property.state + ' - ' + serviceDetails.service.property.postcode}</div>

                                </div>

                                <div className='col-2'>
                                    <img src={UserIcon} alt='HomeIcon' />
                                </div>

                                <div className='col-10'>
                                    <div className='fw-bold'>Land Lord: </div>
                                    <div className='mb-2'>{serviceDetails.service.property.propertyowner.prefix ? serviceDetails.service.property.propertyowner.prefix + '. ' : ''} {serviceDetails.service.property.propertyowner.firstname + ' ' + serviceDetails.service.property.propertyowner.lastname}</div>
                                </div>
                                {serviceDetails.service.property.propertyagent && <>

                                    <div className='col-2'>
                                        <img src={UserIcon} alt='HomeIcon' />
                                    </div>

                                    <div className='col-10'>
                                        <div className='fw-bold'>Agent</div>
                                        <div>{serviceDetails.service.property.propertyagent.prefix ? serviceDetails.service.property.propertyagent.prefix + '. ' : ''} {serviceDetails.service.property.propertyagent.firstname + ' ' + serviceDetails.service.property.propertyagent.lastname}</div>
                                    </div></>
                                }
                            </div>

                        </div>
                        <div className='col-6 mb-3'>
                            <div className='row fs-xs'>
                                <div className='col-2 text-md-end'><img src={CalendarIcon} alt='CalenderIcon' style={{ width: "16px" }} /></div>
                                <div className='col-6 fw-bold'>Job Date:</div>
                                <div className='col-4 text-md-end'> {serviceDetails.job_date}</div>
                                <div className='col-2 text-md-end'><img src={HashtagIcon} alt='HashtagIcon' style={{ width: "16px" }} /></div>
                                <div className='col-6 fw-bold'>Service Number:</div>
                                <div className='col-4 text-md-end'>{serviceDetails.id.toString().padStart(4, "0")}</div>
                                <div className='col-2 text-md-end'><img src={UserIcon} alt='UserIcon' style={{ width: "16px" }} /></div>
                                <div className='col-6 fw-bold'>Technician Name:</div>
                                <div className='col-4 text-md-end'>{serviceDetails.technician.prefix ? serviceDetails.technician.prefix + '.' : ''} {serviceDetails.technician.firstname} {serviceDetails.technician.lastname}</div>
                                <div className='col-2 text-md-end'><img src={HashtagIcon} alt='HashtagIcon' style={{ width: "16px" }} /></div>
                                <div className='col-6 fw-bold'>Licence Number:</div>
                                <div className='col-4 text-md-end mb-2'>{serviceDetails.technician.license_number}</div>
                                {serviceDetails.service.property.propertyagent && <>
                                    <div className='col-2 text-md-end'>
                                        <img src={AgencyIcon} alt='HomeIcon' />
                                    </div>
                                    <div className='col-10'>
                                        <div className='fw-bold'>Agency</div>
                                        <div>{serviceDetails.service.property.propertyagent.agency_name}</div>
                                    </div>
                                </>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className='row'>
                        <div className='col-1'><img src={serviceDetails.vacant_property === 'Y' ? CheckboxCheckedIcon : CheckboxUncheckedIcon} alt='CheckboxIcon' /></div>
                        <div className='col-11'>
                            Vacant Property

                            <p>In the event of a vacant property, it is assumed that permission has been given for myself to carry out the inspection on behalf of the rental provider or agent.</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <Typography
                        sx={{ display: 'block', marginTop: '15px', marginBottom: '15px' }}
                        component="h6"
                        variant="subtitle-1"
                        className='text-theme'
                    >
                        {checklist_title}
                    </Typography>
                    {serviceDetails.service_type === 'ES' &&
                        <>
                            <Table>
                                {checklistData && checklistData.map((item, index) => (
                                    (item.category !== 'GENSUPPLY' || (item.category === 'GENSUPPLY' && serviceDetails.generator_supply !== 'NA')) &&
                                    <TableBody key={"cont-" + index}>
                                        {
                                            (item.category !== 'SWITINSTAL' && item.category !== 'DISBOARD' && item.category !== 'GENSUPPLY' && category !== item.category) && (
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography
                                                            variant='h6'
                                                            className='sub-title'
                                                        >
                                                            Part {num++} - {secTitle[category = item.category]}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        }
                                        <TableRow>
                                            <TableCell>
                                                <div className='row'>
                                                    <div className='col-12 col-md-4'>
                                                        {item.description}
                                                    </div>
                                                    <div className='col-12 col-md-8 text-theme2'>

                                                        {item.lkp_code === 'GENSUPPLY' ? <div className='text-theme2'>{serviceDetails.generator_supply}</div>
                                                            :
                                                            (item.lkp_code === 'SWITINSTAL' || item.lkp_code === 'DISBOARD') ?
                                                                <ul className='row mt-2'>
                                                                    <li className='col-3 col-md-3'>
                                                                        <div className={(item.checked === 'Y' ? 'active ' : '')}>
                                                                            <img src={item.checked === 'Y' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' />Yes
                                                                        </div></li>
                                                                    <li className='col-3 col-md-3'>
                                                                        <div className={(item.checked === 'N' ? 'active ' : '') + " d-flex align-item-center"}>
                                                                            <img src={item.checked === 'N' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' />No
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                : (
                                                                    (item.lkp_code === 'SAFSWITCH') ?
                                                                        <div className='row'>
                                                                            <div className='fs-md col-12 d-flex align-items-top align-items-md-center justify-content-start'>

                                                                                <img src={!serviceDetails.safety_switches[0] ? CheckboxUncheckedIcon : CheckboxCheckedIcon} alt='CheckboxIcon' />

                                                                                <div className='pl-5'>Installed On All Socket Outlet Circuits</div>
                                                                            </div>
                                                                            <div className='fs-md col-12 d-flex align-items-center justify-content-start'>
                                                                                <img src={!serviceDetails.safety_switches[1] ? CheckboxUncheckedIcon : CheckboxCheckedIcon} alt='CheckboxIcon' />
                                                                                <div className='pl-5'>Installed on all lighting circuits</div>
                                                                            </div>
                                                                            <div className='fs-md col-12 d-flex align-items-top align-items-md-center justify-content-start'>
                                                                                <img src={!serviceDetails.safety_switches[2] ? CheckboxUncheckedIcon : CheckboxCheckedIcon} alt='CheckboxIcon' />
                                                                                <div className='pl-5'>Installed on all fixed equipment circuits</div>
                                                                            </div>
                                                                            <div className='fs-md col-12 d-flex align-items-center justify-content-start'>
                                                                                <img src={!serviceDetails.safety_switches[3] ? CheckboxUncheckedIcon : CheckboxCheckedIcon} alt='CheckboxIcon' />
                                                                                <div className='pl-5'>Other</div>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        (item.category === 'AUDITEST') ?
                                                                            <ul className='row'>
                                                                                <li className='col-4 col-md-3'>
                                                                                    <div className={(item.checked === 'P' ? 'active' : '')}>
                                                                                        <img src={item.checked === 'P' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' />Pass
                                                                                    </div>
                                                                                </li>
                                                                                <li className='col-4 col-md-3'>
                                                                                    <div className={(item.checked === 'F' ? 'active' : '')}>
                                                                                        <img src={item.checked === 'F' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' />Fail
                                                                                    </div>
                                                                                </li>
                                                                                <li className='col-4 col-md-3'>
                                                                                    <div className={(item.checked === 'NA' ? 'active' : '')}>
                                                                                        <img src={item.checked === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> N/A
                                                                                    </div>
                                                                                </li>
                                                                                <li className='col-12 mt-2 text-theme2'><b>Comments:</b> {item.comments}</li>

                                                                            </ul>
                                                                            :
                                                                            <ul className='row'>
                                                                                <li className='col-6 col-md-3'>
                                                                                    <div className={(item.checked === 'AD' ? 'active' : '')}>
                                                                                        <img src={item.checked === 'AD' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Adequate
                                                                                    </div>
                                                                                </li>
                                                                                <li className=' col-6 col-md-3'>
                                                                                    <div className={(item.checked === 'AT' ? 'active' : '')}>
                                                                                        <img src={item.checked === 'AT' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Attention
                                                                                    </div>
                                                                                </li>
                                                                                <li className='col-6 col-md-3'>
                                                                                    <div className={(item.checked === 'US' ? 'active' : '')}>
                                                                                        <img src={item.checked === 'US' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> UnSafe
                                                                                    </div>
                                                                                </li>
                                                                                <li className='col-6 col-md-3'>
                                                                                    <div className={(item.checked === 'NA' ? 'active' : '')}>
                                                                                        <img src={item.checked === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> N/A
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                )}

                                                    </div>
                                                </div>

                                            </TableCell>

                                        </TableRow>
                                        {item.category === 'DISTRIBUTION' && item.lkp_code === 'DISBOARD' && item.checked === 'Y' && (
                                            <><TableRow>
                                                <TableCell>
                                                    <div className='row'>
                                                        <div className='col-12 col-md-6'>Phase Configuration
                                                        </div>
                                                        <div className='col-12 col-md-6 text-theme2'>
                                                            {serviceDetails.phase_config}
                                                        </div>
                                                    </div></TableCell>

                                            </TableRow>
                                                <TableRow>
                                                    <TableCell colSpan={2}>
                                                        <Typography
                                                            variant='h6'
                                                            className='sub-title'
                                                        >
                                                            Distribution Boards Rating
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow></>
                                        )}
                                    </TableBody>

                                ))}
                            </Table>
                            <Table>
                                {serviceDetails.audit_test && serviceDetails.audit_test.map((element, aindex) => (
                                    element.test && <TableBody key={"audit-" + aindex}>
                                        {aindex === 0 &&
                                            <TableRow>
                                                <TableCell colSpan={2}>
                                                    <Typography
                                                        variant='h6'
                                                        className='sub-title'
                                                    >
                                                        Additional Optional Tests
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        }
                                        <TableRow>
                                            <TableCell>
                                                <div className='row'>
                                                    <div className='col-12 col-md-4'>
                                                        {element.test}
                                                    </div>
                                                    <div className='col-12 col-md-8'>

                                                        <ul className='row'>
                                                            <li className='col-4 col-md-3'>
                                                                <div className={(element.result === 'P' ? 'active' : '')}>
                                                                    <img src={element.result === 'P' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Pass
                                                                </div>
                                                            </li>
                                                            <li className='col-4 col-md-3'>
                                                                <div className={(element.result === 'F' ? 'active' : '')}>
                                                                    <img src={element.result === 'F' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Fail
                                                                </div>
                                                            </li>
                                                            <li className='col-4 col-md-3'>
                                                                <div className={(element.result === 'NA' ? 'active' : '')}>
                                                                    <img src={element.result === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> N/A
                                                                </div>
                                                            </li>
                                                            <li className='col-12 mt-2 text-theme2'><b>Comments:</b> {element.comments}</li>

                                                        </ul>
                                                    </div>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>

                                ))}
                            </Table>
                        </>
                    }

                    {(serviceDetails.service.product.code !== 'PROD3LMSM' && (serviceDetails.service_type === 'ES' || serviceDetails.service_type === 'SA')) &&
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Typography
                                            variant='h6'
                                            className='sub-title'
                                        >
                                            Safety Alarm
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        <div className='row'>
                                            <div className='col-12'>All smoke alarm are correctly installed and in working condition; and have been tested according to the manufacturer's instructions.</div>
                                            <div className='col-12 mt-3'>
                                                <ul className='row'>
                                                    <li className='col-4 col-md-2'>
                                                        <div className={(serviceDetails.sa_check === 'Y' ? 'active ' : '')}>
                                                            <img src={serviceDetails.sa_check === 'Y' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Yes
                                                        </div>
                                                    </li>
                                                    <li className='col-4 col-md-2'>
                                                        <div className={(serviceDetails.sa_check === 'N' ? 'active ' : '')}>
                                                            <img src={serviceDetails.sa_check === 'N' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> No
                                                        </div>
                                                    </li>
                                                    <li className='col-4 col-md-2'>
                                                        <div className={(serviceDetails.sa_check === 'NA' ? 'active ' : '')}>
                                                            <img src={serviceDetails.sa_check === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> N/A
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell className='fw-bold'>Next smoke alarms check is due by: <span className='text-theme2'>{dayjs(serviceDetails.sa_next_due_date).format('DD/MM/YYYY')}</span></TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    }

                    {(serviceDetails.service_type === 'ES' || serviceDetails.service_type === 'SA') &&
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Typography
                                            variant='h6'
                                            className='sub-title'
                                        >
                                            Support Pictures for Application
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        {serviceDetails.pictures && serviceDetails.pictures.map((image, index) => (
                                            <img key={"pic_" + index} src={image.data_url} alt={"image" + index} className='m-2' style={{ width: '150px' }} />
                                        ))}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    }

                    {serviceDetails.service_type === 'GS' &&
                        <>

                            <Table key="gascontenttable">
                                {checklistData && checklistData.map((item, index) => <Fragment key={"gascont-" + index}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='text-center' colSpan={2}><h5>Gas Appliance {parseInt(index) + 1}</h5></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {item.checklist && item.checklist.map((chklist, cindex) => (
                                        <TableBody key={"gascont-" + index + "-" + cindex}>
                                            {(chklist.code === 'GASNAME' || chklist.category === 'GSQUESTION') ?
                                                <TableRow>
                                                    <TableCell>
                                                        <div className='row'>
                                                            <div className='col-12 col-md-5'>
                                                                {chklist.description}
                                                            </div>
                                                            <div className='col-12 col-md-7 text-theme2'>
                                                                {
                                                                    (chklist.code === 'GASNAME') ? chklist.comments :
                                                                        <ul className='row'>
                                                                            <li className='col-4 col-md-4'>
                                                                                <div className={(chklist.checked === 'Y' ? 'active ' : '')}>
                                                                                    <img src={chklist.checked === 'Y' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' className='m-auto' /> Yes
                                                                                </div>
                                                                            </li>
                                                                            <li className='col-4 col-md-4'>
                                                                                <div className={(chklist.checked === 'N' ? 'active ' : '')}>
                                                                                    <img src={chklist.checked === 'N' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' className='m-auto' /> No
                                                                                </div>
                                                                            </li>
                                                                            <li className='col-4 col-md-4'>
                                                                                <div className={(chklist.checked === 'NA' ? 'active ' : '')}>
                                                                                    <img src={chklist.checked === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' className='m-auto' />
                                                                                    N/A
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                }
                                                            </div>
                                                        </div>
                                                    </TableCell>
                                                </TableRow> : <>
                                                    <TableRow>
                                                        <TableCell colSpan={2}>
                                                            <Typography
                                                                component="div"
                                                                className="sec-title fw-bold"
                                                            >
                                                                {chklist.description}
                                                            </Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        <TableCell colSpan={2} className='text-theme2'>
                                                            {
                                                                (chklist.code === 'GASCOMMENT') ? chklist.comments : chklist.pictures && chklist.pictures.map((image, mi) => (

                                                                    <img key={"gasimg_" + index + "_" + cindex + "_" + mi} src={image.data_url} alt={"image" + mi} className='m-2' style={{ width: '150px' }} />

                                                                ))
                                                            }
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            }
                                        </TableBody>
                                    ))}
                                </Fragment>)}
                            </Table>
                            <div className='container'>
                                <div className="row mt-3">
                                    <div className='col-12'>
                                        Appliance servicing Regulation 12(4) of the Gas Safety (Gas Installation)
                                        Regulations 2018 prescribes AS4575 as the standard for Type A appliance servicing work on an appliance that is part of a standard gas installation
                                        <p className='fw-bold'>
                                            I have serviced all appliances in accordance with AS 4575
                                        </p>
                                    </div>
                                    <div className='col-12 mb-3'>
                                        <ul className='row'>
                                            <li className='col-34 col-md-2'>
                                                <div className={(serviceDetails.all_service_done === 'Y' ? 'active ' : '')}>
                                                    <img src={serviceDetails.all_service_done === 'Y' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Yes
                                                </div>
                                            </li>
                                            <li className='col-4 col-md-2'>
                                                <div className={(serviceDetails.all_service_done === 'N' ? 'active ' : '')}>
                                                    <img src={serviceDetails.all_service_done === 'N' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> No
                                                </div>
                                            </li>
                                            <li className='col-4 col-md-2'>
                                                <div className={(serviceDetails.all_service_done === 'NA' ? 'active ' : '')}>
                                                    <img src={serviceDetails.all_service_done === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> N/A
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='col-12'>
                                        <p>I have created a record (VBA online) under regulation 36(2) or 37(2) of
                                            the Gas Safety (Gas Installation) Regulations 2018 and provided a copy
                                            to the rental provider under the regulation 30 (1)(ab) of the Residential
                                            Tenancies Regulations 2021</p>
                                    </div>
                                    <div className='col-12'>
                                        <ul className='row'>
                                            <li className='col-4 col-md-2'>
                                                <div className={(serviceDetails.created_record === 'Y' ? 'active ' : '')}>
                                                    <img src={serviceDetails.created_record === 'Y' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Yes
                                                </div>
                                            </li>
                                            <li className='col-4 col-md-2'>
                                                <div className={(serviceDetails.created_record === 'N' ? 'active ' : '')}>
                                                    <img src={serviceDetails.created_record === 'N' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' />  No
                                                </div>
                                            </li>
                                            <li className='col-4 col-md-2'>
                                                <div className={(serviceDetails.created_record === 'NA' ? 'active ' : '')}>
                                                    <img src={serviceDetails.created_record === 'NA' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' />  N/A
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                    {serviceDetails.service_type !== 'GS' &&
                        <Table>
                            <TableBody>

                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Typography
                                            variant='h6'
                                            className='sub-title'
                                        >
                                            Observations and Recommendations
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Typography>
                                            The following observations and recommendations are made:
                                        </Typography>
                                        <div className="fs-xs">{serviceDetails.recommendation}</div>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <Typography>
                                            The following work is required for compliance purposes:
                                        </Typography>
                                        <div className="fs-xs">{serviceDetails.compliance}</div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    }

                    {serviceDetails.service_type === 'GS' &&
                        <Table sx={{ marginTop: '15px' }}>
                            <TableHead className="bg-smoke text-body">
                                <TableRow>
                                    <TableCell>
                                        <Typography
                                            component="div"
                                            className="sec-title fw-bold"
                                        >
                                            Dangerous Gas Installations
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        Gas Safety (Gas Installation) Regulations 2018, Part 3, Division 3, Section 21)
                                        <ol>
                                            <li className="mb-2">If a person carrying out gas fitting work on a gas installation becomes
                                                aware of a danger arising from a defect in the gas installation, the
                                                person must without delay-
                                                <br />a) take all steps that are necessary to make the installation safe; and
                                                <br />b) notify the owner of the gas installation and the occupier of the
                                                premises in which the installation is situated of the defect</li>
                                            <li className="mb-2">Sub regulation (1)(a) does not apply if the person is unable, or it is
                                                unreasonable for the person, to take the necessary steps to make the
                                                gas installation safe</li>
                                            <li className="mb-2">If the person carrying out the gas fitting work is unable, or it is
                                                unreasonable for the person, to make the gas installation safe, he or
                                                she must, without delay, notify Energy Safe Victoria and —
                                                <br />a) if the gas installation uses natural gas, the gas distribution company
                                                which supplies that gas to the gas installation of the defect; or
                                                <br />b) if the gas installation uses LPG, the gas retailer which supplies that
                                                gas to the gas installation of the defect.</li>
                                        </ol>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    }

                    <Table sx={{ marginTop: '15px', width: '100%' }}>
                        <TableHead className="bg-smoke text-body">
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        component="div"
                                        className="sec-title fw-bold"
                                    >
                                        Declaration
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    {(serviceDetails.service_type === 'ES' || serviceDetails.service_type === 'SA') ? <>
                                        <p>I, being the person responsible for the audit of the {checklist_title} installation (as indicated by my approval below), particulars of which are described above, having exercised reasonable skill and care when carrying out the audit, hereby declare that the information in this report, including the observations, provides an accurate assessment of the condition of the {checklist_title} installation taking into account the stated extent of the audit and the limitations of the visual inspection and testing.</p>
                                        <div className='mb-3'>I further declare that in my judgment, the condition of the said installation was (below selection) at the time the audit was carried out. This does not preclude the possibility that other defects may exist.
                                        </div>
                                    </> : <>
                                        <p>I, being the person responsible for the inspection of the identified gas
                                            appliances or installations in the rental property or rooming house, particulars
                                            of which are described here, having exercised reasonable skill and care when
                                            carrying out the inspection, hereby declare on the date of inspection that the
                                            information in this report, including the observations and recommendations,
                                            provides an accurate assessment of the condition of the gas appliances or
                                            installations in the rental property or rooming house taking into account the
                                            stated extent of the installation and the limitations of the inspection and
                                            testing.</p>
                                        <div className='mb-3'>I further declare that in my judgment, the said appliance(s) and corresponding
                                            installation(s) is/are: (Select one of the below as applicable)
                                        </div>
                                    </>
                                    }

                                    <ul className='row mb-3'>
                                        {serviceDetails.service_type === 'GS' ? <>
                                            <li className='col-12'>
                                                <div className={(serviceDetails.declaration === 'Compliant' ? 'active ' : '')}>
                                                    <img src={serviceDetails.declaration === 'Compliant' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> <b>Compliant</b> (Gas appliance or gas installation complies with the criteria for a "gas safety check" in the residential tenancies regulations)
                                                </div>
                                            </li>
                                            <li className='col-12'>
                                                <div className={(serviceDetails.declaration === 'Non-Compliant' ? 'active ' : '')}>
                                                    <img src={serviceDetails.declaration === 'Non-Compliant' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> <b>Non-Compliant</b> (No immediate risk, however the customer should be advised that remedial work is required to be carried out to bring the gas appliance or its installation up to standard.)
                                                </div>
                                            </li>
                                            <li className='col-12'>
                                                <div className={(serviceDetails.declaration === 'UnSafe' ? 'active ' : '')}>
                                                    <img src={serviceDetails.declaration === 'UnSafe' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> <b>UnSafe</b> (Gas appliance or its installation is unsafe and requires disconnection and urgent work as the safety of persons may be at risk or there may be damage to property)
                                                </div>
                                            </li>
                                        </> : <>
                                            <li className='col-4 col-md-2'>
                                                <div className={(serviceDetails.declaration === 'Safe' ? 'active ' : '')}>
                                                    <img src={serviceDetails.declaration === 'Safe' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> Safe
                                                </div>
                                            </li>
                                            <li className='col-5 col-md-2'>
                                                <div className={(serviceDetails.declaration === 'UnSafe' ? 'active ' : '')}>
                                                    <img src={serviceDetails.declaration === 'UnSafe' ? RadioCheckedIcon : RadioUncheckedIcon} alt='RadioCheckedIcon' /> UnSafe
                                                </div>
                                            </li></>}
                                    </ul>
                                </TableCell>
                            </TableRow>
                            {serviceDetails.service.product.code !== 'PROD4LMSM' &&
                                <TableRow>
                                    <TableCell className='fw-bold'>
                                        Next Inspection Due Date: <span className='text-theme2'>{nextInspectDt}</span>
                                    </TableCell>
                                </TableRow>}
                            <TableRow>
                                <TableCell>
                                    {(serviceDetails.service_type === 'ES' || serviceDetails.service_type === 'SA') ? <div>
                                        I the above named licenced electrician have carried out an {checklist_name} safety check of this residential tenancies per the
                                        requirements of the Residential Tenancies Regulations 2021 and set out in the Australian/New Zealand Standard
                                        AS/NZS 3019, "{checklist_name} installations—Periodic verification, and have recorded my observations and
                                        recommendations.</div>
                                        : <div>I declare that I have provided all the information in this compliance check
                                            including the estimated quote of parts cost:</div>}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell className='fw-bold'>
                                    Signature of technician <br /><br />
                                    <img src={serviceDetails.signature && serviceDetails.signature[0]} alt="Technician Signature" className='m-3' style={{ height: '50px' }} />
                                    <div className='text-theme2'>
                                        {serviceDetails.technician.prefix ? serviceDetails.technician.prefix + '.' : ''} {serviceDetails.technician.firstname} {serviceDetails.technician.lastname}
                                    </div>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </div>
            </>}
        </Box >
    )
}
export default ServiceReport;